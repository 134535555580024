import httpClient from "./HttpClient";
const prefix = "/index";

export default class IndexService {
  static async update(index) {
    return (await httpClient.put(`${prefix}/${index.id}`, index)).data;
  }

  static async get() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

}
