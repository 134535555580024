import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Typed from "react-typed";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import WOW from "wowjs";

//servicios
import CertService from "../../../services/Cert.service";
import NosotrosService from "../../../services/Nosotros.service";
import { stateToHTML } from "draft-js-export-html";
import S3Service from "../../../services/S3.service";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import useMediaQuery from "@mui/material/useMediaQuery";

//componentes
import Portada_ServicioItem from "./Portada_ServicioItem";
import img2 from "./img2.png";
const Portada_Certs = ({ nuestrosCert }) => {
  const [cert, setCert] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [descripcion, setDescripcion] = useState({});
  const [certificados, setCertificados] = useState([]);
  const [certificadoArray, setCertificadoArray] = useState([]);
  const [certificadoContador, setCertificadoContador] = useState(0);
  const [fileCert, setFileCert] = useState(undefined);
  const [aliados, setAliados] = useState([]);
  const [aliadosArray, setAliadosArray] = useState([]);
  const [aliadosContador, setAliadosContador] = useState(0);
  const [fileAliados, setFileAliados] = useState(undefined);
  const [imagenesProducto, setImagenesProducto] = useState([]);
  const matches = useMediaQuery("(min-width:1400px)");

  const navigate = useNavigate();
  let pasoCertificados = 0;
  let pasoAliados = 0;
  let arrayOriginalCert = [];
  let arrayOriginalAliados = [];

  useEffect(() => {
    getCert();
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const getCert = async () => {
    setLoading(true);
    try {
      const cont = await NosotrosService.getAll();

      const certificados = await CertService.getAll();
      let imagenesc = [];
      if (certificados[0].imagenes) {
        for (const imagen of certificados[0].imagenes) {
          const result = await S3Service.get(imagen);
          imagenesc.push(result.result);
        }
      }
      console.log(certificados);
      console.log(imagenesc);

      setCert(cont[0]);
      if (cont[0].tabs) {
        for (const cert of cont[0].tabs) {
          const result = await S3Service.get(cert.fileTab);
          const certif = {
            titulo: cert.titulo,
            descripcion: cert.descripcion,
            imagen: result.result,
          };
          setCertificados((certificados) => [...certificados, certif]);
        }
        setCertificadoArray(cont[0].certs);
      }

      //Obtener Carrusel
      if (cont[0].imagenesPorque) {
        for (const imagen of cont[0].imagenesPorque) {
          const result = await S3Service.get(imagen);
          setImagenesProducto((imagenesProducto) => [
            ...imagenesProducto,
            result.result,
          ]);
        }
      }

      //Obtener descripción
      const contentHTML = convertFromHTML(cont[0].descripcion);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(state));
      setDescripcion(content);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* WHY CHOOSE US
  ================================================== */}
      <section style={{ padding: "0" }}>
        <div className="row g-0">
          <div
            className="col-xl-5 d-none d-xl-block wow fadeIn"
            data-wow-delay="200ms"
          >
            {loading ? null : (
              <OwlCarousel
                className="whychooseus3-carousel owl-carousel owl-theme position-relative h-100"
                items={1}
                autoplay="true"
                autoplaySpeed="100"
                loop="true"
              >
                {imagenesProducto.map((record, index) => {
                  return (
                    <div
                      key={index}
                      style={{ backgroundImage: `url(${record})` }}
                      className=" item bg-img cover-background"
                      data-overlay-dark={0}
                      data-background={record}
                    />
                  );
                })}
              </OwlCarousel>
            )}
          </div>
          <div className="col-xl-7 wow fadeIn" data-wow-delay="400ms">
            <div
              className="whychooseus3 position-relative bg-img cover-background h-100 left-overlay-dark"
              data-overlay-dark={9}
              data-background={img2}
              style={{ backgroundImage: `url(${img2})` }}
            >
              <div className="row position-relative z-index-9">
                <div
                  className="col-lg-9 col-xl-10 col-xxl-8 py-6 py-md-8 py-xl-8 py-xxl-14 px-1-9 px-sm-8 pe-xl-3 ps-xl-8 ps-xxl-10"
                  style={matches ? { padding: "1rem 5rem 1rem 3rem" } : {}}
                >
                  <div className="title-style2 white mb-4">
                    <span className="text-white ms-4">Por qué elegirnos</span>
                    <h2 className="text-white">{cert?.tituloPorque}</h2>
                  </div>
                  <div>
                    <p className="text-white mb-2-6">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: String(cert?.Historia)
                            .slice(0, 250)
                            .concat("..."),
                        }}
                        style={{
                          textAlign: "justify",
                          height: "100px",
                        }}
                      ></p>
                    </p>
                    <div className="row mb-2-6">
                      {certificados.map((record, index) => {
                        return (
                          <div className="col-sm-6 mb-2 mb-sm-0" key={index}>
                            <ul className="list-unstyled ps-0 mb-0">
                              <div
                                className="mb-2 c"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className="media"
                                  style={{
                                    width: "7rem",
                                    minHeight: "7rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    filter: "brightness(70%)",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <img
                                    src={record.imagen}
                                    alt="imagen"
                                    style={{
                                      width: "7rem",
                                      padding: "0.4rem",
                                      backgroundColor: "white",
                                    }}
                                  />
                                </div>
                                <div
                                  className="media-body ps-ms-4"
                                  style={{ textAlign: "center" }}
                                >
                                  <h4 className="h5 mb-2 text-white">
                                    {record.titulo}
                                  </h4>
                                </div>
                              </div>
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate("/nosotros")}
                      className="butn-style3"
                    >
                      Saber más
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portada_Certs;
