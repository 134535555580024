import React, { useEffect, Suspense } from "react";
import { Outlet } from "react-router-dom";
import FooterPublicLayout from "./FooterPublicLayout";
import HeaderPublicLayout from "./HeaderPublicLayout";

//import HeaderPublicLayout from "./html_frontend/HeaderPublicLayout";
import { CartProvider } from "react-use-cart";

const PublicLayout = () => {
  useEffect(() => {
    //require("../assets/css/kidz.css");
  }, []);

  return (
    <CartProvider>
      <body id="body" class="up-scroll">
        <div className="main-wrapper">
          <HeaderPublicLayout />
          <Outlet />
          <FooterPublicLayout />
        </div>
      </body>
    </CartProvider>
  );
};

export default PublicLayout;
