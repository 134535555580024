/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import DropComponent from "../imagesDrop";
import DropZoneEdit from "../../DropZoneEdit";

/* Servicios */
import NoticiaService from "../../../services/Noticia.service";
import S3Service from "../../../services/S3.service";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

import styles from "../layout/assets/css/core.min.module.css";

const CrearCarrusel = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});
  const [categoria, setCategoria] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [etiquetas, setEtiquetas] = useState("");
  const [contenido, setContenido] = useState("");
  const [imagenesProducto, setImagenesProducto] = useState([]);

  useEffect(() => {
    getNoticia();
    getCategorias();
  }, []);

  const getNoticia = async () => {
    setLoading(true);
    try {
      const data = await NoticiaService.getById(id);
      setNoticia(data[0]);

      const contentHTML = convertFromHTML(data[0].cuerpoNoticia);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );

      const content = JSON.stringify(convertToRaw(state));
      setContenido(content);

      if (data[0]?.imagen && data[0].imagen[0]) {
        const image = await S3Service.get(data[0].imagen);
        setImagen(image.result);
      }
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      setCategorias(data);
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  const updateNoticia = async () => {
    if (!noticia.titulo) {
      toast.info("Error, tienes que agregar un titulo");
    } else if (noticia.cuerpoNoticia.length == 11) {
      toast.info("Error, tienes que colocar texto al blog");
    } else {
      setLoading(true);
      try {
        const dataNoticia = noticia;
        if (file) {
          const uploadPhoto = await S3Service.upload(file);
          dataNoticia.imagen = [uploadPhoto.result.data];
          await NoticiaService.update(dataNoticia);
        } else {
          await NoticiaService.update(dataNoticia);
        }

        if (file && noticia?.imagen) {
          await S3Service.delete(noticia.imagen);
        }
        toast.success("Blog actualizado con éxito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/blog");
      }
    }
  };

  const myTheme = createTheme({});

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {" "}
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>Editar entrada</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li className={`${styles.breadcrumbItem}`}>
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/blog")}
                    >
                      Blog
                    </a>
                  </li>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Editar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className={`${styles.row} ${styles.g4}`}>
                <div className={`${styles.col}`}>
                  <div
                    className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                  >
                    Editar entrada
                  </div>
                  {/* Shipping address */}
                  <div className={`${styles.section} ${styles.mb4}`}>
                    <div className={`${styles.cardBody}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Título"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  titulo: e.target.value.toUpperCase(),
                                })
                              }
                              value={noticia.titulo}
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            <div>
                              <ThemeProvider theme={myTheme}>
                                <MUIRichTextEditor
                                  label="Cuerpo de la entrada... "
                                  controls={[
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "highlight",
                                    "undo",
                                    "redo",
                                    "link",
                                    "numberList",
                                    "bulletList",
                                    "quote",
                                    "code",
                                    "clear",
                                    // "save"
                                  ]}
                                  // onSave={save}
                                  inlineToolbar={true}
                                  onChange={(value) =>
                                    setNoticia({
                                      ...noticia,
                                      cuerpoNoticia: stateToHTML(
                                        value.getCurrentContent()
                                      ),
                                    })
                                  }
                                  defaultValue={contenido}
                                />
                              </ThemeProvider>
                              <br></br>
                              <br></br>
                            </div>
                          </div>
                          {file ? (
                            <div style={{ textAlign: "center" }}>
                              <img
                                style={{
                                  height: 300,
                                  objectFit: "contain",
                                  width: "-webkit-fill-available",
                                }}
                                src={URL.createObjectURL(file)}
                              />
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  width: "-webkit-fill-available",
                                }}
                              >
                                {file.name}
                              </Typography>
                            </div>
                          ) : imagen ? (
                            <div style={{ textAlign: "center" }}>
                              <img
                                style={{
                                  height: 300,
                                  objectFit: "contain",
                                  width: "-webkit-fill-available",
                                }}
                                src={String(imagen)}
                              />
                            </div>
                          ) : null}
                          <div style={{ width: "95%", marginLeft: "1.5%" }}>
                            <DropZoneEdit
                              saveFile={async (file) => {
                                setFile(file);
                                var reader = new FileReader();
                                var url = reader.readAsDataURL(file);
                              }}
                            />
                          </div>

                          {/*{!file && noticia.imagen ? (
              <div>
                <div className="col-lg-12">
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={String(imagen)}
                      style={{
                        height: 300,
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
                          <div style={{ width: "95%", marginLeft: "1.5%" }}>
                            <DropZone
                              saveFile={async (file) => {
                                setFile(file);
                                var reader = new FileReader();
                                var url = reader.readAsDataURL(file);
                              }}
                            />
                          </div>*/}
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Frase celebre"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  fraseCelebre: e.target.value,
                                })
                              }
                              value={noticia.fraseCelebre}
                            />
                            <label>Frase celebre</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Frase celebre"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  autorFraseCelebre: e.target.value,
                                })
                              }
                              value={noticia.autorFraseCelebre}
                            />
                            <label>Autor</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <select
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              id="shipping__country"
                              name="shipping[country]"
                              aria-label="Country"
                              onChange={(e) => {
                                setNoticia({
                                  ...noticia,
                                  categoria: e.target.value,
                                });
                              }}
                              value={noticia.categoria}
                              defaultValue={noticia.categoria}
                            >
                              <option disabled="disabled" selected="selected">
                                Selecciona una
                              </option>
                              {categorias.map((record, index) => {
                                if (record.id == noticia.categoria) {
                                  return (
                                    <option selected="selected">
                                      {record.tipo}
                                    </option>
                                  );
                                } else {
                                  return <option>{record.tipo}</option>;
                                }
                              })}
                            </select>
                            <label>Categoría</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={`${styles.co12}`}>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => updateNoticia()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span className={`${styles.small}`}>Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
