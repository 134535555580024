import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

const BlogItem = ({
  id,
  titulo,
  idAutor,
  fechaU,
  categoria,
  imagenes,
  cuerpo,
  handleCategoria,
}) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  const [fecha, setFecha] = useState("");
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  useEffect(() => {
    getImagen();
    getAutor();
    getTiempo();
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes[0]);
      setImag(result.result);
      imagen = result.result;
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const getAutor = async () => {
    try {
      const result = await UserService.getById(idAutor);
      setAutor(result.nombre);
    } catch (error) {
      toast.error(error);
    }
  };

  const getTiempo = async () => {
    try {
      //Tiempo
      const date = new Date(fechaU);

      // Obtener los componentes de fecha y hora
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const day = ("0" + date.getDate()).slice(-2);
      // Formatear el texto de salida
      setFecha(`${day} ${monthNames[monthIndex]} ${year}`);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const cortarTexto = (entry) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(entry, "text/html");
    return doc.body.innerText;
  };

  return (
    <>
      <div className="col-12 mt-1-9 wow fadeIn" data-wow-delay="200ms">
        <article className="card card-style3 border-0 h-100">
          <div className="card-img position-relative">
            <img
              src={imag}
              alt="Card image"
              style={{ height: "500px", width: "856px", objectFit: "cover" }}
              onClick={() => navigate(`/blog/${id}`)}
            />
          </div>
          <div className="card-body p-xl-1-9 p-4">
            <h3 className="h5 mb-3">
              <a
                href="javascrit:void(0);"
                onClick={() => navigate(`/blog/${id}`)}
              >
                {titulo}
              </a>
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: String(cortarTexto(cuerpo)).slice(0, 200).concat("..."),
              }}
              style={{
                textAlign: "justify",
              }}
            ></p>
            <a
              href="javascript:.void(0);"
              onClick={() => navigate(`/blog/${id}`)}
              className="fw-bold text-primary text-secondary-hover"
              style={{ marginTop: "2rem" }}
            >
              Leer más
            </a>
          </div>
          <div className="card-footer bg-white py-4 px-0 mx-4 mx-xl-1-9">
            <div className="d-flex justify-content-between">
              <span className="display-30">
                <i className="ti-calendar me-1 text-primary" /> {fecha}
              </span>
              <a
                className="display-30"
                href="javascript:void(0)"
                onClick={() => handleCategoria(categoria)}
              >
                {categoria}
              </a>
            </div>
          </div>
        </article>
      </div>
      {/*<div className="col-md-6 col-lg-4">
        <div className="card">
          <div className="position-relative">
            <a href="" onClick={() => navigate(`/blog/${id}`)}>
              <img
                className="card-img-top"
                src={imag}
                alt="Card image"
                style={{ height: "200px", objectFit: "cover" }}
              />
            </a>
            <div className="card-img-overlay p-0">
              <span className="badge bg-danger badge-rounded m-4">
                {" "}
                <i className="far fa-calendar" aria-hidden="true" />
                {"  "}
                {fecha}
              </span>
            </div>
          </div>
          <div className="card-body border-top-5 px-3 rounded-bottom border-danger">
            <h3 className="card-title text-capitalize">
              <a
                className="text-danger d-block text-truncate"
                href="#"
                onClick={() => navigate(`/blog/${id}`)}
              >
                {titulo}
              </a>
            </h3>
            <ul
              className="list-unstyled d-flex flex-md-column  mb-1"
              style={{ height: "50px" }}
            >
              <li className="me-2">
                <a
                  className="text-muted"
                  href="#"
                  onClick={() => navigate(`/blog/${id}`)}
                >
                  <i className="fa fa-user me-2" aria-hidden="true" />
                  {autor}
                </a>
              </li>
              <li className="me-2">
                <a
                  className="text-muted"
                  href="#"
                  onClick={() => navigate(`/blog/${id}`)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-tag-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                  {"   "}
                  {categoria}
                </a>
              </li>
            </ul>
            <p className="mb-2">
              {" "}
              <p
                dangerouslySetInnerHTML={{
                  __html: String(cuerpo).slice(0, 150),
                }}
                style={{
                  textAlign: "justify",
                  height: "50px",
                }}
              ></p>
            </p>
            <a
              className="btn btn-link text-hover-danger ps-0"
              href="#"
              onClick={() => navigate(`/blog/${id}`)}
            >
              <i className="fa fa-angle-double-right me-1" aria-hidden="true" />{" "}
              Leer más
            </a>
          </div>
        </div>
      </div>*/}
    </>
  );
};

export default BlogItem;
