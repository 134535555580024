import { Wrapper } from "@googlemaps/react-wrapper";
import { useEffect, useRef } from "react";

const DEFAULT_CENTER = { lat: 48.8566, lng: 2.3522 };
const DEFAULT_ZOOM = 14;

const GoogleMapsWrapper = ({ children }) => {
  // Ideally we want the apiKey to be fetch from an environment variable
  const apiKey = process.env.REACT_APP_GOOGLE_KEY;

  if (!apiKey) {
    return <div>Cannot display the map: google maps api key missing</div>;
  }

  return <Wrapper apiKey={apiKey}>{children}</Wrapper>;
};

const GoogleMaps = ({ locations, zoom }) => {
  const ref = useRef();

  useEffect(() => {
    // Display the map
    if (ref.current) {
      const map = new window.google.maps.Map(ref.current, {
        center: locations[0] ? locations[0] : DEFAULT_CENTER,
        zoom: zoom ? zoom : DEFAULT_ZOOM,
      });

      // Displays single markers on map when called
      addSingleMarkers({ locations, map });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return <div ref={ref} style={{ width: "100%", height: "100%" }} />;
};

const addSingleMarkers = ({ locations, map }) =>
  locations.map(
    (position) =>
      new window.google.maps.Marker({
        position,
        map,
      })
  );

const MapComponent = ({ locations, zoom }) => (
  <GoogleMapsWrapper>
    <GoogleMaps locations={locations} zoom={zoom} />
  </GoogleMapsWrapper>
);

export default MapComponent;
