import { useNavigate } from "react-router-dom";

const SideMenu = ({ active, handleActive, status, handleStatus, footer }) => {
  const navigate = useNavigate();
  return (
    <>
      {/* <!-- start sidemenu --> */}
      <div
        className={
          status
            ? "side-menu bg-theme alt-font side-menu-active"
            : "side-menu bg-theme alt-font"
        }
        style={{ minWidth: "22rem" }}
      >
        <div class="inner-wrapper">
          <span
            class="btn-close"
            id="btn_sidebar_colse"
            onClick={() => handleStatus()}
          ></span>
          <nav class="side-nav w-100">
            <ul
              class="navbar-nav ml-auto"
              style={{
                backgroundColor: "inherit",
                display: "inherit",
                padding: "3rem",
                boxShadow: "none",
              }}
            >
              <li class="nav-item">
                <a
                  className={
                    active === "inicio" ? "nav-link active" : "nav-link"
                  }
                  href="javascript:void(0);"
                  onClick={() => {
                    handleActive("inicio");
                    navigate(`/`);
                  }}
                  data-scroll-nav="0"
                >
                  Inicio
                </a>
              </li>
              <li class="nav-item">
                <a
                  className={
                    active === "nosotros" ? "nav-link active" : "nav-link"
                  }
                  href="javascript:void(0);"
                  onClick={() => {
                    handleActive("nosotros");
                    navigate(`/nosotros`);
                  }}
                  data-scroll-nav="1"
                >
                  Nosotros
                </a>
              </li>
              <li class="nav-item">
                <a
                  className={
                    active === "servicios" ? "nav-link active" : "nav-link"
                  }
                  href="javascript:void(0);"
                  onClick={() => {
                    handleActive("servicios");
                    navigate(`/Servicios`);
                  }}
                  data-scroll-nav="2"
                >
                  Servicios
                </a>
              </li>
              <li class="nav-item">
                <a
                  className={active === "blog" ? "nav-link active" : "nav-link"}
                  href="javascript:void(0);"
                  onClick={() => {
                    handleActive("blog");
                    navigate(`/Blog`);
                  }}
                  data-scroll-nav="3"
                >
                  Blog
                </a>
              </li>
              <li class="nav-item">
                <a
                  className={
                    active === "contactanos" ? "nav-link active" : "nav-link"
                  }
                  href="javascript:void(0);"
                  onClick={() => {
                    handleActive("contactanos");
                    navigate(`/contactanos`);
                  }}
                  data-scroll-nav="4"
                >
                  Contactanos
                </a>
              </li>
            </ul>
          </nav>

          <div class="side-footer w-100">
            <div
              class="social-links"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <a
                href={
                  footer?.fb ? "https://" + footer?.fb : "https://facebook.com"
                }
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href={
                  footer?.tw ? "https://" + footer?.tw : "https://twtter.com"
                }
              >
                <i class="fab fa-twitter"></i>
              </a>
              <a
                href={
                  footer?.in
                    ? "https://" + footer?.in
                    : "https://www.linkedin.com/"
                }
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End sidemenu --> */}

      {/* <!-- close sidemenu --> */}
      <a
        id="close_sidebar"
        href="javascript:void(0);"
        onClick={() => handleStatus()}
        style={status ? { display: "inline" } : null}
      ></a>
      {/* <!-- End Sidemenu --> */}
    </>
  );
};

export default SideMenu;
