/* React */
import { useNavigate } from "react-router-dom";
import { useEffect, useState, MouseEvent, ChangeEvent } from "react";

/* Components & dependencies */
import { Button, TablePagination } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FullPageLoading from "../../FullPageLoading";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

/* Service */
import CarruselService from "../../../services/Carrusel.service";
import styles from "../layout/assets/css/core.min.module.css";

const CarruselTable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [carrusel, setCarrusel] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    loadCarrusel();
  }, [page, limit]);

  const loadCarrusel = async () => {
    setLoading(true);
    try {
      const results = await CarruselService.list(limit, page * limit);
      setCarrusel(results.data);
      setTotal(results.total);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const checarEstado = (objeto) => {
    if (objeto.isActive === true) {
      return (
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
          onClick={async () => {
            objeto.isActive = false;
            await CarruselService.update(objeto);
            loadCarrusel();
          }}
          label=""
          style={{ marginLeft: "0.3%" }}
          color="error"
        />
      );
    } else {
      return (
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} />}
          onClick={async () => {
            objeto.isActive = true;
            await CarruselService.update(objeto);
            loadCarrusel();
          }}
          label=""
          style={{ marginLeft: "0.3%" }}
          color="error"
        />
      );
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>CARRUSEL</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Hay {carrusel.length} actualmente.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div
                className={`${styles.section} ${styles.p0}`}
                // style={{ width: "100%", overflow: "auto" }}
              >
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <div
                      className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                    >
                      <form
                        className={`${styles.positionRelative} ${styles.dFlex} ${styles.alignItemsCenter}`}
                        method="get"
                        action="#"
                      ></form>
                    </div>
                    {/* options */}
                    <div
                      className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                    >
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate(`nuevo`)}
                        className={`${styles.w100} ${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                      >
                        <svg
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                        <span>Nuevo</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  {/* item list */}
                  <div
                    className={`${styles.tableResponsiveMd}`}
                    style={{ width: "100%", overflow: "auto" }}
                  >
                    <table
                      className={`${styles.table}`}
                      style={{ marginLeft: "10px", width: "1000px" }}
                    >
                      <thead>
                        <tr>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 100 }}
                          >
                            <a
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              href="javascript:void(0);"
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>ACTIVO</span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 450 }}
                          >
                            TÍTULO
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 600 }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>MENSAJE</span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 200 }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                CREACIÓN
                              </span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 200 }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                EDICIÓN
                              </span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 20 }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by price"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}></span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 20 }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by price"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}></span>
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {carrusel.map((record, index) => {
                          return (
                            <tr key={`${record.id}_${record.titulo}`}>
                              <td>
                                <Switch
                                  checked={record.isActive}
                                  color="secondary"
                                  onClick={async () => {
                                    record.isActive = !record.isActive;
                                    await CarruselService.update(record);
                                    loadCarrusel();
                                  }}
                                />{" "}
                              </td>
                              <td
                                className={`${styles.linkNormal} ${styles.fwMedium}`}
                              >
                                <td>
                                  {record.titulo != null ? (
                                    String(record.titulo).slice(0, 30)
                                  ) : (
                                    <p className={`${styles.textMuted}`}>
                                      Sin título
                                    </p>
                                  )}
                                </td>
                              </td>
                              <td>
                                {record.subtitulo != null ||
                                record.subtitulo == "" ? (
                                  String(record.subtitulo).slice(0, 25)
                                ) : (
                                  <p className={`${styles.textMuted}`}>
                                    Sin mensaje
                                  </p>
                                )}
                              </td>

                              <td>
                                <i
                                  className="fa fa-calendar fa-1"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.createdAt).slice(0, 10)}
                                <br />{" "}
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.createdAt).slice(11, 16)}
                              </td>
                              <td>
                                <i
                                  className="fa fa-calendar fa-1"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.updateAt).slice(0, 10)}
                                <br />{" "}
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.updateAt).slice(11, 16)}
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    navigate(`editar/${record.id}`)
                                  }
                                  className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-pencil-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                  </svg>
                                </a>
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    navigate(`eliminar/${record.id}`)
                                  }
                                  className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-trash-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                  </svg>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* pagination, selected items */}
                  <div className={`${styles.row}`}>
                    <div
                      className={`${styles.col} ${styles.py3} ${styles.textCenter} ${styles.textMdStart}`}
                    >
                      {/* selected items 
                      <div className="dropup">
                        <a
                          className="btn btn-sm btn-primary"
                          href="javascript:void(0);"
                          role="button"
                          id="ddSelected"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="group-icon">
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1={18} y1={6} x2={6} y2={18} />
                              <line x1={6} y1={6} x2={18} y2={18} />
                            </svg>
                          </span>
                          <span>Selected items</span>
                        </a>
                        <ul
                          className="dropdown-menu dropdown-menu-clean my-2"
                          aria-labelledby="ddSelected"
                        >
                          <li>
                            <a className="dropdown-item" href="#form-list">
                              <svg
                                className={`${styles.textMuted}`}
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                              <span>Active</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className={`${styles.textMuted}`}
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                              <span>Innactive</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className={`${styles.textMuted}`}
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                              <span>Banned</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-danger"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="3 6 5 6 21 6" />
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                <line x1={10} y1={11} x2={10} y2={17} />
                                <line x1={14} y1={11} x2={14} y2={17} />
                              </svg>
                              <span className="w-100">Delete</span>
                            </a>
                          </li>
                        </ul>
                      </div>*/}
                    </div>
                    <TablePagination
                      style={{ color: "var(--color-dark-variant)" }}
                      component="div"
                      labelRowsPerPage="Items por pagina"
                      count={total}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    {/* <div className={`${styles.colMdAuto} ${styles.py3}`}> */}
                    {/* pagination */}
                    {/* <nav
                        className={`${styles.w100} ${styles.textCenter}`}
                        aria-label="Pagination"
                      > */}
                    {/* pagination : desktop */}
                    {/* <nav
                          className={`${styles.textCenter} ${styles.floatMdEnd} ${styles.mt3} ${styles.mtMd0} ${styles.dNone} ${styles.dMdFlex}`}
                          aria-label="Pagination"
                        >
                          <TablePagination
                            style={{ color: "var(--color-dark-variant)" }}
                            component="div"
                            classes="recent-orders"
                            labelRowsPerPage="Items por pagina"
                            count={total}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={limit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </nav>
                      </nav>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className={`${styles.cardHeader} ${styles.p4}`}>
                <div className={`${styles.row} ${styles.g3}`}>
                  <div
                    className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                  ></div>
                  {/* options */}
                  <div
                    className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                  >
                    <p style={{ color: "lightgrey" }}>V.1df6da3</p>
                  </div>
                </div>
              </div>
            </>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CarruselTable;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
