/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";

/* Servicios */
import TerminosService from "../../../services/Terminos.service";

import styles from "../layout/assets/css/core.min.module.css";

const TablaTerminos = () => {
  const contentHTML_contentcontactanos = convertFromHTML("");
  const [terminos, setTerminos] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [contenido, setContenido] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML_contentcontactanos.contentBlocks,
          contentHTML_contentcontactanos.entityMap
        )
      )
    )
  );
  const myTheme = createTheme({});

  useEffect(() => {
    getAviso();
  }, []);

  const getAviso = async () => {
    setLoading(true);
    try {
      const ter = await TerminosService.get();
      setTerminos(ter[0]);
      console.log("----" + ter[0].terminos);

      const contentHTML = convertFromHTML(ter[0].terminos);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(state));

      setContenido(content);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateTerminos = async () => {
    if (!terminos.terminos) {
      toast.info(
        "Error, tienes que agregar texto a los terminos y condiciones"
      );
    } else {
      setLoading(true);
      try {
        const dataTerminos = terminos;
        if (!terminos.id) {
          dataTerminos.id = 1;
        }
        const updateTerminos = await TerminosService.update(dataTerminos);
        toast.success("Terminos y condiciones actualizados con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getAviso();
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>TÉRMINOS Y CONDICIONES</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Editar sección de términos y condiciones.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <h4>Términos y condiciones</h4>
                  </div>
                </div>
                <form className="form-validate">
                  <div className={`${styles.row} ${styles.g4}`}>
                    <div className={`${styles.col}`}>
                      {/* Shipping address */}

                      <div className={`${styles.section} ${styles.mb4}`}>
                        <div className={`${styles.cardBody}`}>
                          <div className={`${styles.row} ${styles.g3}`}>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <ThemeProvider theme={myTheme}>
                                  <MUIRichTextEditor
                                    label="Escriba sus términos y condiciones"
                                    controls={[
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "highlight",
                                      "undo",
                                      "redo",
                                      "link",
                                      "numberList",
                                      "bulletList",
                                      "quote",
                                      "code",
                                      "clear",
                                    ]}
                                    inlineToolbar={true}
                                    onChange={(value) => {
                                      console.log(value.getCurrentContent());
                                      setTerminos({
                                        ...terminos,
                                        terminos: stateToHTML(
                                          value.getCurrentContent()
                                        ),
                                      });
                                    }}
                                    defaultValue={contenido}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className={`${styles.co12}`}></div>
              </div>

              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => updateTerminos()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className={`${styles.small}`}>Guardar</span>
                </button>
              </div>
            </>
          </main>
        </>
      )}
    </>
  );
};

export default TablaTerminos;
