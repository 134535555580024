/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import DropComponent from "../imagesDrop";
import DropZoneEdit from "../../DropZoneEdit";

/* Servicios */
import NoticiaService from "../../../services/Noticia.service";
import S3Service from "../../../services/S3.service";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

import styles from "../layout/assets/css/core.min.module.css";

const CrearCarrusel = () => {
  const { currentUser } = useContext(AuthContext);
  const [noticia, setNoticia] = useState({});
  const [categoria, setCategoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [etiquetas, setEtiquetas] = useState("");
  const [file, setFile] = useState(undefined);
  const [destacados, setDestacados] = useState(false);
  const [principal, setPrincipal] = useState(false);

  const myTheme = createTheme({});
  const navigate = useNavigate();
  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);

  useEffect(() => {
    getCategoria();
  }, []);

  const saveNoticia = async () => {
    if (!noticia.titulo) {
      toast.info("Error, tienes que agregar un titulo");
    } else if (noticia.cuerpoNoticia.length == 11) {
      toast.info("Error, tienes que colocar texto al blog");
    } /*else if (!file) {
      toast.info("Error, tienes que colocar una imagen");
    } */ else {
      setLoading(true);
      try {
        const noticiaSubir = { ...noticia, usuarioId: currentUser.id };

        // let imagenesArray = []; //Array para los id del s3

        // /* Subida de imagenes del carrusel */
        // for (const file of imagenesCarrusel) {
        //   const resultFile = await S3Service.upload(file);
        //   console.log("imagen carrusel guardad:" + resultFile.result.data);
        //   imagenesArray.push(resultFile.result.data);
        // }

        if (file) {
          const uploadPhoto = await S3Service.upload(file);
          noticiaSubir.imagen = uploadPhoto.result.data;
        }

        const {
          titulo,
          usuarioId,
          cuerpoNoticia,
          fraseCelebre,
          autorFraseCelebre,
          categoria,
          imagen,
        } = noticiaSubir;

        const dataNoticia = {
          titulo,
          usuarioId,
          cuerpoNoticia,
          fraseCelebre,
          autorFraseCelebre,
          categoria,
          imagen: [imagen],
        };
        const noticiaSave = await NoticiaService.create(dataNoticia);

        /*if (file) {
          const uploadPhoto = await S3Service.upload(file);
          noticiaSubir.imagen = uploadPhoto.result.data;
          await NoticiaService.create(noticiaSubir);
        } else {
          await NoticiaService.create(noticiaSubir);
        }*/

        toast.success("Blog creado con éxito!");
      } catch (error) {
        toast.error(error);
      } finally {
        navigate("/blog");
      }
    }
  };

  const getCategoria = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      setCategoria(data);
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>Nueva entrada</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li className={`${styles.breadcrumbItem}`}>
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/blog")}
                    >
                      Blog
                    </a>
                  </li>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Nuevo
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className={`${styles.row} ${styles.g4}`}>
                <div className={`${styles.col}`}>
                  {/* Shipping address */}
                  <div
                    className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                  >
                    Entrada
                  </div>
                  <div className={`${styles.section} ${styles.mb4}`}>
                    <div className={`${styles.cardBody}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Título"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  titulo: e.target.value.toUpperCase(),
                                })
                              }
                              value={noticia.titulo}
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm12}`}>
                          <div className={`${styles.formFloating}`}>
                            <div>
                              <ThemeProvider theme={myTheme}>
                                <MUIRichTextEditor
                                  label="Cuerpo de la entrada... "
                                  controls={[
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "highlight",
                                    "undo",
                                    "redo",
                                    "link",
                                    "numberList",
                                    "bulletList",
                                    "quote",
                                    "code",
                                    "clear",
                                    // "save"
                                  ]}
                                  // onSave={save}
                                  inlineToolbar={true}
                                  onChange={(value) =>
                                    setNoticia({
                                      ...noticia,
                                      cuerpoNoticia: stateToHTML(
                                        value.getCurrentContent()
                                      ),
                                    })
                                  }
                                />
                              </ThemeProvider>
                              <br></br>
                              <br></br>
                            </div>
                          </div>
                          {file ? (
                            <div>
                              <div className={`${styles.colLg12}`}>
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    style={{
                                      height: 300,
                                      objectFit: "contain",
                                      width: "-webkit-fill-available",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    style={{
                                      width: "-webkit-fill-available",
                                    }}
                                  >
                                    {file.name}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div style={{ width: "95%", marginLeft: "1.5%" }}>
                            <DropZoneEdit
                              saveFile={async (file) => {
                                setFile(file);
                              }}
                            />
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Frase celebre"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  fraseCelebre: e.target.value,
                                })
                              }
                              value={noticia.fraseCelebre}
                            />
                            <label>Frase celebre</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              placeholder="Frase celebre"
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  autorFraseCelebre: e.target.value,
                                })
                              }
                              value={noticia.autorFraseCelebre}
                            />
                            <label>Autor</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <select
                              className={`${styles.formControl} ${styles.shadowNone}`}
                              id="shipping__country"
                              name="shipping[country]"
                              aria-label="Country"
                              onChange={(e) => {
                                setNoticia({
                                  ...noticia,
                                  categoria: e.target.value,
                                });
                              }}
                              value={noticia.categoria}
                            >
                              <option disabled="disabled" selected="selected">
                                Selecciona una
                              </option>
                              {categoria.map((record, index) => {
                                return <option>{record.tipo}</option>;
                              })}
                            </select>
                            <label>Categoria</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={`${styles.co12}`}>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => saveNoticia()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className={`${styles.small}`}>Guardar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default CrearCarrusel;
