import httpClient from "./HttpClient";
const prefix = "/terminos";

export default class TerminosService {
  static async update(terminos) {
    return (await httpClient.put(`${prefix}/${terminos.id}`, terminos)).data;
  }

  static async get() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

}
