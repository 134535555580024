import React, { useEffect, useState } from "react";
import BlogItem from "./BlogItem";
import Siguenos from "./Siguenos";
import { useNavigate } from "react-router-dom";

import CategoriaBlogService from "../../../services/CategoriaBlog.service";

import FooterService from "../../../services/Footer.service";

import NoticiaService from "../../../services/Blog.service";
import EntradasRecientes from "./EntradasRecientes";
import { TablePagination } from "@mui/material";

import banner from "./blog.jpg";
//imagenes

const BlogGrid = () => {
  const [loading, setLoading] = useState(true);
  const [Entradas, setEntradas] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [precio, setPrecio] = useState("normal");
  const [categorias, setCategorias] = useState([]);
  const [redes, setRedes] = useState([]);
  const [tag, setTag] = useState(false);
  const [busqueda, setBusqueda] = useState(false);
  const [noticias, setNoticias] = useState([]);
  const [tabUno, setTabUno] = useState("active");
  const [tabDos, setTabDos] = useState("");
  const [tabUnoCuerpo, setTabUnoCuerpo] = useState("show active");
  const [tabDosCuerpo, setTabDosCuerpo] = useState("");
  const [buscar, setBuscar] = useState("");
  const [ultimasNoticias, setultimasNoticias] = useState([]);
  const navigate = useNavigate();
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [update]);

  useEffect(() => {
    getEntradas();
    getCategorias();
    getNoticias();
    getUltimasNoticias();
    getRedes();
    window.scrollTo(0, 0);
  }, [page, limit]);

  const getEntradas = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      const data = await NoticiaService.list(limit, page * limit);
      setEntradas(data.data);
      setTotal(data.total);
      onUpdate();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdate = () => {
    setUpdate(!update);
  };
  const getEntradasFiltroCategoria = async (filtro) => {
    setLoading(true);
    setEntradas([]);
    try {
      const data = await NoticiaService.filtroCategoria(
        filtro,
        limit,
        page * limit
      );
      setEntradas(data.data);
      setTotal(data.total);
      onUpdate();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      console.log(Entradas);
    }
  };

  const getEntradasFiltroBuscar = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      if (buscar == "") {
        const data = await NoticiaService.list(limit, page * limit);
        setEntradas(data.data);
        setTotal(data.total);
        setBusqueda(false);
      } else {
        const data = await NoticiaService.filtroBuscar(buscar);
        setEntradas(data.data);
        setTotal(data.total);
        setBusqueda(true);
        onUpdate();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      console.log(Entradas);
    }
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      setCategorias(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getRedes = async () => {
    setLoading(true);
    try {
      const data = await FooterService.getRedes();
      setRedes(data[0]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      setultimasNoticias(data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUltimasNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      setNoticias(data.data);
    } catch (error) {
    } finally {
    }
  };

  const handleCategoria = (categoria) => {
    getEntradasFiltroCategoria(categoria);
    setTag(true);
  };

  return (
    <>
      <>
        {/* PAGE TITLE
  ================================================== */}
        <section
          className="page-title-section bg-img cover-background left-overlay-dark"
          data-overlay-dark={6}
          data-background="img/banner/page-title.jpg"
          style={{ backgroundImage: `url(${banner})`, height: "20rem" }}
        >
          <div className="container position-unset">
            <div className="page-title mx-1-6 mx-lg-2-0 mx-xl-2-6 mx-xxl-2-9">
              <div className="row">
                <div className="col-md-12">
                  <h1>Blog</h1>
                </div>
                <div className="col-md-12">
                  <ul className="ps-0">
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate("/")}
                      >
                        Inicio
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Blog</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <>
        {/* BLOG LIST
  ================================================== */}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-1-9 mb-lg-0">
                <div className="row mt-n1-9">
                  {Entradas.map((record, index) => {
                    return (
                      <BlogItem
                        key={index}
                        id={record.id}
                        titulo={record.titulo}
                        idAutor={record.usuarioId}
                        fechaU={record.updateAt}
                        categoria={record.categoria}
                        imagenes={record.imagen}
                        cuerpo={record.cuerpoNoticia}
                        handleCategoria={handleCategoria}
                      />
                    );
                  })}
                </div>

                <div className="row wow fadeIn" data-wow-delay="200ms">
                  <div className="col-sm-12">
                    {/* start pager  */}
                    <div className="text-center mt-2-6 mt-lg-6">
                      <div className="pagination">
                        <TablePagination
                          style={{ color: "var(--color-dark-variant)" }}
                          component="div"
                          labelRowsPerPage="Items por pagina"
                          count={total}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={limit}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    </div>
                    {/* end pager */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sidebar ps-xl-4">
                  <div
                    className="widget mb-1-9 p-4 wow fadeIn"
                    data-wow-delay="200ms"
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Buscar..."
                        aria-describedby="basic-addon2"
                        value={buscar}
                        onChange={(e) => {
                          setBuscar(e.target.value.toUpperCase());
                        }}
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            getEntradasFiltroBuscar();
                          }
                        }}
                      />
                      <div className="input-group-append">
                        {!busqueda ? (
                          <button
                            className="butn-style3"
                            onClick={() => {
                              getEntradasFiltroBuscar();
                            }}
                          >
                            <i className="fa fa-search" />
                          </button>
                        ) : (
                          <button
                            className="butn-style3"
                            onClick={() => {
                              setBuscar((prev) => "");
                              setBusqueda(false);
                              getEntradas();
                            }}
                          >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <EntradasRecientes />
                  <div
                    className="widget mb-1-9 p-4 wow fadeIn"
                    data-wow-delay="600ms"
                  >
                    <h3 className="mb-1-6 h5">Categorías</h3>
                    <ul className="list-style5 mb-0 ps-0">
                      <li>
                        <a
                          href="javascript:void(0);"
                          onClick={() => {
                            setBuscar((prev) => "");
                            setBusqueda(false);
                            getEntradas();
                          }}
                        >
                          <i className="ti-angle-right text-primary me-2 display-32" />
                          Todo
                        </a>
                      </li>
                      {categorias.map((record, index) => {
                        return (
                          <li>
                            <a
                              href="javascript:void(0);"
                              onClick={() => {
                                getEntradasFiltroCategoria(record.tipo);
                                setTag(true);
                              }}
                            >
                              <i className="ti-angle-right text-primary me-2 display-32" />
                              {record.tipo}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <Siguenos />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      {/* element wrapper ends */}
    </>
  );
};

export default BlogGrid;
