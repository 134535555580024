import httpClient from "./HttpClient";

const prefix = "/equipo";

export default class EquipoService {
  static async create(equipo) {
    return (await httpClient.post(`${prefix}/`, equipo)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async update(equipo) {
    return (await httpClient.put(`${prefix}/${equipo.id}`, equipo)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async login(data) {
    return (await httpClient.post(`${prefix}/login`, data)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }

}
