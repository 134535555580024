import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//servicio
import S3Service from "../../../services/S3.service";
import logo from "../assets/img/esculologoblanco.png";

const Portada_ServicioItem = ({
  id,
  titulo,
  precioU,
  precioE,
  descuento,
  imagenes,
  fecha,
  tipo,
  descripcion,
  incluye,
  subtitulo,
}) => {
  const navigate = useNavigate();
  let precioFinal = 0;
  const [imag, setImag] = useState([]);
  const [precio, setPrecio] = useState(0);
  let precios = 0;
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  if (descuento > 0) {
    const descuentoProps = descuento;
    const precioOriginal = precio;
    precioFinal = precioOriginal - 100 / (precioOriginal / descuentoProps);
  }

  if (tipo == "Empresa") {
    if (precioE == null) {
      precios = 0;
    } else {
      for (const pre of precioE) {
        if (pre.props.tipo == "Adulto") {
          precios = pre.props.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  } else {
    if (precioU == null) {
      precios = 0;
    } else {
      for (const pre of precioU) {
        if (pre.props.tipo == "Adulto") {
          precios = pre.props.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [imagenesItem, setImagenes] = useState([]);

  useEffect(() => {
    if (imagenes.length > 0) {
      getImagen();
    }
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes[0]);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imagen);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const cortarTexto = (entry) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(entry, "text/html");
    return doc.body.innerText;
  };

  return (
    <>
      <div
        style={{ minWidth: "18rem", maxWidth: "25rem" }}
        className="card card-style7 border-0 my-3 col-sm-6 col-lg-3 mt-1-6"
      >
        <img
          onClick={() => navigate(`/Servicios/${id}`)}
          className="card-img-top"
          style={{
            height: "250px",
            width: "800px",
          }}
          src={imag}
        />
        <div
          className="card-body position-relative text-center p-1-9 pt-6"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "2rem 1rem 1rem 1rem",
          }}
        >
          <div className="icon-img rounded" style={{ lineHeight: "55px" }}>
            <img
              src={logo}
              alt="Avatar"
              class="image"
              style={{
                height: "100%",
                padding: "0.2rem",
                filter: "brightness(2)",
              }}
            />
          </div>
          <h3 className="h5 mb-3">
            <a
              href="javascript:void(0);"
              onClick={() => navigate(`/Servicios/${id}`)}
            >
              {titulo}
            </a>
          </h3>
          <p>
            {" "}
            <p
              dangerouslySetInnerHTML={{
                __html: String(cortarTexto(descripcion))
                  .slice(0, 94)
                  .concat("..."),
              }}
              style={{
                height: "100px",
                overflow: "hidden",
              }}
            ></p>
          </p>
          <a
            onClick={() => navigate(`/Servicios/${id}`)}
            href="javascript:void(0);"
            className="text-secondary font-weight-600 text-primary-hover"
          >
            Ver más{" "}
            <i className="ti-arrow-right ms-2 align-middle display-30" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Portada_ServicioItem;
