import React, { useEffect, useState } from "react";
import Slide from "./Slide";
import { useNavigate } from "react-router-dom";

//Components
import Portada_Servicios from "./Portada_Servicios";
import Portada_Blog from "./Portada_Blog";
import Testimonios from "./Testimonios";
import Nosotros from "./Nosotros";
import Portada_Certs from "./Portada_Certs";
import Portada_Equipo from "./Portada_Equipo";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import WOW from "wowjs";

import img from "../assets/img/why-choose1.jpg";
import bgstripes from "./assets/img/bg-stripes.png";
import IndexService from "../../../services/Index.service";
import logo from "../assets/img/SORIA_20_horizontal-removebg.png";
import logoVertical from "../assets/img/vertical SORIA 20 ANIVERSARIO-circular.png";
import img1 from "./img1.png";
import img2 from "./img2.png";
import img3 from "./img3.png";
import titulo from "../assets/img/titulo.jpg";
import detalles from "../assets/img/detalles.jpg";
import logoSoriaWeb from "../assets/img/LogoSoriaWeb.jpg";
import presenta from "../assets/img/presenta.jpg";
import presentacion from "../assets/img/presentacion.jpg";

import img9 from "./img9.jpg";
import img10 from "./img10.jpg";
import img11 from "./img11.jpg";

const Index = () => {
  const [informacion, setInformacion] = useState();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const matchesmd = useMediaQuery("(min-width:850px)");

  useEffect(() => {
    window.scrollTo(0, 0);
    getInformacion();
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const getInformacion = async () => {
    let data = await IndexService.get();
    setInformacion(data[0]);
  };

  return (
    <>
      <div>
        {/*====================================
          ——— BEGIN MAIN SLIDE LIST
          ===================================== */}
        <Slide />

        {/* CLIENTS
  ================================================== */}
        <section class="pt-10 pt-lg-2-3 pb-0" style={{ paddingTop: "2.3rem" }}>
          <div class="container">
            <div class="row justify-content-end">
              <div class="col-lg-5 col-xxl-6">
                <div class="row align-items-center">
                  <div class="col-4">
                    <div class="px-md-4 px-lg-0 px-xxl-3 client-style1"></div>
                  </div>
                  <div class="col-4">
                    <div class="px-md-4 px-lg-0 px-xxl-3 client-style1"></div>
                  </div>
                  <div class="col-4">
                    <div class="px-md-4 px-lg-0 px-xxl-3">
                      <img src={logo} alt="..." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ABOUT US
  ================================================== */}
        <section class="about-section" style={{ paddingTop: "40px" }}>
          <div class="container mt-lg-3 mt-xl-0">
            <div class="row clearfix align-items-xl-center">
              <div
                class="col-lg-6 text-column mb-2-9 mb-lg-0 wow fadeIn"
                data-wow-delay="200ms"
              >
                <div class="pe-xl-2-9">
                  <h2
                    class="h1 mb-4"
                    dangerouslySetInnerHTML={{
                      __html: String(informacion?.contactanos),
                    }}
                  ></h2>
                  <p
                    class="pb-3"
                    dangerouslySetInnerHTML={{
                      __html: String(informacion?.blog),
                    }}
                  ></p>
                  <div class="clearfix d-sm-flex align-items-center mb-2-6">
                    <ul class="list-style1 mb-1-9 mb-sm-0">
                      <li> Asesoría personalizada y profesional.</li>
                    </ul>
                    <div class="since">
                      <span
                        class="txt text-center text-white"
                        style={{ zIndex: "1" }}
                      >
                        Desde <br />
                        2003
                      </span>
                      <span
                        style={{
                          content: "",
                          position: "absolute",
                          left: "-10px",
                          top: "0px",
                          width: "80px",
                          height: "80px",
                          background: "#e8e9e9",
                          borderRadius: "50%",
                        }}
                      ></span>
                    </div>
                  </div>
                  <div>
                    <a
                      class="butn-style3 medium"
                      href="javascript:void(0)"
                      onClick={() => navigate("/nosotros")}
                    >
                      Conocer más
                    </a>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-6 position-relative wow fadeIn"
                data-wow-delay="400ms"
                style={{ zIndex: "1" }}
              >
                <div class="row align-items-center ps-lg-1-9 position-relative z-index-9">
                  <div class="col-sm-7 d-none d-sm-block">
                    <img
                      src={img2}
                      class="rounded"
                      style={{ objectFit: "cover", height: "30em" }}
                      alt="..."
                    />
                  </div>
                  <div class="col-sm-5 text-center text-sm-start">
                    <img
                      src={img3}
                      class="rounded"
                      style={{ objectFit: "cover", height: "19em" }}
                      alt="..."
                    />
                    <div class="text-center mt-4">
                      <a
                        href="javascript:void(0)"
                        onClick={() => navigate("/Servicios")}
                        class="text-dark text-primary-hover font-weight-600"
                      >
                        Ver todos los servicios
                        <i class="ti-arrow-right ms-2 align-middle display-30"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="circle-md left-10 top-15 border-secondary-color d-none d-xl-block"></div>
          <div
            class="bg-img bg-stripes right-25 top-15 ani-left-right d-none d-xl-block"
            style={{ backgroundImage: `url(${bgstripes})` }}
          ></div>
        </section>

        {/* SERVICE
  ================================================== */}
        <Portada_Servicios />

        {/* VIDEO
  ================================================== */}
        <section class="whychooseus1 py-0">
          <div class="row">
            <div
              class="col-lg-6 bg-img cover-background min-height p-0 wow fadeIn"
              data-wow-delay="200ms"
              data-overlay-dark="2"
              style={{
                backgroundImage: `url(${presentacion})`,
                paddingBottom: "0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a
                class="navbar-brand logowhite"
                href="javascript:void(0);"
                onClick={() => {
                  navigate(`/`);
                }}
                data-scroll-nav="0"
              >
                <img
                  src={logoVertical}
                  alt="SORIAsesores - Asesores profesionales en seguros de vida"
                  title="SORIAsesores - Asesores profesionales en seguros de vida"
                  style={{
                    maxHeight: "170px",
                    position: "absolute",
                    zIndex: 1,
                    bottom: 0,
                    left: 0,
                    background: "#ffff",
                    padding: "5px 30px",
                  }}
                />
              </a>
              <div class="h-100 story-video">
                <div class="d-table h-100 text-center mx-auto position-relative z-index-1">
                  <div class="d-table-cell align-middle">
                    <div class="d-inline-block align-middle z-index-1 text-start">
                      <a
                        class="video video_btn"
                        target="_blank"
                        href="https://www.youtube.com/watch?v=NIHeC907EMM"
                      >
                        <i class="fa fa-play"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 p-0 bg-primary wow fadeIn"
              data-wow-delay="400ms"
            >
              <div class="p-1-9 p-sm-6 p-xxl-8">
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <span class="d-block mb-2 text-white text-uppercase fw-bold">
                      WEBINAR
                    </span>
                    <h2 class="mb-1-9 text-white">
                      "Como organizar nuestra empresa en tiempos de covid"
                    </h2>
                    <p class="text-white mb-5 w-xxl-80">
                      La importancia de un seguro empresarial
                    </p>
                    <div class="small-box">
                      <div class="media mb-1-9">
                        <i class="ti-pencil-alt text-primary me-3 me-lg-4"></i>
                        <div class="media-body">
                          <h4 class="h5 text-white">Presenta</h4>
                          <p class="mb-0 text-white w-xxl-70 opacity9">
                            LUTC Moisés Soria Iriarte
                          </p>
                        </div>
                      </div>
                      <div class="media mb-1-9">
                        <div class="media-body">
                          <h4 class="h5 text-white">
                            Con la participación de dos grandes empresarios:
                          </h4>
                          <p class="mb-0 text-white w-xxl-70 opacity9">
                            Lic. Miguel Angel Aguilar
                          </p>
                          <p>- Grupo TAI -</p>
                          <p class="mb-0 text-white w-xxl-70 opacity9">
                            Ing. José Manuel Nuñez Corzo
                          </p>
                          <p>- Corp. Nuñez Corzo -</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ABOUT US
  ================================================== */}
        <Nosotros />

        {/* TEAM
  ================================================== */}
        {/* <Portada_Equipo /> */}

        {/* TESTIMONIALS
  ================================================== */}
        {/* <Testimonios /> */}

        {/* WHY CHOOSE US
  ================================================== */}
        <Portada_Certs />

        {/* BLOG
  ================================================== */}
        <Portada_Blog />
      </div>{" "}
    </>
  );
};

export default Index;
