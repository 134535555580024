import React, { useEffect, useState } from "react";

/*Servicios*/
import S3Service from "../../../services/S3.service";

//imagenes
import logo from "../assets/img/default.png";

const Portada_ItemTestimonios = ({ data }) => {
  const [imagen, setImagen] = useState("");

  useEffect(() => {
    setImagen("");
    getImage();
  }, []);

  const getImage = async () => {
    try {
      await S3Service.get(data.imagen).then((dataimagen) => {
        if (dataimagen.result) {
          setImagen(dataimagen.result);
        } else {
          setImagen(logo);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        style={{ width: "304.25px", textAlign: "start", paddingBottom: "5rem" }}
      >
        <div class="mb-1-9">
          <img
            src={imagen}
            class="rounded-circle"
            alt="..."
            style={{ width: "80px", height: "80px" }}
          />
        </div>
        <div>
          <p class="w-lg-90 mb-2-3">
            <i class="fas fa-quote-left d-inline-block text-primary me-3 display-26"></i>
            {data.texto}
          </p>
          <h4 class="h5 mb-1">{data.nombre}</h4>
          <p class="mb-0">{data.ocupacion}</p>
        </div>
      </div>
    </>
  );
};

export default Portada_ItemTestimonios;
