import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <div className="col-12 col-lg-4 order-2 order-lg-1">
      <div className="sticky-kit z-index-1">
        <div className="card mb-4">
          <div className="card-body p-md-4">
            <div className="border-bottom border-light mb-3 pb-2 text-gray-700">
              <h2 className="h5">Tienes preguntas?</h2>
              <p className="small">
                Si tienes preguntas realacionadas a nuestro servicio, no dudes
                en
                <button
                  className="link-normal text-decoration-none text-primary"
                  style={{
                    border: "none",
                    backgroundColor: "unset",
                    paddingInline: "0.2rem",
                  }}
                  onClick={() => navigate(`/contactanos`)}
                >
                  contactarnos.
                </button>
              </p>
            </div>
            <ul className="nav flex-column m-0 p-0">
              <li className="nav-item">
                <button
                  className="nav-link px-0 link-normal text-primary"
                  style={{ border: "none", backgroundColor: "unset" }}
                  onClick={() => navigate(`/aviso`)}
                >
                  <svg
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                  Aviso de privacidad
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link px-0 link-normal text-primary"
                  style={{ border: "none", backgroundColor: "unset" }}
                  onClick={() => navigate(`/terminos`)}
                >
                  <svg
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                  Términos de servicio
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link px-0 link-normal text-primary"
                  style={{ border: "none", backgroundColor: "unset" }}
                  onClick={() => navigate(`/contactanos`)}
                >
                  <svg
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                  Contáctanos
                </button>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="card">
            <div className="card-body p-lg-4">
                <h3 className="h6 fw-bold">Smarty Inc.</h3>
                <ul className="list-unstyled mb-0 small">
                    <li>
                        <b>Phone:</b>{" "}
                        <a className="link-normal" href="tel:18005652390">
                            1-800-565-2390
                        </a>
                    </li>
                    <li>
                        <b>Address:</b>
                        <address className="mb-0 d-inline">
                            PO Box 21132, Melbourne 2355 Australia
                        </address>
                    </li>
                </ul>
            </div>
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
