import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Slide from "./Slide";

import styles from "./assets/css/core.min.module.css";

const PrivateLayout = () => {
  useEffect(() => {
    //require("./css/public.css");
    //require("./main.scss");
    //require('./html_frontend/assets/css/core.css');
    ///require('./assets/css/core.min.css');
    //require('./html_frontend/assets/css/vendor_bundle.css');
    require("./html_admin/assets/css/vendor_bundle.min.css");
  }, []);

  return (
    <body
      id={styles["body"]}
      className={`${styles.layoutAdmin} ${styles.asideSticky} ${styles.bgLime} ${styles.headerSticky}`}
    >
      <div
        id={styles["wrapper"]}
        className={`${styles.dFlex} ${styles.alignItemsStretch} ${styles.flexColumn}`}
      >
        <Header />
        <div
          id={styles["wrapper_content"]}
          className={`${styles.dFlex} ${styles.flexFill} ${styles.flexColumn}`}
        >
          <Slide />
          <main
            className={`${styles.mxAuto} ${styles.flexFill}`}
            style={{ width: "100%" }}
          >
            <Outlet />
          </main>
        </div>
      </div>
    </body>
  );
};

export default PrivateLayout;
