import React, { useEffect, useState, useContext } from "react";
import NosotrosService from "../../../services/Nosotros.service";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import WOW from "wowjs";

import NosotrosX from "../portada/Nosotros";
import Testimonios from "../portada/Testimonios";

import background from "../assets/img/map.png";
import banner from "./nosotros.jpg";

import EquipoItem from "./EquipoItem";
import EquipoService from "../../../services/Equipo.Service";
import S3Service from "../../../services/S3.service";
import LabTabs from "./Tabs";

const Nosotros = () => {
  const [loading, setLoading] = useState(true);
  const [nosotros, setNosotros] = useState([]);
  const [equipo, setEquipos] = useState([]);
  const [imagenesTab, setImagenesTab] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getNosotros();
    getEquipo();
    window.scrollTo(0, 0);
    const wow = new WOW.WOW();
    wow.init();
    // getImagenes();
  }, []);

  const getNosotros = async () => {
    try {
      const data = await NosotrosService.getAll();
      setNosotros(data[0]);
      if (data[0].tabs) {
        for (const tab of data[0].tabs) {
          const result = await S3Service.get(tab.fileTab);
          setImagenesTab((imagenesTab) => [...imagenesTab, result.result]);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const getEquipo = async () => {
    setLoading(true);
    try {
      const data = await EquipoService.list(4, 0);
      setEquipos(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const getImagenes = async () => {
  //   try {
  //     if (nosotros.tabs) {
  //       for (const tab of nosotros.tabs) {
  //         const result = await S3Service.get(tab.fileTab);
  //         setImagenesTab((imagenesTab) => [...imagenesTab, result.result]);
  //       }
  //     }
  //   } catch (error) {}
  // };

  return (
    <>
      <section
        className="page-title-section bg-img cover-background left-overlay-dark"
        data-overlay-dark={6}
        data-background="img/banner/page-title.jpg"
        style={{
          backgroundImage: `url(${banner})`,
          height: "20rem",
        }}
      >
        <div className="container position-unset">
          <div className="page-title mx-1-6 mx-lg-2-0 mx-xl-2-6 mx-xxl-2-9">
            <div className="row">
              <div className="col-md-12">
                <h1>Sobre nosotros</h1>
              </div>
              <div className="col-md-12">
                <ul className="ps-0">
                  <li>
                    <a href="javascript:void(0);" onClick={() => navigate("/")}>
                      Inicio
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Nosotros</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ABOUT US
  ================================================== */}
      <NosotrosX />

      {/* HISTORY
  ================================================== */}
      <section className="pt-0">
        <div className="container">
          <div
            className="text-center mb-2-9 mb-lg-6 wow fadeIn"
            data-wow-delay="100ms"
          >
            <span
              className=" mb-2 d-block fw-bold text-uppercase"
              style={{ color: "#5F0F46" }}
            >
              Nuestra historia
            </span>
            <div
              className="mb-0"
              dangerouslySetInnerHTML={{
                __html: nosotros?.Historia,
              }}
              style={{ textAlign: "justify" }}
            ></div>
          </div>
          <div className="row wow fadeIn" data-wow-delay="200ms">
            <div className="horizontaltab tab-style3">
              <LabTabs tabs={nosotros?.tabs} imagenesTab={imagenesTab} />
              {/* <Tabs
                defaultActiveKey="2"
                id="justify-tab-example"
                className="mb-3"
                justify
                resp-tabs-list
                hor_1
                text-center
              >
                <Tab eventKey="2" title="200X">
                  <div>
                    <div className="row align-items-center">
                      <div className="col-lg-6 order-lg-1 order-2">
                        <div className="pe-0 pe-lg-3 pe-xl-4">
                          <h4 className="mb-3">Established Secure</h4>
                          <p>
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour, or randomised
                            words which don't look even slightly believable.
                          </p>
                          <ul className="list-style1 mb-4">
                            <li>Life time supports</li>
                            <li>Solve your problem with us</li>
                            <li>We Provide Awesome Services</li>
                            <li>Your business deserves best software</li>
                          </ul>
                          <a
                            href="javascritp:void(0);"
                            className="butn-style3 medium"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-6 order-lg-2 order-1 mb-4 mb-lg-0">
                        <div className="box-shadow-large">
                          <img src="img/about/history1.jpg" alt="..." />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="20" title="200X">
                  <div>
                    <div className="row align-items-center">
                      <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className="box-shadow-large">
                          <img src="img/about/history2.jpg" alt="..." />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="ps-0 ps-lg-3 ps-xl-4">
                          <h4 className="mb-3">Registered as a Company</h4>
                          <p>
                            Many desktop publishing packages and web page
                            editors now use Lorem Ipsum as their default model
                            text, and a search for 'lorem ipsum' will uncover
                            many web sites still in their infancy.
                          </p>
                          <ul className="list-style1 mb-4">
                            <li>Life time supports</li>
                            <li>Solve your problem with us</li>
                            <li>We Provide Awesome Services</li>
                            <li>Your business deserves best software</li>
                          </ul>
                          <a
                            href="javascritp:void(0);"
                            className="butn-style3 medium"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="30" title="20XX">
                  <div>
                    <div className="row align-items-center">
                      <div className="col-lg-6 order-lg-1 order-2">
                        <div className="pe-0 pe-lg-3 pe-xl-4">
                          <h4 className="mb-3">Launch a new branch</h4>
                          <p>
                            It uses a dictionary of over 200 Latin words,
                            combined with a handful of model sentence
                            structures, to generate Lorem Ipsum which looks
                            reasonable. The generated Lorem Ipsum is therefore
                            always free from repetition
                          </p>
                          <ul className="list-style1 mb-4">
                            <li>Life time supports</li>
                            <li>Solve your problem with us</li>
                            <li>We Provide Awesome Services</li>
                            <li>Your business deserves best software</li>
                          </ul>
                          <a
                            href="javascritp:void(0);"
                            className="butn-style3 medium"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-6 order-lg-2 order-1 mb-4 mb-lg-0">
                        <div className="box-shadow-large">
                          <img src="img/about/history3.jpg" alt="..." />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="40" title="20XX">
                  <div>
                    <div className="row align-items-center">
                      <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className="box-shadow-large">
                          <img src="img/about/history4.jpg" alt="..." />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="ps-0 ps-lg-3 ps-xl-4">
                          <h4 className="mb-3">Strategic plans</h4>
                          <p>
                            The point of using Lorem Ipsum is that it has a
                            more-or-less normal distribution of letters, as
                            opposed to using 'Content here, content here',
                            making it look like readable English. Many desktop
                            publishing packages.
                          </p>
                          <ul className="list-style1 mb-4">
                            <li>Life time supports</li>
                            <li>Solve your problem with us</li>
                            <li>We Provide Awesome Services</li>
                            <li>Your business deserves best software</li>
                          </ul>
                          <a
                            href="javascritp:void(0);"
                            className="butn-style3 medium"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="50" title="20XX">
                  <div>
                    <div className="row align-items-center">
                      <div className="col-lg-6 order-lg-1 order-2">
                        <div className="pe-0 pe-lg-3 pe-xl-4">
                          <h4 className="mb-3">Awards win</h4>
                          <p>
                            All the Lorem Ipsum generators on the Internet tend
                            to repeat predefined chunks as necessary, making
                            this the first true generator on the Internet. It
                            uses a dictionary of over 200 Latin words, combined.
                          </p>
                          <ul className="list-style1 mb-4">
                            <li>Life time supports</li>
                            <li>Solve your problem with us</li>
                            <li>We Provide Awesome Services</li>
                            <li>Your business deserves best software</li>
                          </ul>
                          <a
                            href="javascritp:void(0);"
                            className="butn-style3 medium"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-6 order-lg-2 order-1 mb-4 mb-lg-0">
                        <div className="box-shadow-large">
                          <img src="img/about/history5.jpg" alt="..." />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs> */}
            </div>
          </div>
        </div>
      </section>

      {/* TESTIMONIALSs
  ================================================== */}
      <Testimonios />

      {/* TEAM
  ================================================== */}
      {/* <section>
        <div className="container">
          <div
            className="text-center mb-2-9 mb-lg-6 wow fadeIn mx-auto w-95 w-md-75 w-lg-60"
            data-wow-delay="100ms"
          >
            <span className="text-secondary mb-2 d-block fw-bold text-uppercase">
              Nuestro equipo
            </span>
            <h2 className="mb-0 h1">
              Obten asesoria de nuestros agentes profesionales
            </h2>
          </div>
          <div className="row mt-n1-9">
            {equipo.map((record, index) => {
              return <EquipoItem equipo={record} />;
            })}
          </div>
        </div>
      </section> */}

      {/* CLIENT
  ================================================== */}
    </>
  );
};

export default Nosotros;
