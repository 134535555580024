/* React */
import React, { useState, useEffect } from "react";
/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import styles from "../layout/assets/css/core.min.module.css";
import DropZone from "../../DropZone";
import S3Service from "../../../services/S3.service";
/* Servicios */
import NosotrosService from "../../../services/Nosotros.service";
import DropComponent from "../imagesDrop";

const TablaContactanos = () => {
  const contentHTML = convertFromHTML("");
  const [nosotros, setNosotros] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [contenido, setContenido] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        )
      )
    )
  );
  const [mision, setMision] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        )
      )
    )
  );
  const [vision, setVision] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        )
      )
    )
  );
  const [historia, setHistoria] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        )
      )
    )
  );
  const [tabsArray, setTabsArray] = useState([]);
  const [tabsContador, setTabsContador] = useState([]);
  const [tab, setTab] = useState({});
  const [fileTab, setFileTab] = useState(undefined);
  const myTheme = createTheme({});
  const matches = useMediaQuery("(min-width:600px)");
  const [oldImages, setOldImages] = useState([]);

  const [tituloPorque, setTituloPorque] = useState("");
  const [imagenesporque, setImagenesPorque] = useState([]);

  useEffect(() => {
    getNosotros();
  }, []);

  const getNosotros = async () => {
    setImagenesPorque([]);
    setLoading(true);
    try {
      const cont = await NosotrosService.getAll();
      if (!cont[0]) {
        return;
      }
      setNosotros(cont[0]);
      setTituloPorque(cont[0].tituloPorque ? cont[0].tituloPorque : "");

      const contentHTML = convertFromHTML(cont[0].Cuerpo);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(state));
      setContenido(content);
      console.log(content);

      const contentHTMLMision = convertFromHTML(cont[0].Mision);
      const stateMision = ContentState.createFromBlockArray(
        contentHTMLMision.contentBlocks,
        contentHTMLMision.entityMap
      );
      const contentMision = JSON.stringify(convertToRaw(stateMision));
      setMision(contentMision);

      const contentHTMLVision = convertFromHTML(cont[0].Vision);
      const stateVision = ContentState.createFromBlockArray(
        contentHTMLVision.contentBlocks,
        contentHTMLVision.entityMap
      );
      const contentVision = JSON.stringify(convertToRaw(stateVision));
      setVision(contentVision);

      const contentHTMLHistoria = convertFromHTML(cont[0].Historia);
      const stateHistoria = ContentState.createFromBlockArray(
        contentHTMLHistoria.contentBlocks,
        contentHTMLHistoria.entityMap
      );
      const contentHistoria = JSON.stringify(convertToRaw(stateHistoria));
      setHistoria(contentHistoria);

      if (cont[0].tabs) {
        for (const tab of cont[0].tabs) {
          const result = await S3Service.get(tab.fileTab);
          setOldImages((oldImages) => [...oldImages, result.result]);
          tab.imagen = result.result;
        }
        setTabsArray(cont[0].tabs);
      }

      if (cont[0].imagenesPorque) {
        for (const imagen of cont[0].imagenesPorque) {
          const result = await S3Service.get(imagen);
          setImagenesPorque((imagenesProducto) => [
            ...imagenesProducto,
            result.result,
          ]);
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateNosotros = async () => {
    if (!nosotros.Cuerpo) {
      toast.info("Error, tienes que agregar texto");
    } else {
      setLoading(true);
      try {
        let tabsJson = [];
        let imagenesArray = []; //Array para los id del s3
        for (const certificado of tabsArray) {
          var resultImagen = "";
          if (typeof certificado.fileTab === "object") {
            const resultImagen1 = await S3Service.upload(certificado.fileTab);
            resultImagen = resultImagen1.result.data;
          } else {
            resultImagen = certificado.fileTab;
          }
          const JSON = {
            id: certificado.id,
            titulo: certificado.titulo,
            descripcion: certificado.descripcion,
            url: certificado.url,
            fileTab: resultImagen,
          };
          tabsJson.push(JSON);
        }

        for (const file of imagenesporque) {
          if (!file.urlImaen) {
            const resultFile = await S3Service.upload(file);
            imagenesArray.push(resultFile.result.data);
          } else {
            const idImagen = file.urlImaen.split("/")[3].split("?")[0];
            imagenesArray.push(idImagen);
          }
        }

        const dataNosotros = nosotros;
        dataNosotros.tituloPorque = tituloPorque;
        dataNosotros.tabs = tabsJson;
        dataNosotros.imagenesPorque = imagenesArray;

        if (!dataNosotros.id) {
          dataNosotros.id = 1;
        }

        await NosotrosService.update(dataNosotros);
        toast.success("Nosotros actualizado con éxito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getNosotros();
      }
    }
  };

  const saveTab = async () => {
    setTabsContador((tabsContador) => tabsContador + 1);
    console.log("id  del dia: " + tabsContador + tab.titulo);
    if (!tab.titulo) {
      toast.info("Error, tienes que colocar un titulo");
    } else if (!tab.descripcion) {
      toast.info("Error, tienes que agregar texto");
    } else if (!fileTab) {
      toast.info("Error, tienes que agregar una imagen");
    } else {
      try {
        const newDay = {
          id: tabsContador + tab.titulo,
          titulo: tab.titulo,
          descripcion: tab.descripcion,
          fileTab: fileTab,
          url: URL.createObjectURL(fileTab),
        };
        setTabsArray((tabsArray) => [...tabsArray, newDay]);
        console.log("tabs----" + tabsArray.length);
        toast.info("Agregado puedes guardar cambios");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const eliminarTab = (id) => {
    console.log("Eliminar id:" + id);

    try {
      let arrayOriginal = tabsArray.slice();
      console.log("dias array eliminar, length: " + tabsArray);
      console.log("****ELIMINAR: " + arrayOriginal.length);
      let nuevoArray = arrayOriginal.filter((record) => record.id !== id);
      console.log(nuevoArray);
      setTabsArray(nuevoArray);
      //arrayOriginalDiasJson=nuevoArray;
      //arrayDiasJSon = nuevoArray;
    } catch (error) {
      console.log(error);
    }
  };

  const limpiarTab = () => {
    try {
      tab.titulo = "";
      tab.descripcion = "";
      setFileTab(undefined);
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>NOSOTROS</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Editar el texto nosotros, misión , visión y historia.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <h4>Nosotros</h4>
                  </div>
                </div>
                <form className="form-validate">
                  <div className={`${styles.row} ${styles.g4}`}>
                    <div className={`${styles.col}`}>
                      {/* Shipping address */}

                      <div className={`${styles.section} ${styles.mb4}`}>
                        <div className={`${styles.cardBody}`}>
                          <div className={`${styles.row} ${styles.g3}`}>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <ThemeProvider theme={myTheme}>
                                  <MUIRichTextEditor
                                    label="Escribe el texto"
                                    controls={[
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "highlight",
                                      "undo",
                                      "redo",
                                      "link",
                                      "numberList",
                                      "bulletList",
                                      "quote",
                                      "code",
                                      "clear",
                                    ]}
                                    inlineToolbar={true}
                                    onChange={(value) => {
                                      console.log(value.getCurrentContent());
                                      setNosotros({
                                        ...nosotros,
                                        Cuerpo: stateToHTML(
                                          value.getCurrentContent()
                                        ),
                                      });
                                    }}
                                    defaultValue={contenido ? contenido : ""}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className={`${styles.co12}`}></div>
              </div>

              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <h4>Misión</h4>
                  </div>
                </div>
                <form className="form-validate">
                  <div className={`${styles.row} ${styles.g4}`}>
                    <div className={`${styles.col}`}>
                      {/* Shipping address */}

                      <div className={`${styles.section} ${styles.mb4}`}>
                        <div className={`${styles.cardBody}`}>
                          <div className={`${styles.row} ${styles.g3}`}>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <ThemeProvider theme={myTheme}>
                                  <MUIRichTextEditor
                                    label="Escribe la misión"
                                    controls={[
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "highlight",
                                      "undo",
                                      "redo",
                                      "link",
                                      "numberList",
                                      "bulletList",
                                      "quote",
                                      "code",
                                      "clear",
                                    ]}
                                    inlineToolbar={true}
                                    onChange={(value) => {
                                      console.log(value.getCurrentContent());
                                      setNosotros({
                                        ...nosotros,
                                        Mision: stateToHTML(
                                          value.getCurrentContent()
                                        ),
                                      });
                                    }}
                                    defaultValue={mision}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className={`${styles.co12}`}></div>
              </div>

              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <h4>Visión</h4>
                  </div>
                </div>
                <form className="form-validate">
                  <div className={`${styles.row} ${styles.g4}`}>
                    <div className={`${styles.col}`}>
                      {/* Shipping address */}

                      <div className={`${styles.section} ${styles.mb4}`}>
                        <div className={`${styles.cardBody}`}>
                          <div className={`${styles.row} ${styles.g3}`}>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <ThemeProvider theme={myTheme}>
                                  <MUIRichTextEditor
                                    label="Escribe la visión"
                                    controls={[
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "highlight",
                                      "undo",
                                      "redo",
                                      "link",
                                      "numberList",
                                      "bulletList",
                                      "quote",
                                      "code",
                                      "clear",
                                    ]}
                                    inlineToolbar={true}
                                    onChange={(value) => {
                                      console.log(value.getCurrentContent());
                                      setNosotros({
                                        ...nosotros,
                                        Vision: stateToHTML(
                                          value.getCurrentContent()
                                        ),
                                      });
                                    }}
                                    defaultValue={vision}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className={`${styles.co12}`}></div>
              </div>

              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <h4>Historia</h4>
                  </div>
                  <nav aria-label="breadcrumb">
                    <ol className={`${styles.breadcrumb} ${styles.small}`}>
                      <li
                        className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                        aria-current="page"
                      >
                        Historia se muestra en las secciones nuestra historia y
                        porque elegirnos.
                      </li>
                    </ol>
                  </nav>
                </div>
                <form className="form-validate">
                  <div className={`${styles.row} ${styles.g4}`}>
                    <div className={`${styles.col}`}>
                      {/* Shipping address */}

                      <div className={`${styles.section} ${styles.mb4}`}>
                        <div className={`${styles.cardBody}`}>
                          <div className={`${styles.row} ${styles.g3}`}>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <ThemeProvider theme={myTheme}>
                                  <MUIRichTextEditor
                                    label="Escribe la historia"
                                    controls={[
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "highlight",
                                      "undo",
                                      "redo",
                                      "link",
                                      "numberList",
                                      "bulletList",
                                      "quote",
                                      "code",
                                      "clear",
                                    ]}
                                    inlineToolbar={true}
                                    onChange={(value) => {
                                      console.log(value.getCurrentContent());
                                      setNosotros({
                                        ...nosotros,
                                        Historia: stateToHTML(
                                          value.getCurrentContent()
                                        ),
                                      });
                                    }}
                                    defaultValue={historia}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className={`${styles.co12}`}></div>
              </div>

              <header style={{ zIndex: "inherit" }}>
                <h1 className={`${styles.h4}`}>PORQUE ELEGIRNOS</h1>
                <nav aria-label="breadcrumb">
                  <ol className={`${styles.breadcrumb} ${styles.small}`}>
                    <li
                      className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                      aria-current="page"
                    >
                      Editar titulo y carousel de porque elegirnos.
                    </li>
                  </ol>
                </nav>
              </header>

              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <div className={`${styles.formFloating}`}>
                      <input
                        type="text"
                        className={`${styles.formControl} ${styles.shadowNone}`}
                        placeholder="Título porque elegirnos"
                        value={tituloPorque}
                        onChange={(e) =>
                          setTituloPorque(e.target.value.toUpperCase())
                        }
                      />
                      <label>Título porque elegirnos</label>
                    </div>
                  </div>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <div className={`${styles.formFloating}`}>
                      <p style={{ marginTop: "1rem" }}>
                        Imágenes carousel porque elegirnos
                      </p>
                      <DropComponent
                        subirImagen={(data) => setImagenesPorque(data)}
                        imagenesDefault={imagenesporque}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <header style={{ zIndex: "inherit" }}>
                <h1 className={`${styles.h4}`}>ALIADOS Y CERTIFICACIONES</h1>
                <nav aria-label="breadcrumb">
                  <ol className={`${styles.breadcrumb} ${styles.small}`}>
                    <li
                      className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                      aria-current="page"
                    >
                      Editar aliados y certificaciones
                    </li>
                  </ol>
                </nav>
              </header>

              <form className="form-validate">
                <div className={`${styles.row} ${styles.g4}`}>
                  <div className={`${styles.col}`}>
                    {/* Shipping address */}
                    <div
                      className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textCenter}`}
                      style={{ backgroundColor: "#585858", color: "white" }}
                    >
                      Elementos de aliados y certificaciones
                    </div>
                    <div className={`${styles.section} ${styles.mb4}`}>
                      <div className={`${styles.cardBody}`}>
                        <div className={`${styles.row} ${styles.g3}`}>
                          {/*Titulo */}
                          <div className={matches && `${styles.col3}`}>
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Título del elemento"
                                value={tab?.titulo}
                                onChange={(e) =>
                                  setTab({
                                    ...tab,
                                    titulo: e.target.value,
                                  })
                                }
                              />
                              <label>Título del elemento</label>
                            </div>
                          </div>
                          {/*Descripcion */}
                          <div className={matches && `${styles.col9}`}>
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Descripción del elemento"
                                value={tab?.descripcion}
                                onChange={(e) =>
                                  setTab({
                                    ...tab,
                                    descripcion: e.target.value,
                                  })
                                }
                              />
                              <label>Descripción del elemento</label>
                            </div>
                          </div>
                          {/* Img */}
                          <>
                            <div className={matches && `${styles.col9}`}>
                              {fileTab ? (
                                <div>
                                  <div className={`${styles.colLg12}`}>
                                    <div style={{ textAlign: "center" }}>
                                      <img
                                        src={URL.createObjectURL(fileTab)}
                                        style={{
                                          height: 300,
                                          objectFit: "contain",
                                          width: "-webkit-fill-available",
                                        }}
                                      />
                                      <Typography
                                        variant="body1"
                                        component="span"
                                        style={{
                                          width: "-webkit-fill-available",
                                        }}
                                      >
                                        {fileTab.name}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div style={{ width: "95%", marginLeft: "1.5%" }}>
                                <DropZone
                                  saveFile={async (fileAliados) => {
                                    setFileTab(fileAliados);
                                    var reader = new FileReader();
                                    var url = reader.readAsDataURL(fileTab);
                                  }}
                                />
                              </div>
                            </div>
                          </>
                          {/* Btn */}
                          <div
                            className={`${styles.colSm3}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className={`${styles.formFloating}`}>
                              <Button
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                  limpiarTab();
                                }}
                                className={`${styles.btn} ${styles.btnPrimary}`}
                                style={{
                                  margin: "5px",
                                  backgroundColor: "#5F0F46",
                                  color: "whitesmoke",
                                  width: "10rem",
                                }}
                              >
                                Limpiar
                              </Button>
                              <br />
                              <Button
                                className={`${styles.btn} ${styles.btnPrimary}`}
                                variant="outlined"
                                color="inherit"
                                onClick={() => saveTab()}
                                style={{
                                  margin: "5px",
                                  backgroundColor: "#5F0F46",
                                  color: "whitesmoke",
                                  width: "10rem",
                                }}
                              >
                                Guardar
                              </Button>
                            </div>
                          </div>
                          {/* Cards */}
                          <div className={`${styles.colSm12}`}>
                            <div
                              style={{
                                overflow: "auto",
                                display: "-webkit-box",
                                boxShadow: "none",
                                paddingBottom: "0%",
                              }}
                            >
                              {tabsArray?.map((record, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      id="row__posterLarge"
                                      style={{
                                        padding: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Card sx={{ maxWidth: 345 }}>
                                        {typeof record.fileTab !== "object" ? (
                                          <CardMedia
                                            component="img"
                                            height="100px"
                                            image={record.imagen}
                                            style={{
                                              height: "150px",
                                              width: "200px",
                                            }}
                                          />
                                        ) : (
                                          <CardMedia
                                            component="img"
                                            height="100px"
                                            image={record.url}
                                            style={{
                                              height: "150px",
                                              width: "200px",
                                            }}
                                          />
                                        )}
                                        <CardContent>
                                          <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                          >
                                            {record.titulo}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            color="text.secondary"
                                          >
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: record.descripcion,
                                              }}
                                            />
                                          </Typography>
                                        </CardContent>
                                        <CardActions>
                                          <Button
                                            size="small"
                                            onClick={() => {
                                              eliminarTab(record.id);
                                              console.log(
                                                "####CARD: " + record.id
                                              );
                                            }}
                                          >
                                            Eliminar
                                          </Button>
                                        </CardActions>
                                      </Card>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>

                          <div className={`${styles.colSm12}`}>
                            <div className={`${styles.formFloating}`}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => updateNosotros()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className={`${styles.small}`}>Guardar</span>
                </button>
              </div>
            </>
          </main>
        </>
      )}
    </>
  );
};

export default TablaContactanos;
