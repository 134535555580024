import React, { useEffect, useState } from "react";
import FooterService from "../../../services/Footer.service";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Siguenos = () => {
  const [redes, setRedes] = useState([]);

  useEffect(() => {
    getRedes();
  }, []);

  const getRedes = async () => {
    try {
      const data = await FooterService.getRedes();
      setRedes(data[0]);
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      {/*PESTAÑAS*/}
      <div className="widget p-4 wow fadeIn" data-wow-delay="1000ms">
        <h3 className="mb-1-6 h5">Síguenos</h3>
        <div>
          <a
            href={redes?.fb ? redes?.fb : "https://facebook.com"}
            className="me-2"
            target="_blank"
          >
            <FaFacebookF />
          </a>
          <a
            href={redes?.insta ? redes?.insta : "https://instagram.com"}
            className="me-2"
            target="_blank"
          >
            <FaInstagram />
          </a>
          <a
            href={redes?.tw ? redes?.tw : "https://x.com"}
            className="me-2"
            target="_blank"
          >
            <FaXTwitter />
          </a>
          <a
            href={redes?.in ? redes?.in : "https://linkedin.com"}
            className="me-2"
            target="_blank"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>
    </>
  );
};

export default Siguenos;
