import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AvisoPrivacidadService from "../../../services/Aviso.service";
import Sidebar from "./Sidebar";
import Title from "./Title";
import banner from "./nosotros.jpg";

const Aviso = () => {
  const navigate = useNavigate();
  const [aviso, setAviso] = useState([]);

  useEffect(() => {
    getAviso();
    window.scrollTo(0, 0);
  }, []);

  const getAviso = async () => {
    try {
      const data = await AvisoPrivacidadService.get();
      setAviso(data[0]);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <>
      <section
        className="page-title-section bg-img cover-background left-overlay-dark"
        data-overlay-dark={6}
        data-background="img/banner/page-title.jpg"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="container position-unset">
          <div className="page-title mx-1-6 mx-lg-2-0 mx-xl-2-6 mx-xxl-2-9">
            <div className="row">
              <div className="col-md-12">
                <h1>Aviso de privacidad</h1>
              </div>
              <div className="col-md-12">
                <ul className="ps-0">
                  <li>
                    <a href="javascript:void(0)" onClick={() => navigate("/")}>
                      Inicio
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Aviso de privacidad</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="main-wrapper blog-grid-three-col"
        style={{ marginBottom: "-6.5%" }}
      >
        <section className="py-9 py-md-10">
          <div className="containerPublic">
            <div className="row">
              <div className="container pb-7">
                <div className="row g-4">
                  <div className="col-12 col-lg-8 order-1 order-lg-2">
                    <article className="article-format">
                      <h2 className="h4 fw-bold">{aviso?.titulo}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: aviso?.cuerpo,
                        }}
                        style={{ textAlign: "justify" }}
                      ></p>
                    </article>
                  </div>
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Aviso;
