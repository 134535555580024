import httpClient from "./HttpClient";
const prefix = "/footer";

export default class FooterService {
  static async update(contactanos) {
    return (await httpClient.put(`${prefix}/${contactanos.id}`, contactanos)).data;
  }
 
  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async getBasic() {
    return (await httpClient.get(`${prefix}-basic/`)).data;
  }

  static async getRedes() {
    return (await httpClient.get(`${prefix}-redes/`)).data;
  }
}
