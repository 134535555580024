/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import DropComponent from "../imagesDrop";

/* Componentes y dependencias */
import S3Service from "../../../services/S3.service";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";
import { Card, Typography } from "@mui/material";
import DropZone from "../../DropZone";
import CardTestimonios from "./CardTestimonios";
import styles from "../layout/assets/css/core.min.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";

/* Servicios */
import TestimoniosService from "../../../services/Testimonios.service";

const TablaTestimonios = () => {
  const [testimonio, setTestimonio] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [testimonios, setTestimonios] = useState({});
  const [testimoniosArray, setTestimonioArray] = useState([]);
  const [testimonioContador, setTestimoniosContador] = useState(0);
  const [imagenesProducto, setImagenesProducto] = useState([]);
  const [fileCert, setFileCert] = useState(undefined);
  const matches = useMediaQuery("(min-width:600px)");

  let pasoTestimonios = 0;

  let arrayOriginalTestimonios = [];

  useEffect(() => {
    getTestimonios();
  }, []);

  const getTestimonios = async () => {
    setLoading(true);
    try {
      const cont = await TestimoniosService.getAll();
      setTestimonio(cont[0]);

      //Obtener cards testimonios
      if (cont[0].testimonios && pasoTestimonios == 0) {
        let contadorTestimonios = 0;
        pasoTestimonios = 1;
        for (const record of cont[0].testimonios) {
          const imagen = await S3Service.get(record.imagen);
          // const newTestimonio = (
          //   <CardTestimonios
          //     id={contadorTestimonios}
          //     texto={record.texto}
          //     nombre={record.nombre}
          //     ocupacion={record.ocupacion}
          //     eliminarActual={(data) => {
          //       eliminarCardTestimonios(data);
          //     }}
          //   />
          // );
          const newTestimonio = {
            id: record.id,
            texto: record.texto,
            nombre: record.nombre,
            ocupacion: record.ocupacion,
            url: imagen.result,
            imgID: record.imagen,
          };
          arrayOriginalTestimonios.push(newTestimonio);
          contadorTestimonios += 1;
        }
        setTestimoniosContador(contadorTestimonios);
        setTestimonioArray(arrayOriginalTestimonios);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateTestimonio = async () => {
    if (!testimonio.titulo) {
      toast.info("Error, tienes que agregar un titulo");
    } else {
      //setLoading(true);
      try {
        let testimoniosJson = []; //Array para almecenar los dias en formatos JSON y guardarlos en la BD
        let imagenesArray = []; //Array para los id del s3
        /* Subida de los certificados */
        for (const testimonio of testimoniosArray) {
          var resultImagen = "";
          if (testimonio.imagen == undefined) {
            resultImagen = testimonio.imgID;
          } else {
            const resultImagen1 = await S3Service.upload(testimonio.imagen);
            resultImagen = resultImagen1.result.data;
          }
          const JSON = {
            texto: testimonio.texto,
            nombre: testimonio.nombre,
            ocupacion: testimonio.ocupacion,
            imagen: resultImagen,
            id: testimonio.id,
          };
          testimoniosJson.push(JSON);
        }
        console.log("cert5");
        const {
          titulo,
          proyectos,
          clientes,
          asesores,
          id,
          proyectosTitle,
          clientesTitle,
          asesoresTitle,
        } = testimonio;
        console.log("cert6");
        const dataTestimonio = {
          id: id,
          titulo,
          proyectos,
          clientes,
          asesores,
          proyectosTitle,
          clientesTitle,
          asesoresTitle,
          testimonios: testimoniosJson,
        };
        if (!testimonio.id) {
          dataTestimonio.id = 1;
        }
        console.log("cert7");
        const updateTestimonio = await TestimoniosService.update(
          dataTestimonio
        );
        toast.success("Testimonios actualizado con éxito!");
      } catch (error) {
        toast.error(error);
      } finally {
        //setLoading(false);
        getTestimonios();
      }
    }
  };

  const saveCardTestimonio = async () => {
    setTestimoniosContador((prev) => prev + 1);
    try {
      // const newTestimonio = (
      //   <CardTestimonios
      //     id={testimonioContador}
      //     texto={testimonios.texto}
      //     nombre={testimonios.nombre}
      //     ocupacion={testimonios.ocupacion}
      //     eliminarActual={(data) => {
      //       eliminarCardTestimonios(data);
      //     }}
      //   />
      // );
      const newTestimonio = {
        id: `${testimonioContador}${testimonios.nombre}`,
        texto: testimonios.texto,
        nombre: testimonios.nombre,
        ocupacion: testimonios.ocupacion,
        imagen: fileCert,
        url: URL.createObjectURL(fileCert),
      };
      setTestimonioArray((testimoniosArray) => [
        ...testimoniosArray,
        newTestimonio,
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarCardTestimonios = (id) => {
    try {
      let arrayOriginal = testimoniosArray;
      console.log(arrayOriginal);
      let nuevoArray = [];
      arrayOriginal.map((record) => {
        if (record.id !== id) {
          nuevoArray.push(record);
        }
      });
      setTestimonioArray(nuevoArray);
    } catch (error) {
      console.log(error);
    }
  };

  const limpiarCardTestimonios = () => {
    try {
      testimonios.texto = "";
      testimonios.nombre = "";
      testimonios.ocupacion = "";
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>TESTIMONIOS</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Editar los testimonios
                  </li>
                </ol>
              </nav>
            </header>
            {/* CUERPO */}
            <div className={`${styles.section} ${styles.p0}`}>
              <div className={`${styles.cardHeader} ${styles.p4}`}>
                <div className={`${styles.row} ${styles.g3}`}>
                  <div className={`${styles.formFloating}`}>
                    <input
                      type="text"
                      className={`${styles.formControl} ${styles.shadowNone}`}
                      placeholder="Título"
                      onChange={(e) =>
                        setTestimonio({
                          ...testimonio,
                          titulo: e.target.value,
                        })
                      }
                      value={testimonio?.titulo}
                    />
                    <label>Titulo</label>
                  </div>
                </div>
              </div>
              {/* texto */}
              <form className="form-validate">
                <div className={`${styles.row} ${styles.g4}`}>
                  <div className={`${styles.col}`}>
                    <div
                      className={`${styles.section} ${styles.mb4}`}
                      style={{ boxShadow: "none" }}
                    >
                      <div className={`${styles.cardBody}`}>
                        <div className={`${styles.row} ${styles.g3}`}>
                          <div className={`${styles.colSm3}`}>
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Titulo"
                                onChange={(e) =>
                                  setTestimonio({
                                    ...testimonio,
                                    proyectosTitle: e.target.value,
                                  })
                                }
                                value={testimonio?.proyectosTitle}
                              />
                              <label>Titulo uno</label>
                            </div>
                            <br />
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Cantidad"
                                onChange={(e) =>
                                  setTestimonio({
                                    ...testimonio,
                                    proyectos: e.target.value,
                                  })
                                }
                                value={testimonio?.proyectos}
                              />
                              <label>Cantidad uno</label>
                            </div>
                          </div>
                          <div className={`${styles.colSm3}`}>
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Titulo"
                                onChange={(e) =>
                                  setTestimonio({
                                    ...testimonio,
                                    clientesTitle: e.target.value,
                                  })
                                }
                                value={testimonio?.clientesTitle}
                              />
                              <label>Titulo dos</label>
                            </div>
                            <br />
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Cantidad"
                                onChange={(e) =>
                                  setTestimonio({
                                    ...testimonio,
                                    clientes: e.target.value,
                                  })
                                }
                                value={testimonio?.clientes}
                              />
                              <label>Cantidad dos</label>
                            </div>
                          </div>
                          <div className={`${styles.colSm3}`}>
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Titulo"
                                onChange={(e) =>
                                  setTestimonio({
                                    ...testimonio,
                                    asesoresTitle: e.target.value,
                                  })
                                }
                                value={testimonio?.asesoresTitle}
                              />
                              <label>Titulo tres</label>
                            </div>
                            <br />
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Cantidad"
                                onChange={(e) =>
                                  setTestimonio({
                                    ...testimonio,
                                    asesores: e.target.value,
                                  })
                                }
                                value={testimonio?.asesores}
                              />
                              <label>Cantidad tres</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {/* testimonios */}
              <form className="form-validate">
                <div className={`${styles.row} ${styles.g4}`}>
                  <div className={`${styles.col}`}>
                    {/* Shipping address */}
                    <div
                      className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textCenter}`}
                      style={{ backgroundColor: "#585858", color: "white" }}
                    >
                      {" "}
                      Testimonios {testimonios.length}
                    </div>
                    <div className={`${styles.section} ${styles.mb4}`}>
                      <div className={`${styles.cardBody}`}>
                        <div className={`${styles.row} ${styles.g3}`}>
                          {/*Nombre */}
                          <div className={`${styles.colSm6}`}>
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Nombre"
                                value={testimonios?.nombre}
                                onChange={(e) =>
                                  setTestimonios({
                                    ...testimonios,
                                    nombre: e.target.value,
                                  })
                                }
                              />
                              <label>Nombre</label>
                            </div>
                          </div>
                          {/*Ocupacion */}
                          <div className={`${styles.colSm6}`}>
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Ocupacion"
                                value={testimonios?.ocupacion}
                                onChange={(e) =>
                                  setTestimonios({
                                    ...testimonios,
                                    ocupacion: e.target.value,
                                  })
                                }
                              />
                              <label>Ocupacion</label>
                            </div>
                          </div>
                          {/*Texto */}
                          <div className={`${styles.col9}`}>
                            <div className={`${styles.formFloating}`}>
                              <textarea
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                id="descripcionDia"
                                placeholder="Testimonio"
                                style={{
                                  padding: "1.5%",
                                  width: "100%",
                                  minHeight: "10rem",
                                  margin: "0px",
                                }}
                                value={testimonios?.texto}
                                onChange={(e) =>
                                  setTestimonios({
                                    ...testimonios,
                                    texto: e.target.value,
                                  })
                                }
                              />
                              <label>Testimonio</label>
                            </div>
                          </div>
                          {/* Img */}
                          <>
                            <div className={matches && `${styles.col9}`}>
                              {fileCert ? (
                                <div>
                                  <div className={`${styles.colLg12}`}>
                                    <div style={{ textAlign: "center" }}>
                                      <img
                                        src={URL.createObjectURL(fileCert)}
                                        style={{
                                          height: 300,
                                          objectFit: "contain",
                                          width: "-webkit-fill-available",
                                        }}
                                      />
                                      <Typography
                                        variant="body1"
                                        component="span"
                                        style={{
                                          width: "-webkit-fill-available",
                                        }}
                                      >
                                        {fileCert.name}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div style={{ width: "95%", marginLeft: "1.5%" }}>
                                <DropZone
                                  saveFile={async (fileCert) => {
                                    setFileCert(fileCert);
                                    var reader = new FileReader();
                                    var url = reader.readAsDataURL(fileCert);
                                  }}
                                />
                              </div>
                            </div>
                          </>
                          {/* Btn */}
                          <div
                            className={`${styles.colSm3}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className={`${styles.formFloating}`}>
                              <Button
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                  limpiarCardTestimonios();
                                }}
                                className={`${styles.btn} ${styles.btnPrimary}`}
                                style={{
                                  margin: "5px",
                                  backgroundColor: "#5F0F46",
                                  color: "whitesmoke",
                                  width: "10rem",
                                }}
                              >
                                Limpiar
                              </Button>
                              <br />
                              <Button
                                className={`${styles.btn} ${styles.btnPrimary}`}
                                variant="outlined"
                                color="inherit"
                                onClick={() => saveCardTestimonio()}
                                style={{
                                  margin: "5px",
                                  backgroundColor: "#5F0F46",
                                  color: "whitesmoke",
                                  width: "10rem",
                                }}
                              >
                                Guardar
                              </Button>
                            </div>
                          </div>
                          {/* Cards */}
                          <div className={`${styles.colSm12}`}>
                            <div
                              style={{
                                overflow: "auto",
                                display: "-webkit-box",
                                boxShadow: "none",
                                paddingBottom: "0%",
                              }}
                            >
                              {testimoniosArray.map((record, index) => {
                                return (
                                  <>
                                    <div
                                      id="row__posterLarge"
                                      style={{
                                        padding: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <CardTestimonios
                                        id={record.id}
                                        texto={record.texto}
                                        nombre={record.nombre}
                                        url={record.url}
                                        ocupacion={record.ocupacion}
                                        imgID={record.imagen}
                                        eliminarActual={() => {
                                          eliminarCardTestimonios(record.id);
                                        }}
                                      />
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>

                          <div className={`${styles.colSm12}`}>
                            <div className={`${styles.formFloating}`}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* FOOTER */}
            <div
              className={`${styles.cardFooter} ${styles.borderLight}`}
              style={{ textAlign: "right" }}
            >
              <button
                className={`${styles.btn} ${styles.btnPrimary}`}
                onClick={() => updateTestimonio()}
              >
                <svg
                  width="18px"
                  height="18px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
                {"  "}
                <span className={`${styles.small}`}>Guardar</span>
              </button>
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default TablaTestimonios;
