/* Dependencies */
import { useContext, useEffect, useState } from "react";
import { AuthContext, IAuthContext } from "./context/AuthContext";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
//import "./App.css";
import FullPageLoading from "./utils/FullPageLoading";
/* Public components*/
import PublicLayout from "./components/public/layout/PublicLayout";
import Index from "./components/public/portada/Index";
import BlogGrid from "./components/public/blog/BlogGrid";
import BlogEntrada from "./components/public/blog/BlogEntrada";
import ServicioIndividual from "./components/public/servicios/ServicioIndividual";

/* Private components*/
import PrivateLayout from "./components/private/layout";
//Carrusel
import CarruselTable from "./components/private/carrusel/CarruselTable";
import CrearCarrusel from "./components/private/carrusel/CrearCarrusel";
import EditarCarrusel from "./components/private/carrusel/EditarCarrusel";
import EliminarCarrusel from "./components/private/carrusel/EliminarCarrusel";
//Blog
import TablaBlog from "./components/private/blog/TablaBlog";
import CrearBlog from "./components/private/blog/CrearBlog";
import EditarBlog from "./components/private/blog/EditarBlog";
import EliminarBlog from "./components/private/blog/EliminarBlog";
//Categoria Blog
import TablaCategoriaBlog from "./components/private/categoria/TablaCategoriaBlog";
//Servicios
import TablaServicios from "./components/private/servicios/TablaServicios";
import CrearServicio from "./components/private/servicios/CrearServicio";
import EliminarServicio from "./components/private/servicios/EliminarServicio";
import EditarServicio from "./components/private/servicios/EditarServicio";
//Cert
// import TablaCert from "./components/private/Cert/TablaCert";
//Testimonios
import TablaTestimonios from "./components/private/Testimonios/TablaTestimonios";
//Usuarios
import TablaUsuarios from "./components/private/usuarios/TablaUsuario";
import NuevoUsuario from "./components/private/usuarios/NuevoUsuario";
import EditarUsuario from "./components/private/usuarios/EditarUsuario";
import EliminarUsuario from "./components/private/usuarios/EliminarUsuario";
//Equipo
// import TablaEquipo from "./components/private/equipo/TablaEquipo";
// import CrearEquipo from "./components/private/equipo/NuevoEquipo";
// import EditarEquipo from "./components/private/equipo/EditarEquipo";
// import EliminarEquipo from "./components/private/equipo/EliminarEquipo";
//aviso
import TablaAviso from "./components/private/aviso/TablaAviso";
//footer
import TablaFooter from "./components/private/footer/TablaFooter";
//terminos
import TablaTerminos from "./components/private/terminos/TablaTerminos";
//index
import TablaIndex from "./components/private/index/TablaIndex";
//dashboard
import Dashboard from "./components/private/dashboard/dashboard";
//Contactanos
import TablaContactanos from "./components/private/contactanos/TablaContactanos";

//Nosotros
import TablaNosotros from "./components/private/nosotros/TablaContactanos";

import Contactanos from "./components/public/contactanos/Contactanos";
import ServiciosGrid from "./components/public/servicios/ServiciosGrid";
import IniciarSesion from "./components/public/login/IniciarSesion";
import Nosotros from "./components/public/nosotros/Nosotros";
//Mensajes
import TablaMensaje from "./components/private/mensaje/TablaMensaje";

import Aviso from "./components/public/informacion/Aviso";
import Terminos from "./components/public/informacion/Terminos";

function App() {
  const { currentUser, checkUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
      checkUser();
      setLoading(false);
    }, 500);
  });

  const publicRoutes = (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<Index />} />
        <Route path="contactanos" element={<Contactanos />} />
        <Route path="Servicios" element={<ServiciosGrid />} />
        <Route path="Servicios/:id" element={<ServicioIndividual />} />
        <Route path="nosotros" element={<Nosotros />} />
        <Route path="blog" element={<BlogGrid />} />
        <Route path="blog/:id" element={<BlogEntrada />} />
        <Route path="iniciarsesion" element={<IniciarSesion />} />
        <Route path="aviso" element={<Aviso />} />
        <Route path="terminos" element={<Terminos />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>
    </Routes>
  );

  const adminRoutes = (
    <Routes>
      <Route path="/" element={<PrivateLayout />}>
        <Route index element={<Navigate replace to="/carousel" />} />
        <Route path="carousel" element={<CarruselTable />} />
        <Route path="carousel/nuevo" element={<CrearCarrusel />} />
        <Route path="carousel/editar/:id" element={<EditarCarrusel />} />
        <Route path="carousel/eliminar/:id" element={<EliminarCarrusel />} />

        <Route path="blog" element={<TablaBlog />} />
        <Route path="blog/nuevo" element={<CrearBlog />} />
        <Route path="blog/editar/:id" element={<EditarBlog />} />
        <Route path="blog/eliminar/:id" element={<EliminarBlog />} />
        <Route path="categoriaBlog" element={<TablaCategoriaBlog />} />

        <Route path="servicio" element={<TablaServicios />} />
        <Route path="servicio/nuevo" element={<CrearServicio />} />
        <Route path="servicio/eliminar/:id" element={<EliminarServicio />} />
        <Route path="servicio/editar/:id" element={<EditarServicio />} />

        {/* <Route path="cert" element={<TablaCert />} /> */}
        <Route path="testimonios" element={<TablaTestimonios />} />
        <Route path="usuario" element={<TablaUsuarios />} />
        <Route path="usuario/nuevo" element={<NuevoUsuario />} />
        <Route path="usuario/editar/:id" element={<EditarUsuario />} />
        <Route path="usuario/eliminar/:id" element={<EliminarUsuario />} />

        {/* <Route path="equipo" element={<TablaEquipo />} />
        <Route path="equipo/nuevo" element={<CrearEquipo />} />
        <Route path="equipo/editar/:id" element={<EditarEquipo />} />
        <Route path="equipo/eliminar/:id" element={<EliminarEquipo />} /> */}

        <Route path="aviso" element={<TablaAviso />} />
        <Route path="footer" element={<TablaFooter />} />
        <Route path="terminos" element={<TablaTerminos />} />
        <Route path="index" element={<TablaIndex />} />
        <Route path="contactanos" element={<TablaContactanos />} />
        <Route path="nosotros" element={<TablaNosotros />} />

        <Route path="mensajes" element={<TablaMensaje />} />

        <Route path="*" element={<FullPageLoading />} />
      </Route>
      {/**/}
    </Routes>
  );

  const getRoute = () => {
    if (loading) {
      return (
        <Routes>
          <Route path="/" element={<FullPageLoading />} />
        </Routes>
      );
    }

    if (!currentUser) {
      return publicRoutes;
    } else {
      if (currentUser.tipo == "Administrador") {
        return adminRoutes;
      } else {
        return publicRoutes;
      }
    }
  };

  return (
    <>
      <BrowserRouter>{getRoute()}</BrowserRouter>
    </>
  );
}

export default App;
