import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";
import MensajeService from "../../../services/Mensaje.service";

const BlogMensaje = ({
  id,
  nombre,
  createdAt,
  imagenes,
  MensajeCuerpo,
  borrar,
  borrarComentario,
}) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  useEffect(() => {
    getImagen();
  }, []);

  const getImagen = async () => {
    console.log(imagenes);
    try {
      if (imagenes != undefined && imagenes != null) {
        console.log();
        const result = await S3Service.get(imagenes);
        console.log(result);
        setImag(result.result);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div className="media py-1">
        <div className="me-4">
          <img
            className=" rounded-circle"
            src={imagenes == undefined ? require("./assets/user.png") : imag}
            alt="User Image"
            style={{ width: "100px", height: "100px" }}
          />
        </div>
        <div className="media-body">
          <span className="mb-3 font-weight-medium text-muted d-inline-block">
            {nombre}
          </span>
          <time className="d-block text-muted font-size-13 mb-3">
            {String(createdAt).slice(0, 10)}
          </time>
          <p className="font-size-13 line-hight-21">{MensajeCuerpo}</p>
          {borrar ? (
            <>
              <a
                href="javascript:void(0);"
                className="btn btn-danger text-uppercase"
                type="submit"
                onClick={() => {
                  borrarComentario(id);
                }}
              >
                <i className="fa fa-reply-all me-3" aria-hidden="true" />
                Borrar
              </a>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <hr />
    </>
  );
};

export default BlogMensaje;
