import React, { useEffect, useState } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import WOW from "wowjs";

import { IoShieldHalf, IoTrophy, IoFlagSharp } from "react-icons/io5";

//servicios
import TestimoniosService from "../../../services/Testimonios.service";

//componentes
import Portada_ItemTestimonios from "./Portada_ItemTestimonios";
import img8 from "./img8.jpg";
import FullPageLoading from "../../../utils/FullPageLoading";
import useMediaQuery from "@mui/material/useMediaQuery";

const Testimonios = ({ ultimasNoticias }) => {
  const [testimonio, setTestimonio] = useState({ undefined });
  const [testimonios, setTestimonios] = useState([]);
  const [testimonioArray, setTestimonioArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const matchesmd = useMediaQuery("(min-width:1200px)");

  useEffect(() => {
    getTestimonios();
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const getTestimonios = async () => {
    setLoading(true);
    try {
      const cont = await TestimoniosService.getAll();
      setTestimonio(cont[0]);
      if (cont[0].testimonios) {
        for (const test of cont[0].testimonios) {
          const certif = {
            texto: test.texto,
            nombre: test.nombre,
            ocupacion: test.ocupacion,
            imagen: test.imagen,
          };
          setTestimonios((testimonio) => [...testimonio, certif]);
        }
        setTestimonioArray(cont[0].certs);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const indicatorStyles = {
    background: "#5F0F4654",
    width: 15,
    height: 15,
    borderRadius: "50%",
    display: "inline-block",
    margin: "0 8px",
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* TESTIMONIALS
  ================================================== */}
          <section
            class="half-bg position-relative z-index-9"
            style={{ marginBottom: "100px" }}
          >
            <div class="container">
              <div class="row align-items-stretch">
                <div
                  class="col-lg-6 col-xl-7 text-center text-lg-start mb-5 mb-lg-0 wow fadeIn"
                  data-wow-delay="200ms"
                  style={{ paddingBottom: "2rem" }}
                >
                  <div className="title-style2 wow fadeIn">
                    <span className="ms-4" style={{ color: "#5F0F46" }}>
                      Testimonios
                    </span>
                    <h2 className="mb-0" style={{ position: "relative" }}>
                      {testimonio?.titulo}
                    </h2>
                  </div>
                  <div class="row g-0 align-items-center h-100">
                    <div class="col-12">
                      <OwlCarousel
                        className="whychooseus3-carousel owl-carousel owl-theme position-relative h-100"
                        items={matchesmd ? 2 : 1}
                        autoplay="true"
                        autoplaySpeed="100"
                        loop="true"
                      >
                        {testimonios.map((record, index) => {
                          return (
                            <Portada_ItemTestimonios
                              key={index}
                              data={record}
                            />
                          );
                        })}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-6 col-xl-5 wow fadeIn"
                  data-wow-delay="400ms"
                >
                  <div class="position-relative">
                    <div class="h-100 w-100">
                      <div class="quote-tab p-md-2-2 bg-white h-100 w-100 clearfix rounded">
                        <div class="horizontaltab tab-style2">
                          <div
                            className="position-relative h-100 vw-lg-50 bg-img cover-background overflow-visible py-8 py-xl-10"
                            data-overlay-dark={0}
                            data-background="img/bg/bg-04.jpg"
                            style={{ backgroundImage: `url(${img8})` }}
                          >
                            <div
                              className="left-negative-margin mt-lg-6"
                              style={{ width: "25rem" }}
                            >
                              <div
                                className="media py-1-9 px-4 px-sm-2-6 mb-3 rounded wow fadeInUp"
                                data-wow-delay="200ms"
                                style={{
                                  visibility: "visible",
                                  animationDelay: "200ms",
                                  animationName: "fadeInUp",
                                  backgroundColor: "white",
                                }}
                              >
                                <div className="icon-box mb-0">
                                  <IoTrophy className="z-index-9 position-relative" />
                                  <div className="box-circle" />
                                </div>
                                <div className="media-body">
                                  <h3 className="mb-1 countup">
                                    {testimonio?.proyectos}{" "}
                                  </h3>
                                  <p className="mb-0">
                                    {testimonio?.proyectosTitle}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="media py-1-9 px-4 px-sm-2-6 mb-3 rounded wow fadeInUp"
                                data-wow-delay="400ms"
                                style={{
                                  visibility: "visible",
                                  animationDelay: "400ms",
                                  animationName: "fadeInUp",
                                  backgroundColor: "white",
                                }}
                              >
                                <div className="icon-box mb-0">
                                  <IoShieldHalf className="z-index-9 position-relative" />

                                  <div className="box-circle"></div>
                                </div>
                                <div className="media-body">
                                  <h3 className="mb-1 countup">
                                    {testimonio?.clientes}{" "}
                                  </h3>
                                  <p className="mb-0">
                                    {testimonio?.clientesTitle}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="media py-1-9 px-4 px-sm-2-6 rounded wow fadeInUp"
                                data-wow-delay="600ms"
                                style={{
                                  visibility: "visible",
                                  animationDelay: "600ms",
                                  animationName: "fadeInUp",
                                  backgroundColor: "white",
                                }}
                              >
                                <div className="icon-box mb-0">
                                  <IoFlagSharp className="z-index-9 position-relative" />
                                  <div className="box-circle" />
                                </div>
                                <div className="media-body">
                                  <h3 className="mb-1 countup">
                                    {testimonio?.asesores}{" "}
                                  </h3>
                                  <p className="mb-0">
                                    {testimonio?.asesoresTitle}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Testimonios;
