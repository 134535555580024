import React, { PropTypes, Component } from "react";

import {
  greatPlaceStyle,
  greatPlaceStyleHover,
} from "./my_great_place_with_controllable_hover_styles.js";

export default class MyGreatPlaceWithControllableHover extends Component {
  // static propTypes = {
  //   // use hover from controllable
  //   hover: PropTypes.bool,
  //   text: PropTypes.string,
  // };

  static defaultProps = {};

  constructor(props) {
    super(props);
  }

  render() {
    const style = this.props.hover ? greatPlaceStyleHover : greatPlaceStyle;

    return (
      <div className="hint hint--html hint--info hint--top" style={style}>
        <div>{this.props.text}</div>
        <div style={{ width: 80 }} className="hint__content">
          {/* Сlick me */}
        </div>
      </div>
    );
  }
}
