/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

/* Servicios */
import UserService from "../../../services/User.Service";

import styles from "../layout/assets/css/core.min.module.css";

const TablaServicios = () => {
  const [usuarios, setUsuarios] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getUsuarios();
  }, [page, limit]);

  const getUsuarios = async () => {
    setLoading(true);

    try {
      const data = await UserService.list(limit, page * limit);

      setUsuarios(data.data);
      console.log("HOlaaa*****" + usuarios);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>USUARIOS</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Hay {usuarios.length} actualmente.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <div
                      className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                    >
                      <form
                        className={`${styles.positionRelative} ${styles.dFlex} ${styles.alignItemsCenter}`}
                        method="get"
                        action="#"
                      ></form>
                    </div>
                    {/* options */}
                    <div
                      className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                    >
                      <a
                        href="javascript:void(0)"
                        onClick={() => navigate(`nuevo`)}
                        className={`${styles.w100} ${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                      >
                        <svg
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                        <span>Nuevo</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className={`${styles.cardBody} ${styles.pt1}`}>
                  {/* item list */}
                  <div
                    className={`${styles.tableResponsiveMd}`}
                    style={{ width: "100%", overflow: "auto" }}
                  >
                    <table style={{ marginLeft: "10px" }}>
                      <thead>
                        <tr>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 400 }}
                          >
                            NOMBRE
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 400 }}
                          >
                            <a
                              href="javascript:void(0)"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>CORREO</span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 200, marginLeft: "20px" }}
                          >
                            <a
                              href="javascript:void(0)"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                CREACIÓN
                              </span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 250 }}
                          >
                            <a
                              href="javascript:void(0)"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                EDICIÓN
                              </span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 20 }}
                          >
                            <a
                              href="javascript:void(0)"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by price"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}></span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 20 }}
                          >
                            <a
                              href="javascript:void(0)"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by price"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}></span>
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {usuarios.map((record, index) => {
                          if (record.correo == "karimnot@gmail.com") {
                            return (
                              <tr key={`${record.id}`}>
                                <td>{record.nombre}</td>
                                <td>{record.correo}</td>
                                <td> </td>
                                <td></td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr key={`${record.id}`}>
                                <td>{record.nombre}</td>
                                <td>{record.correo}</td>
                                <td>
                                  <i
                                    className="fa fa-calendar fa-1"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.createdAt).slice(0, 10)}
                                  <br />{" "}
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.createdAt).slice(11, 16)}
                                </td>
                                <td>
                                  <i
                                    className="fa fa-calendar fa-1"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.updateAt).slice(0, 10)}
                                  <br />{" "}
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                  />{" "}
                                  {String(record.updateAt).slice(11, 16)}
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                  >
                                    {" "}
                                    <span
                                      onClick={() =>
                                        navigate(`editar/${record.id}`)
                                      }
                                      className="material-icons-sharp"
                                    >
                                      {" "}
                                      edit{" "}
                                    </span>
                                  </a>
                                </td>

                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                  >
                                    {" "}
                                    <span
                                      className="material-icons-sharp"
                                      onClick={() =>
                                        navigate(`eliminar/${record.id}`)
                                      }
                                    >
                                      {" "}
                                      delete{" "}
                                    </span>
                                  </a>
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                  <TablePagination
                    style={{ color: "var(--color-dark-variant)" }}
                    component="div"
                    classes="recent-orders"
                    labelRowsPerPage="Items por pagina"
                    count={total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  {/* pagination, selected items */}
                  {/* <div className={`${styles.row}`}>
                    <div
                      className={`${styles.col} ${styles.py3} ${styles.textCenter} ${styles.textMdStart}`}
                    > */}
                  {/* selected items 
                      <div className="dropup">
                        <a
                          className="btn btn-sm btn-primary"
                          href="#"
                          role="button"
                          id="ddSelected"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="group-icon">
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1={18} y1={6} x2={6} y2={18} />
                              <line x1={6} y1={6} x2={18} y2={18} />
                            </svg>
                          </span>
                          <span>Selected items</span>
                        </a>
                        <ul
                          className="dropdown-menu dropdown-menu-clean my-2"
                          aria-labelledby="ddSelected"
                        >
                          <li>
                            <a className="dropdown-item" href="#form-list">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                              <span>Active</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                              <span>Innactive</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                              <span>Banned</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-danger"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="3 6 5 6 21 6" />
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                <line x1={10} y1={11} x2={10} y2={17} />
                                <line x1={14} y1={11} x2={14} y2={17} />
                              </svg>
                              <span className="w-100">Delete</span>
                            </a>
                          </li>
                        </ul>
                      </div>*/}
                  {/* </div>
                    <div className={`${styles.colMdAuto} ${styles.py3}`}> */}
                  {/* pagination */}
                  {/* <nav
                        className={`${styles.w100} ${styles.textCenter}`}
                        aria-label="Pagination"
                      > */}
                  {/* pagination : desktop */}
                  {/* <nav
                          className={`${styles.textCenter} ${styles.floatMdEnd} ${styles.mt3} ${styles.mtMd0} ${styles.dNone} ${styles.dMdFlex}`}
                          aria-label="Pagination"
                        >
                          <TablePagination
                            style={{ color: "var(--color-dark-variant)" }}
                            component="div"
                            classes="recent-orders"
                            labelRowsPerPage="Items por pagina"
                            count={total}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={limit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </nav>
                      </nav>
                    </div>
                  </div> */}
                </div>
              </div>
            </>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaServicios;
