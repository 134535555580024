/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { TablePagination } from "@mui/material";

import { toast } from "react-toastify";

/* Servicios */
import NoticiaService from "../../../services/Noticia.service";

import styles from "../layout/assets/css/core.min.module.css";

const TablaBlog = () => {
  const [noticias, setNoticias] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    getNoticias();
  }, [page, limit]);

  const getNoticias = async () => {
    setLoading(true);
    try {
      const data = await NoticiaService.list(limit, page * limit);
      setNoticias(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>BLOG</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Hay {noticias.length} actualmente.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div
                className={`${styles.section} ${styles.p0}`}
                // style={{ width: "100%", overflow: "auto" }}
              >
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <div
                      className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                    >
                      <form
                        className={`${styles.positionRelative} ${styles.dFlex} ${styles.alignItemsCenter}`}
                        method="get"
                        action="#"
                      ></form>
                    </div>
                    {/* options */}
                    <div
                      className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                    >
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate(`nuevo`)}
                        className={`${styles.w100} ${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                      >
                        <svg
                          width="18px"
                          height="18px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                        <span>Nuevo</span>
                      </a>
                    </div>
                  </div>
                  {/* active filters 
                  <ul className="list-inline mt-2 mb-0">
                    <li className="list-inline-item badge bg-primary me-1">
                      <a
                        href="#!"
                        className="text-white text-decoration-none d-flex align-items-center"
                      >
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                        <span className="text-white ms-1">Filters:</span>
                      </a>
                    </li>
                    <li className="list-inline-item badge bg-light me-1">
                      <a
                        href="#!"
                        className="link-muted d-flex align-items-center"
                      >
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                        <span className="text-muted ms-1">John Doe</span>
                      </a>
                    </li>
                    <li className="list-inline-item badge bg-light me-1">
                      <a
                        href="#!"
                        className="link-muted d-flex align-items-center"
                      >
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                        <span className="text-muted ms-1">Active</span>
                      </a>
                    </li>
                    <li className="list-inline-item badge bg-light me-1">
                      <a
                        href="#!"
                        className="link-muted d-flex align-items-center"
                      >
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                        <span className="text-muted ms-1">Returning</span>
                      </a>
                    </li>
                  </ul>*/}
                </div>
                {/*          <div className="card-body pt-1">*/}
                <div>
                  {/* item list */}
                  <div
                    className={`${styles.tableResponsiveMd}`}
                    style={{ width: "100%", overflow: "auto" }}
                  >
                    <table
                      className={`${styles.table}`}
                      style={{
                        overflowX: "auto",
                        width: "1000px",
                        marginLeft: "10px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: "600px" }}
                          >
                            TÍTULO
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 800 }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>ENTRADA</span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 30 }}
                          ></th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 200, marginLeft: "20px" }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                CREACIÓN
                              </span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 250 }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by inventory"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                EDICIÓN
                              </span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 20 }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by price"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}></span>
                            </a>
                          </th>
                          <th
                            className={`${styles.small} ${styles.textMuted}`}
                            style={{ width: 20 }}
                          >
                            <a
                              href="javascript:void(0);"
                              className={`${styles.dFlex} ${styles.linkMuted}`}
                              title="order by price"
                              aria-label="order by inventory"
                            >
                              <span className={`${styles.ms2}`}></span>
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {noticias.map((record, index) => {
                          const regex = /<[^>]'>/gim;
                          return (
                            <tr key={`${record.id}_${record.titulo}`}>
                              <td
                                className={`${styles.linkNormal} ${styles.fwMedium}`}
                              >
                                {record.titulo}
                              </td>
                              <td>
                                <p
                                  className={`${styles.lead}`}
                                  dangerouslySetInnerHTML={{
                                    __html: String(record.cuerpoNoticia)
                                      .slice(0, 100)
                                      .concat("..."),
                                  }}
                                  style={{
                                    textAlign: "justify",
                                    fontWeight: "light",
                                    fontSize: "1rem",
                                  }}
                                ></p>
                              </td>
                              <td></td>
                              <td>
                                <i
                                  className="fa fa-calendar fa-1"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.createdAt).slice(0, 10)}
                                <br />{" "}
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.createdAt).slice(11, 16)}
                              </td>
                              <td>
                                <i
                                  className="fa fa-calendar fa-1"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.updateAt).slice(0, 10)}
                                <br />{" "}
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                />{" "}
                                {String(record.updateAt).slice(11, 16)}
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    navigate(`editar/${record.id}`)
                                  }
                                  className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-pencil-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                  </svg>
                                </a>
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    navigate(`eliminar/${record.id}`)
                                  }
                                  className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-trash-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                  </svg>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* pagination, selected items */}
                  <div className={`${styles.row}`}>
                    <div
                      className={`${styles.col} ${styles.py3} ${styles.textCenter} ${styles.textMdStart}`}
                    >
                      {/* selected items 
                      <div className="dropup">
                        <a
                          className="btn btn-sm btn-primary"
                          href="#"
                          role="button"
                          id="ddSelected"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="group-icon">
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1={18} y1={6} x2={6} y2={18} />
                              <line x1={6} y1={6} x2={18} y2={18} />
                            </svg>
                          </span>
                          <span>Selected items</span>
                        </a>
                        <ul
                          className="dropdown-menu dropdown-menu-clean my-2"
                          aria-labelledby="ddSelected"
                        >
                          <li>
                            <a className="dropdown-item" href="#form-list">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                              <span>Active</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                              <span>Innactive</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-muted"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                              <span>Banned</span>
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <svg
                                className="text-danger"
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="3 6 5 6 21 6" />
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                <line x1={10} y1={11} x2={10} y2={17} />
                                <line x1={14} y1={11} x2={14} y2={17} />
                              </svg>
                              <span className="w-100">Delete</span>
                            </a>
                          </li>
                        </ul>
                      </div>*/}
                    </div>
                    <div className={`${styles.colMdAuto} ${styles.py3}`}>
                      {/* pagination */}
                      <nav
                        className={`${styles.w100} ${styles.textCenter}`}
                        aria-label="Pagination"
                      >
                        {/* pagination : desktop */}
                        <nav
                          className={`${styles.textCenter} ${styles.floatMdEnd} ${styles.mt3} ${styles.mtMd0} ${styles.dNone} ${styles.dMdFlex}`}
                          aria-label="Pagination"
                        >
                          <TablePagination
                            style={{ color: "var(--color-dark-variant)" }}
                            component="div"
                            classes="recent-orders"
                            labelRowsPerPage="Items por pagina"
                            count={total}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={limit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </nav>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaBlog;
