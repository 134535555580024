import httpClient from "./HttpClient";
const prefix = "/carrusel";

export default class CarruselService {
  static async create(carrusel) {
    return (await httpClient.post(`${prefix}/`, carrusel)).data;
  }

  static async update(carrusel) {
    return (await httpClient.put(`${prefix}/${carrusel.id}`, carrusel)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async getActive() {
    return (await httpClient.get(`${prefix}Active/`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }
}
