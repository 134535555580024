/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import DropZoneEdit from "../../DropZoneEdit";
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";
import { Typography } from "@mui/material";

/* services */
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.service";

import styles from "../layout/assets/css/core.min.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const EditarCarrusel = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [carrusel, setCarrusel] = useState();
  const matches = useMediaQuery("(min-width:769px)");

  useEffect(() => {
    loadCarruselItem();
  }, []);

  const loadCarruselItem = async () => {
    setLoading(true);
    try {
      const result = await CarruselService.getById(parseInt(id));
      if (result.imagen) {
        const imagen = await S3Service.get(result.imagen);
        setImagen(imagen.result);
      }
      setCarrusel(result);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(true);
      return;
    }
  };

  const updateCarrusel = async () => {
    setLoading(true);
    try {
      const dataCarrusel = carrusel;
      if (file) {
        await S3Service.delete(dataCarrusel.imagen);
        const uploadPhoto = await S3Service.upload(file);
        dataCarrusel.imagen = uploadPhoto.result.data;
        await CarruselService.update(dataCarrusel);
      } else {
        await CarruselService.update(dataCarrusel);
      }
      setLoading(false);
      navigate("/carousel");
      toast.success("Item de carrusel actualizado");
    } catch (error) {
      toast.error(error);
      setLoading(true);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>Editar Item</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li className={`${styles.breadcrumbItem}`}>
                    <a href="javascript:void(0);" onClick={() => navigate("/")}>
                      Carrusel
                    </a>
                  </li>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Editar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className={`${styles.row} ${styles.g4}`}>
                <div className={`${styles.col}`}>
                  <div
                    className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                  >
                    Imagen Item
                  </div>
                  {/* Shipping address */}
                  <div className={`${styles.section} ${styles.mb4}`}>
                    <div className={`${styles.cardBody}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.gshadowNone}`}
                              placeholder="Título"
                              value={carrusel?.titulo}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  titulo: e.target.value,
                                })
                              }
                            />
                            <label>Título</label>
                          </div>
                        </div>
                        <div className={`${styles.colSm6}`}>
                          <div className={`${styles.formFloating}`}>
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.gshadowNone}`}
                              value={carrusel?.subtitulo}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  subtitulo: e.target.value,
                                })
                              }
                              placeholder="Mensaje"
                            />
                            <label htmlFor="shipping__last_name">Mensaje</label>
                          </div>
                        </div>
                        <div className={matches ? `${styles.col9}` : ""}>
                          <div
                            className={`${styles.formFloating} ${styles.my3}`}
                          >
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.gshadowNone}`}
                              value={carrusel?.url}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  url: e.target.value,
                                })
                              }
                              placeholder="Url"
                            />
                            <label
                              className={`${styles.w100} ${styles.textTruncate}`}
                              htmlFor="shipping__address_1"
                            >
                              Url. Ej: https://soriasesores.com/
                            </label>
                          </div>
                        </div>
                        <div className={matches ? `${styles.col3}` : ""}>
                          <div
                            className={`${styles.formFloating} ${styles.my3}`}
                          >
                            <input
                              type="text"
                              className={`${styles.formControl} ${styles.gshadowNone}`}
                              value={carrusel?.boton}
                              onChange={(e) =>
                                setCarrusel({
                                  ...carrusel,
                                  boton: e.target.value,
                                })
                              }
                              placeholder="Texto del botón."
                            />
                            <label
                              className={`${styles.w100} ${styles.textTruncate}`}
                              htmlFor="shipping__address_1"
                            >
                              Texto del botón.
                            </label>
                          </div>
                        </div>
                        {file ? (
                          <div style={{ textAlign: "center" }}>
                            <img
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                              src={URL.createObjectURL(file)}
                            />
                            <Typography
                              variant="body1"
                              component="span"
                              style={{
                                width: "-webkit-fill-available",
                              }}
                            >
                              {file.name}
                            </Typography>
                          </div>
                        ) : imagen ? (
                          <div style={{ textAlign: "center" }}>
                            <img
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                              src={String(imagen)}
                            />
                          </div>
                        ) : null}
                        <DropZoneEdit
                          saveFile={async (file) => {
                            setFile(file);
                            var reader = new FileReader();
                            var url = reader.readAsDataURL(file);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={`${styles.co12}`}>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => updateCarrusel()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>{" "}
                  {"  "}
                  <span className={`${styles.small}`}>Editar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default EditarCarrusel;
