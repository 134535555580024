import httpClient from "./HttpClient";
const prefix = "/aviso";

export default class AvisoPrivacidadService {
  static async update(aviso) {
    return (await httpClient.put(`${prefix}/${aviso.id}`, aviso)).data;
  }

  static async get() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

}
