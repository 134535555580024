import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";
import NoticiaService from "../../../services/Noticia.service";
import { getAutoHeightDuration } from "@mui/material/styles/createTransitions";
import BlogCard from "./BlogCard";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";
import ReCAPTCHA from "react-google-recaptcha";
import MensajeService from "../../../services/Mensaje.service";
import BlogMensaje from "./BlogMensaje";
import EntradaSimilar from "./EntradaSimilar";
import ItemBlog from "./ItemBlog";
import EntradasRecientes from "./EntradasRecientes";
import Siguenos from "./Siguenos";
import banner from "./blog.jpg";

import WOW from "wowjs";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TumblrShareButton,
} from "react-share";
import ImageComponent from "./ImageComponent";

const BlogEntrada = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [noticias, setNoticias] = useState([]);

  const [autor, setAutor] = useState("Ad");
  const [fecha, setFecha] = useState("");
  const [imag, setImagen] = useState(null);
  const [loading, setLoading] = useState(true);
  const [aleatorio, setAleatorio] = useState(true);
  const [nuevo, setNuevo] = useState(false);
  const [popular, setPopular] = useState(false);
  const [tag, setTag] = useState(false);
  const [filtro, setFiltro] = useState(undefined);
  const [tabUno, setTabUno] = useState("active");
  const [tabDos, setTabDos] = useState("");
  const [tabUnoCuerpo, setTabUnoCuerpo] = useState("show active");
  const [tabDosCuerpo, setTabDosCuerpo] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [valido, setValido] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [mensajes, setMensajes] = useState([]);
  const [totalMensajes, setTotalMensajes] = useState([]);
  const [ultimasNoticias, setultimasNoticias] = useState([]);
  const [Entradas, setEntradas] = useState([]);
  const [buscar, setBuscar] = useState("");
  const [total, setTotal] = useState(0);
  const [hashtag, setHashtag] = useState([]);

  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    getNoticia();
    getNoticias();
    getMensajes();
    getAutor();
    getTiempo();
    window.scrollTo(0, 0);
  }, [id]);

  function onChange(value) {
    console.log("Captcha value:", value);
    setValido(false);
  }

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      setultimasNoticias(data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getNoticia = async () => {
    try {
      const data = await NoticiaService.getById(id);
      S3Service.get(data[0].imagen).then((dataimagen) => {
        document.getElementById(`imagen${data[0].imagen}`).src =
          dataimagen.result;
        setImagen(dataimagen.result);
      });
      setNoticia(data[0]);
      setFiltro(data[0].categoria);
      //Hashtag
      setHashtag([data[0].categoria.replace(/\s+/g, ""), "SORIAasesores"]);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      getEntradasFiltroCategoria();
    }
  };

  const getMensajes = async () => {
    try {
      const data = await MensajeService.getByIdBlog(id);
      setTotalMensajes(data.total);
      setMensajes(data.data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const frase = (frase, autor) => {
    return (
      <div className="blog-quoted text-start bg-grey px-4 py-4 position-relative mb-4 bg-dark">
        <p className="text-white">“{frase}”</p>
        <span className="text-primary">– {autor} </span>
      </div>
    );
  };

  const enviarMensaje = async () => {
    if (!mensaje.MensajeCuerpo) {
      toast.info("Error, tienes que colocar un texto");
    } else {
      setLoading(true);
      try {
        let idUser = currentUser.id;
        let nombreUser = currentUser.nombre;
        const datosMensaje = {
          idBlog: id,
          IdUsuario: currentUser.id,
          nombre: nombreUser,
          MensajeCuerpo: mensaje.MensajeCuerpo,
          imagenes: currentUser.imagen,
        };
        await MensajeService.create(datosMensaje);
        const data = await MensajeService.getByIdBlog(id);
        setTotalMensajes(data.total);
        setMensajes(data.data);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        const limpiar = {
          Nombre: "",
          MensajeCuerpo: "",
          idBlog: "",
        };
        setMensaje(limpiar);
        setValido(true);
        toast.info("Gracias, mensaje enviado");
        const data = await MensajeService.getByIdBlog(id);
        setTotalMensajes(data.total);
        setMensajes(data.data);
        getMensajes();
      }
    }
  };

  const activarMensaje = () => {
    if (currentUser == undefined) {
      return (
        <>
          <div className="clearfix mt-5">
            <div style={{ padding: "10px", borderRadius: "10px" }}>
              {/* Not logged in */}
              <span className="d-block text-muted small mb-1">
                Para dejar un comentario debes{" "}
                <a
                  href="javascript:void(0);"
                  onClick={() => navigate(`/iniciarsesion`)}
                >
                  INICIAR SESIÓN.
                </a>
              </span>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Your Name"
                  value={currentUser.nombre}
                />
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email Address"
                  value={currentUser.correo}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <textarea
              name="reply"
              rows={6}
              className="form-control"
              placeholder="Your Reply"
              defaultValue={""}
              onChange={(e) =>
                setMensaje({
                  ...mensaje,
                  MensajeCuerpo: e.target.value,
                })
              }
              value={mensaje.MensajeCuerpo}
            />
          </div>
          <div>
            <button
              className="butn-style3 medium"
              type="submit"
              onClick={() => enviarMensaje()}
            >
              Deja un mensaje
            </button>
          </div>
        </>
      );
    }
  };

  const borrarComentario = async (idBlog) => {
    try {
      const dataBorrar = await MensajeService.remove(idBlog);
      const data = await MensajeService.getByIdBlog(id);
      console.log("Mensajes" + data.total);
      setTotalMensajes(data.total);
      setMensajes(data.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const getEntradasFiltroCategoria = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      const data = await NoticiaService.filtroCategoria(filtro, 3, 0);
      setEntradas(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      console.log(Entradas);
    }
  };

  const getTiempo = async () => {
    try {
      //Tiempo
      const data = await NoticiaService.getById(id);
      //Tiempo
      const date = new Date(data[0].updateAt);

      // Obtener los componentes de fecha y hora
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const day = ("0" + date.getDate()).slice(-2);
      // Formatear el texto de salida
      setFecha(`${day} de ${monthNames[monthIndex]} de ${year}`);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const getAutor = async () => {
    try {
      const data = await NoticiaService.getById(id);
      const result = await UserService.getById(data[0].usuarioId);
      S3Service.get(result.imagen).then((dataimagen) => {
        document.getElementById(`imagen2${data[0].imagen}`).src =
          dataimagen.result;
        console.log(dataimagen.result);
        autor.image = dataimagen.result;
      });
      setAutor(result.nombre);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <>
        {/* PAGE TITLE
  ================================================== */}
        <section
          className="page-title-section bg-img cover-background left-overlay-dark"
          data-overlay-dark={6}
          data-background="img/banner/page-title.jpg"
          style={{ backgroundImage: `url(${banner})`, height: "20rem" }}
        >
          <div className="container position-unset">
            <div className="page-title mx-1-6 mx-lg-2-0 mx-xl-2-6 mx-xxl-2-9">
              <div className="row">
                <div className="col-md-12">
                  <h1>Entrada</h1>
                </div>
                <div className="col-md-12">
                  <ul className="ps-0">
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate("/")}
                      >
                        Inicio
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate("/Blog")}
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Entrada</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>

      <>
        {/* BLOG DETAILS
  ================================================== */}

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-5 mb-lg-0">
                <div className="row">
                  <div className="col-lg-12 mb-2-3">
                    <div className="card card-style7 border-0">
                      <img
                        className="card-img-top"
                        alt="Card image cap"
                        id={`imagen${noticia.imagen}`}
                        style={{
                          width: "100%",
                          height: "500px",
                          objectFit: "cover",
                        }}
                      />
                      <div className="card-body px-4 py-2-3">
                        <h2 className="mb-4">{noticia.titulo}</h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: noticia.cuerpoNoticia,
                          }}
                          style={{ textAlign: "justify" }}
                        ></p>
                        {noticia.autorFraseCelebre
                          ? frase(
                              noticia.fraseCelebre,
                              noticia.autorFraseCelebre
                            )
                          : ""}

                        <div className="pt-4">
                          <h4 className="h6 d-inline-block me-2">Compartir:</h4>
                          <a href="javascript:void(0)" className="me-2">
                            <FacebookShareButton
                              quote={
                                "Leí una entrada increíble en este blog, Lee aquí: "
                              }
                              hashtag={"#" + noticia.categoria}
                              id="btn-fb"
                              url={window.location.href}
                            >
                              <FaFacebookF />
                            </FacebookShareButton>
                          </a>
                          <a href="javascript:void(0)" className="me-2">
                            <TwitterShareButton
                              title={`Leí una entrada increíble en este blog, Lee aquí:\n  ${noticia.titulo}`}
                              hashtags={hashtag}
                              id="btn-fb"
                              url={window.location.href}
                            >
                              <FaXTwitter />
                            </TwitterShareButton>
                          </a>
                          <a href="javascript:void(0)" className="me-2">
                            <LinkedinShareButton
                              title={`Leí una entrada increíble en este blog, Lee aquí:\n  ${noticia.titulo}`}
                              hashtags={hashtag}
                              id="btn-fb"
                              url={window.location.href}
                            >
                              <FaLinkedinIn />
                            </LinkedinShareButton>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="d-flex justify-content-center align-items-center mx-auto py-1-9 px-3 bg-light">
                      <div className="me-3">
                        {loading ? null : (
                          <ImageComponent
                            className="rounded-circle w-60px"
                            alt="..."
                            id={`imagen2${noticia.imagen}`}
                            src={autor.image}
                            notFoundSrc="/img/avatar/perfil2.jpg"
                          />
                        )}
                      </div>
                      <div className="text-start">
                        <h4 className="h6 mb-0">Publicado por {autor}</h4>
                        <span className="small text-muted">{fecha} </span>
                      </div>
                    </div>
                  </div>
                  {/* comments */}
                  <div className="col-lg-12 my-2-3">
                    <h3 className="mb-1-9 h4">Comentarios</h3>
                    <ul className="m-0 p-0">
                      {mensajes.map((record) => {
                        console.log(record);
                        return (
                          <BlogMensaje
                            id={record.id}
                            nombre={record.nombre}
                            createdAt={record.createdAt}
                            imagenes={record.imagenes}
                            borrarComentario={(data) => borrarComentario(data)}
                            borrar={
                              currentUser?.id == record.IdUsuario ? true : false
                            }
                            MensajeCuerpo={record.MensajeCuerpo}
                          />
                        );
                      })}
                    </ul>
                  </div>
                  {/* end comments */}
                  <div className="col-lg-12">
                    <div className="blog-form p-1-9">
                      <h3 className="mb-1-9 h4">Deja un comentario</h3>
                      {activarMensaje()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sidebar ps-xl-4">
                  <EntradasRecientes />
                  <Siguenos />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default BlogEntrada;
