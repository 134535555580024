import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import ReactHtmlParser from "react-html-parser";

const CardTestimonios = ({
  texto,
  nombre,
  imagen,
  ocupacion,
  url,
  id,
  eliminarActual,
  eliminar,
}) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="100px"
        image={url}
        style={{ height: "150px", width: "200px" }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {nombre},{"  "} {ocupacion}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <div dangerouslySetInnerHTML={{ __html: texto }} />
        </Typography>
      </CardContent>
      <CardActions>
        {eliminar ? null : (
          <Button
            size="small"
            onClick={() => {
              eliminarActual(id);
              console.log("####CARD: " + id);
            }}
          >
            Eliminar
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default CardTestimonios;
