/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";

/* Servicios */
import ContactanosService from "../../../services/Contactanos.service";

const TablaContactanos = () => {
  const [contactanos, setContactanos] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [contenido, setContenido] = useState({});
  const myTheme = createTheme({});

  useEffect(() => {
    getContactanos();
  }, []);

  const getContactanos = async () => {
    setLoading(true);
    try {
      const cont = await ContactanosService.getAll();
      setContactanos(cont[0]);
      const contentHTML = convertFromHTML(cont[0].Cuerpo);
      
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(state));
      setContenido(content);
      console.log("contactanos info: "+ content)
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateContactanos = async () => {
    if (!contactanos.Cuerpo) {
      toast.info("Error, tienes que agregar texto");
    } else if (!contactanos.Numero) {
      toast.info("Error, tienes que colocar un número de telefono");
    } else if (!contactanos.Direccion) {
      toast.info("Error, tienes que colocar una dirección");
    } else if (!contactanos.Horario) {
      toast.info("Error, tienes que colocar un horario de atención");
    } else {
      setLoading(true);
      try {
        const dataContactanos = contactanos;
        const updateContactanos = await ContactanosService.update(
          dataContactanos
        );
        toast.success("Contactanos actualizado con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getContactanos();
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div style={{ display: "flex" }}>
                    <h4
                      className="card-title"
                      style={{ marginRight: "20px", marginTop: "6px" }}
                    >
                      Contactanos
                    </h4>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td scope="row">
                          <ThemeProvider theme={myTheme}>
                            <MUIRichTextEditor
                              label="Descripcion del paquete"
                              controls={[
                                "italic",
                                "underline",
                                "strikethrough",
                                "highlight",
                                "undo",
                                "redo",
                                "link",
                                "numberList",
                                "bulletList",
                                "quote",
                                "code",
                                "clear",
                                // "save"
                              ]}
                              // onSave={save}
                              inlineToolbar={true}
                              onChange={(value) => {
                                console.log(value.getCurrentContent());
                                setContactanos({
                                  ...contactanos,
                                  Cuerpo: stateToHTML(
                                    value.getCurrentContent()
                                  ),
                                });
                              }}
                              defaultValue={contenido}
                            />
                          </ThemeProvider>
                        </td>
                      </tr>
                      <thead>
                        <tr>
                          <th scope="col">
                            <input
                              placeholder="Dirección"
                              value={contactanos.Direccion}
                              onChange={(e) =>
                                setContactanos({
                                  ...contactanos,
                                  Direccion: e.target.value,
                                })
                              }
                              className="form-control"
                            />
                          </th>
                          <th scope="col">
                            <input
                              placeholder="Número telefonico"
                              value={contactanos.Numero}
                              onChange={(e) =>
                                setContactanos({
                                  ...contactanos,
                                  Numero: e.target.value,
                                })
                              }
                              className="form-control"
                            />
                          </th>
                          <th scope="col">
                            <input
                              placeholder="Horario de atención. Ej: Lunes a Viernes 9am a 6pm"
                              value={contactanos.Horario}
                              onChange={(e) =>
                                setContactanos({
                                  ...contactanos,
                                  Horario: e.target.value,
                                })
                              }
                              className="form-control"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tr>
                        <div
                          className="col-lg-12"
                          style={{ textAlign: "center", marginTop: "3%" }}
                        >
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => updateContactanos()}
                          >
                            Guardar
                          </Button>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TablaContactanos;
