import React, { useEffect, useState } from "react";
import NosotrosService from "../../../services/Nosotros.service";
import Logo from "./logo.jpg";
import img5 from "./img5.png";
import img6 from "./img6.jpg";
import img7 from "./img7.png";
import { useNavigate } from "react-router-dom";
const Nosotros = () => {
  const [loading, setLoading] = useState(true);
  const [nosotros, setNosotros] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getNosotros();
    window.scrollTo(0, 0);
  }, []);

  const getNosotros = async () => {
    try {
      const data = await NosotrosService.getAll();
      setNosotros(data[0]);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <>
      <section className="about-section" style={{ paddingTop: "100px" }}>
        <div className="container mt-lg-3 mt-xl-0">
          <div className="row clearfix align-items-xl-center">
            <div
              className="col-lg-6 position-relative wow fadeIn"
              data-wow-delay="400ms"
            >
              <div className="row align-items-center ps-lg-1-9 position-relative z-index-9">
                <div
                  className="col-sm-9 d-none d-sm-block"
                  style={{ width: "100%", height: "500px" }}
                >
                  <img
                    src={img5}
                    className="rounded"
                    alt="..."
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
              <br></br>
            </div>
            <div
              className="col-lg-6 text-column mb-2-9 mb-lg-0 wow fadeIn"
              data-wow-delay="200ms"
            >
              <div className="pe-xl-2-9">
                <h2 className="h1 mb-4">
                  Volvemos cierto lo incierto del futuro a través de una
                  asesoría profesional en seguros
                </h2>
                <p className="pb-3">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: nosotros?.Cuerpo,
                    }}
                    style={{ textAlign: "justify" }}
                  ></p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="circle-md left-10 top-15 border-secondary-color d-none d-xl-block" />
        <div
          className="bg-img bg-stripes right-25 top-15 ani-left-right d-none d-xl-block"
          data-background="img/content/bg-stripes.png"
        />
      </section>

      <section
        className="about-section"
        style={{ marginTop: "-100px", paddingTop: "-300px" }}
      >
        <div
          className="container mt-lg-3 mt-xl-0"
          style={{ marginTop: "-300px" }}
        >
          <div
            className="row clearfix align-items-xl-center"
            style={{ marginTop: "-100px", marginBottom: "-50px" }}
          >
            <div
              className="col-lg-6 position-relative wow fadeIn"
              data-wow-delay="400ms"
            >
              <div className="row align-items-center ps-lg-1-9 position-relative z-index-9">
                <div className="col-sm-5 text-center text-sm-start">
                  <img
                    src={img7}
                    className="rounded"
                    alt="..."
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                    }}
                  />
                  {/* <div className="text-center mt-4">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/nosotros")}
                      className="text-dark text-primary-hover font-weight-600"
                    >
                      Más informacion
                      <i className="ti-arrow-right ms-2 align-middle display-30" />
                    </a>
                  </div> */}
                </div>
                <div className="col-sm-7 d-none d-sm-block">
                  <img
                    src={img6}
                    className="rounded"
                    alt="..."
                    style={{
                      width: "100%",
                      height: "400px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 text-column mb-2-9 mb-lg-0 wow fadeIn"
              data-wow-delay="200ms"
            >
              <div className="pe-xl-2-9">
                <div className="clearfix d-sm-flex align-items-center mb-2-6">
                  <div className="since">
                    <h3 className="h3 mb-4">Misión.</h3>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: nosotros?.Mision,
                      }}
                      style={{ textAlign: "justify" }}
                    ></p>
                  </div>
                </div>

                <div className="clearfix d-sm-flex align-items-center mb-2-6">
                  <div className="since">
                    <h3 className="h3 mb-4">Visión.</h3>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: nosotros?.Vision,
                      }}
                      style={{ textAlign: "justify" }}
                    ></p>
                  </div>
                </div>
                <div>
                  {/* <a className="butn-style3 medium" href="javascritp:void(0);">
                    Más Información
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="circle-md left-10 top-15 border-secondary-color d-none d-xl-block" />
        <div
          className="bg-img bg-stripes right-25 top-15 ani-left-right d-none d-xl-block"
          data-background="img/content/bg-stripes.png"
        />
      </section>
    </>
  );
};

export default Nosotros;
