import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./assets/css/core.min.module.css";

import logo from "./LogoSoriaWeb.jpg";

const Slide = () => {
  const { logout } = useContext(AuthContext);
  const [active, setActive] = useState("carousel");
  let navigate = useNavigate();
  const matches = useMediaQuery("(min-width:992px)");

  const closeMenu = () => {
    let element;
    element = document.querySelector("#aside_responsive");
    element.style.display = "none";
  };

  const salir = () => {
    logout();
    navigate("/");
  };

  const navegar = (direccion) => {
    if (direccion === "Dashboard") {
      navigate("/carousel");
      setActive(direccion);
      return;
    }
    navigate(direccion);
    setActive(direccion);
    !matches && closeMenu();
  };

  useEffect(() => {
    let url = window.location.href;
    let urlArray = url.split("/");
    let path = urlArray[urlArray.length - 1].split("#");
    setActive(path[0] || "carousel");
  }, []);
  return (
    <>
      {/* sidebar */}
      <aside
        id="aside_responsive"
        className={
          matches &&
          ` ${styles.dFlex} ${styles.asideStart} ${styles.bgWhite} ${styles.shadowSm} ${styles.flexColumn} ${styles.px2} ${styles.hAuto}`
        }
        style={{ background: "white", height: "100vh", overflow: "auto" }}
      >
        {/* sidebar : logo */}
        <div
          className={`${styles.py2} ${styles.px3} ${styles.mb3} ${styles.mt1}`}
        >
          <a href="javascript:void(0);" onClick={() => navegar("/")}>
            <img src={logo} width={200} height={90} alt="..." />
          </a>
        </div>
        {/* /sidebar : logo */}
        {/* sidebar : navigation */}
        <div
          className={`${styles.asideWrapper} ${styles.scrollableVertical} ${styles.scrollableStyledLight} ${styles.alignSelfBaseline}  ${styles.h100} ${styles.w100}`}
        >
          <nav
            className={`${styles.navDeep} ${styles.navDeepSm} ${styles.navDeepLight}`}
          >
            <ul className={`${styles.nav} ${styles.flexColumn}`}>
              {matches ? null : (
                <li
                  style={{ textAlign: "right", backgroundColor: "#5F0F46" }}
                  onClick={() => closeMenu()}
                >
                  <a
                    href="javascript:void(0)"
                    style={{
                      paddingInline: "1rem",
                      fontSize: "1rem",
                      color: "white",
                    }}
                  >
                    <i
                      class="fa fa-arrow-left"
                      aria-hidden="true"
                      style={{ paddingInlineEnd: "1rem" }}
                    >
                      {" "}
                      CERRAR
                    </i>
                  </a>
                </li>
              )}
              {/* <li
                className={`${styles.navItem} ${
                  (active === "carousel" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => {
                    navegar("carousel");
                  }}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className={"bi bi-house-fill"}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                    />
                  </svg>
                  <span>Inicio</span>
                </a>
              </li> */}

              <li className={`${styles.navTitle} ${styles.mt4}`}>
                <h6
                  className={`${styles.mb0} ${styles.smaller} ${styles.textMuted} ${styles.textUppercase}`}
                >
                  Administración
                </h6>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "carousel" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("carousel")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={styles["bi bi-images"]}
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                    <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                  </svg>
                  <span>Carrusel</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "blog" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("blog")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-blockquote-left"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm5 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm-5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm.79-5.373c.112-.078.26-.17.444-.275L3.524 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282c.024-.203.065-.37.123-.498a1.38 1.38 0 0 1 .252-.37 1.94 1.94 0 0 1 .346-.298zm2.167 0c.113-.078.262-.17.445-.275L5.692 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282a1.75 1.75 0 0 1 .118-.492c.058-.13.144-.254.257-.375a1.94 1.94 0 0 1 .346-.3z" />
                  </svg>
                  <span>Blog</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "servicio" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("servicio")}
                >
                  <svg
                    width="18px"
                    height="18px"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3z"
                    />
                    <path d="M13 3V2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z" />
                  </svg>
                  <span>Servicios</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>

              <li
                className={`${styles.navItem} ${
                  (active === "usuario" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("usuario")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-people"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                  </svg>
                  <span>Usuarios</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "testimonios" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("testimonios")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-people"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                  </svg>
                  <span>Testimonios</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
              <li className={`${styles.navTitle} ${styles.mt4}`}>
                <h6
                  className={`${styles.mb0} ${styles.smaller} ${styles.textMuted} ${styles.textUppercase}`}
                >
                  Catálogos
                </h6>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "categoriaBlog" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("categoriaBlog")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-table"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                  </svg>
                  <span>Categorías del Blog</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "mensajes" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("mensajes")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-chat-left-text"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                  </svg>
                  <span>Mensajes</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
              <li className={`${styles.navTitle} ${styles.mt4}`}>
                <h6
                  className={`${styles.mb0} ${styles.smaller} ${styles.textMuted} ${styles.textUppercase}`}
                >
                  Datos generales
                </h6>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "index" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("index")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-pencil-square"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <span>Página de inicio</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "nosotros" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("nosotros")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-pencil-square"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <span>Nosotros</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "aviso" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("aviso")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-pencil-square"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <span>Aviso de privacidad</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "terminos" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("terminos")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-pencil-square"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <span>Terminos y condiciones</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
              <li
                className={`${styles.navItem} ${
                  (active === "footer" || active === "") && styles.active
                }`}
              >
                <a
                  className={`${styles.navLink}`}
                  href="javascript:void(0);"
                  onClick={() => navegar("footer")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.bi} ${styles["bi-pencil-square"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <span>Footer</span>
                  <span className={`${styles.groupIcon} ${styles.floatEnd}`}>
                    <i className={`${styles.fi} ${styles.fiArrowEnd}`} />
                    <i className={`${styles.fi} ${styles.fiArrowDown}`} />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        {/* /sidebar : navigation */}
        {/* sidebar : footer */}
        <div
          className={`${styles.dFlex} ${styles.alignSelfBaseline} ${styles.w100} ${styles.py3} ${styles.px3} ${styles.borderTop} ${styles.borderLight} ${styles.small}`}
        >
          <p
            className={`${styles.dInlineGrid} ${styles.gapAuto3} ${styles.mb0} ${styles.textMuted}`}
          >
            <span className="material-icons-sharp"> logout </span>
            <a
              href="javascript:void(0);"
              onClick={() => salir()}
              className={`${styles.linkNormal} ${styles.textDashed}`}
            >
              Logout
            </a>
          </p>
        </div>
        {/* /sidebar : footer */}
      </aside>
      {/* /sidebar */}
    </>
  );
};
export default Slide;
