import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";

/*Servicios*/
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

/*Componentes*/
import FullPageLoading from "../../../utils/FullPageLoading";
import { toast } from "react-toastify";

const Portada_ItemBlog = ({ data }) => {
  const navegar = useNavigate();
  const [usuario, setUsuario] = useState({});
  const [imagen, setImagen] = useState({});
  const [autor, setAutor] = useState([]);
  const [fecha, setFecha] = useState({ dia: "", mes: "" });

  const regex = /<[^>]'>/gim;
  const monthNames = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  useEffect(() => {
    setImagen("");
    getImage();
    getAutor();
    getTiempo();
  }, []);

  const getAutor = async () => {
    try {
      const result = await UserService.getById(data.usuarioId);
      setAutor(result.nombre);
    } catch (error) {
      toast.error(error);
    }
  };

  const getImage = async () => {
    try {
      S3Service.get(data.imagen[0]).then((dataimagen) => {
        setImagen(dataimagen.result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTiempo = async () => {
    try {
      //Tiempo
      const date = new Date(data.updateAt);

      // Obtener los componentes de fecha y hora
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const day = ("0" + date.getDate()).slice(-2);
      // Formatear el texto de salida
      setFecha({ dia: day, mes: monthNames[monthIndex], anio: year });
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const cortarTexto = (entry) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(entry, "text/html");
    return doc.body.innerText;
  };

  return (
    <>
      <div class="col-md-6 col-lg-4 mt-1-6 wow fadeIn" data-wow-delay="200ms">
        <article class="card card-style6 border-0 h-100">
          <div
            class="card-img"
            onClick={() => navegar(`/blog/${data.id}`)}
            style={{ height: "19rem", cursor: "pointer" }}
          >
            <img
              src={imagen}
              class="rounded"
              alt="..."
              style={{
                height: "100%",
                width: " auto",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </div>
          <div class="card-body p-0">
            <div class="position-relative" style={{ height: "100%" }}>
              <div class="blog-date position-absolute bg-primary">
                <span class="d-block text-white display-26 display-sm-23 lh-1 mb-2">
                  {fecha?.dia}
                </span>
                <span class="d-block text-white lh-1">{fecha?.mes}</span>
              </div>
              <div
                class="p-3"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <ul class="mb-2 p-0 mb-3">
                  {autor && (
                    <li class="display-30 d-inline-block me-3">
                      <span>
                        <i class="ti-user text-primary pe-2"></i>
                      </span>
                      {autor}
                    </li>
                  )}
                  <li class="display-30 d-inline-block">
                    {/* <span>
                      <i class="ti-comments text-primary pe-2"></i>
                    </span> */}
                    {fecha.anio}
                  </li>
                </ul>
                <h3 class="h5 mb-3">
                  <a
                    href="javascript:void(0);"
                    onClick={() => navegar(`/blog/${data.id}`)}
                  >
                    {data.titulo}
                  </a>
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: String(cortarTexto(data.cuerpoNoticia))
                      .slice(0, 210)
                      .concat("..."),
                  }}
                ></p>
                <a
                  href="javascript:void(0);"
                  onClick={() => navegar(`/blog/${data.id}`)}
                  class="butn"
                >
                  Leer más...
                </a>
              </div>
            </div>
          </div>
        </article>
      </div>
      {/* <article className="blog-box">
        <div className="row align-items-center">
          <div className="col-md-4">
            <div className="blog-img">
              <img
                className="rounded"
                data-src="assets/img/blog/blog-img3.jpg"
                id={`imagen${data.imagen}`}
                alt="Card image"
                src={imagen}
                style={{ height: "300px", objectFit: "cover" }}
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="blog-text" style={{ overflow: "hidden" }}>
              <span className="mb-1 d-block text-primary">{fecha}</span>
              <h3 className="h4 mb-4">
                <a href="javascript:void(0);">{data.titulo}</a>
              </h3>
              <p className="w-xl-90 mb-1-9">
                <p
                  dangerouslySetInnerHTML={{
                    __html: String(data.cuerpoNoticia)
                      .slice(0, 210)
                      .concat("..."),
                  }}
                  style={{
                    textAlign: "justify",
                  }}
                ></p>
              </p>
              <a
                className="butn-style3 small"
                href="javascript:void(0);"
                onClick={() => navegar(`/blog/${data.id}`)}
              >
                Leer más
              </a>
            </div>
          </div>
        </div>
      </article> */}
      {/*<div className="col-md-4">
      <div className="card" style={{height:"425px"}}>
        <div className="position-relative">
          <a href="#" onClick={() => navegar(`/blog/${data.id}`)}>
            <img
              className="card-img-top"
              data-src="assets/img/blog/blog-img3.jpg"
              id={`imagen${data.imagen}`}
              alt="Card image"
              style={{height:"200px", objectFit: "cover"}}
            />
          </a>
          <div className="card-img-overlay p-0">
            <span className="badge bg-danger badge-rounded m-4">
              {" "}
              <i className="far fa-calendar" aria-hidden="true" />
              {"  "}
              {fecha}
            </span>
          </div>
        </div>
        <div className="card-body border-top-5 px-3 border-danger">
          <h3 className="card-title">
            <a
              className="text-danger text-capitalize d-block text-truncate"
              href="#" onClick={() => navegar(`/blog/${data.id}`)}
            >
              {data.titulo}
            </a>
          </h3>
          <ul className="list-unstyled d-flex flex-md-column flex-lg-row">
            <li className="me-2">
              <a className="text-muted" href="#" onClick={() => navegar(`/blog/${data.id}`)}>
                <i className="fa fa-user me-2" aria-hidden="true" />
                {autor}
              </a>
            </li>
            <li className="me-2">
              <a className="text-muted" href="#" onClick={() => navegar(`/blog/${data.id}`)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag-fill" viewBox="0 0 16 16">
  <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
</svg>{"   "}
                {data.categoria}
              </a>
            </li>
          </ul>
          <p
                dangerouslySetInnerHTML={{
                  __html: String(data.cuerpoNoticia).slice(0, 150),
                }}
                style={{
                  textAlign: "justify",
                  height:"50px"
                }}
              ></p>
          <a
            className="btn btn-link text-hover-danger ps-0"
            href="#"
            onClick={() => navegar(`/blog/${data.id}`)}
          >
            <i className="fas fa-angle-double-right me-1" aria-hidden="true" />{" "}
            Leer más
          </a>
        </div>
      </div>
    </div>*/}
    </>
  );
};

export default Portada_ItemBlog;
