import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
/*Servicios*/
import FooterService from "../../../services/Footer.service";
//Componentes
import logo from "../assets/img/vertical SORIA 20 ANIVERSARIO-circular.png";
import background from "../assets/img/footer-map.png";
import MapaFooter from "./MapaFooter";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const FooterPublicLayout = () => {
  const [footer, setFooter] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [locations, setLocations] = useState([
    {
      lat: 17.052747,
      lng: -96.72233,
    },
  ]);

  useEffect(() => {
    getFooter();
    window.scrollTo(0, 0);
  }, []);

  const getFooter = async () => {
    try {
      const foot = await FooterService.getAll();
      setFooter(foot[0]);

      if (
        foot[0]?.Direccion &&
        foot[0]?.Direccion?.lat !== 0 &&
        !isNaN(foot[0]?.Direccion.lat)
      ) {
        setLocations([
          {
            lat: Number(foot[0]?.Direccion?.lat),
            lng: Number(foot[0]?.Direccion?.long),
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <>
        {/* FOOTER
  ================================================== */}
        <footer
          className="footer2 bg-img theme-overlay-blue-dark pt-6 pt-md-8"
          data-overlay-dark={7}
          data-background={background}
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          <div className="container">
            <div className="row mb-6 mb-md-8 mt-n1-9">
              <div
                className="col-md-6 col-lg-3 mt-1-9 wow fadeIn"
                data-wow-delay="200ms"
              >
                <div className="footer-top">
                  <div
                    className="mb-4"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      height: "13rem",
                      width: "13rem",
                      alignContent: "center",
                    }}
                  >
                    <img src={logo} alt="..." />
                  </div>
                  <p className="mb-4 text-white opacity9">
                    <p className="mb-6">{footer?.Texto}</p>
                  </p>
                  <ul className="list-unstyled mb-0">
                    <li className="d-inline-block me-3">
                      <a
                        href={footer?.fb ? footer?.fb : "https://facebook.com"}
                        target="_blank"
                        className="text-anniversary-link"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li className="d-inline-block me-3">
                      <a
                        href={
                          footer?.insta
                            ? footer?.insta
                            : "https://instagram.com"
                        }
                        target="_blank"
                        className="text-anniversary-link"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                    <li className="d-inline-block me-3">
                      <a
                        href={footer?.tw ? footer?.tw : "https://x.com"}
                        target="_blank"
                        className="text-anniversary-link"
                      >
                        <FaXTwitter />
                      </a>
                    </li>
                    <li className="d-inline-block me-3">
                      <a
                        href={footer?.in ? footer?.in : "https://linkedin.com"}
                        target="_blank"
                        className="text-anniversary-link"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 mt-1-9 wow fadeIn"
                data-wow-delay="350ms"
              >
                <div className="ps-lg-1-9">
                  <h3 className="text-anniversary mb-1-9 h5">Links</h3>
                  <ul className="footer-link list-unstyled mb-0">
                    <li className="mb-3">
                      <a href="javacript:(0);" onClick={() => navigate("/")}>
                        Inicio
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href="javacipt:void(0);"
                        onClick={() => navigate("/nosotros")}
                      >
                        Nosotros
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate("/Servicios")}
                      >
                        Servicios
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate("/Blog")}
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate("/contactanos")}
                      >
                        Contáctanos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-3 mt-1-9 wow fadeIn"
                data-wow-delay="650ms"
              >
                <div>
                  <h3 className="text-anniversary mb-1-9 h5">Contáctanos</h3>
                  <ul className="footer-link list-unstyled mb-0">
                    <li className="mb-3">
                      <i className="ti-mobile display-28 align-middle text-anniversary pe-3" />
                      <a
                        href={"tel:" + footer?.Telefono}
                        className="ps-0 text-anniversary-white"
                      >
                        {footer?.Telefono}
                      </a>
                    </li>
                    <li className="mb-3">
                      <i className="ti-email display-28 align-middle text-anniversary pe-3" />
                      <a
                        href={"mailTo:" + footer?.Correo}
                        className="ps-0 text-anniversary-white"
                      >
                        {footer?.Correo}
                      </a>
                    </li>
                    <li>
                      <i className="ti-location-pin display-28 align-middle text-anniversary pe-3" />
                      <a
                        className="text-anniversary-white"
                        target="_blank"
                        href={
                          footer?.Direccion
                            ? `https://www.google.com/maps/search/?api=1&query=${footer?.Direccion.lat},${footer?.Direccion.long}5&zoom=20`
                            : "javascript:void(0);"
                        }
                      >
                        {footer?.Direccion?.Direccion}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-3 mt-1-9 wow fadeIn"
                data-wow-delay="500ms"
              >
                <div
                  className="mb-10"
                  id="map"
                  style={{
                    width: "100%",
                    height: 250,
                    marginTop: "-4%",
                  }}
                >
                  {loading ? null : (
                    <MapaFooter locations={locations} zoom={16} />
                  )}
                </div>
              </div>
            </div>
            <div
              className="border-top border-color-light-white py-4 wow fadeIn"
              data-wow-delay="200ms"
            >
              <div className="text-center">
                <p className="d-inline-block mb-0 text-white">
                  Copyright © 2022 <span className="current-year" />
                  <a
                    href="http://www.karimnot.com/"
                    className="text-anniversary-link"
                    target="_blank"
                  >
                    {" "}
                    KARIMNOT INC
                  </a>
                </p>
                <p className="small mb-0 text-white">
                  Todos los derechos reservados{" "}
                  <a
                    onClick={() => navigate("/aviso")}
                    className="text-anniversary-link"
                    href="javascript:void(0)"
                  >
                    Aviso de privacidad
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </>
    </>
  );
};

export default FooterPublicLayout;
