import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ContactanosService from "../../../services/Contactanos.service";
// import GoogleMaps from "simple-react-google-maps";
import AOS from "aos";
import "aos/dist/aos.css";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

import banner from "./contactanos.jpg";

import FooterService from "../../../services/Footer.service";
import ContactanosMensajeService from "../../../services/ContactanosMensaje.service";
import MapComponent from "../layout/MapaFooter";
const Contactanos = () => {
  const ReCAPTCHAKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

  const [mensaje, setMensaje] = useState({ undefined });
  const [valido, setValido] = useState(null);
  const [aceptar, setAceptar] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [informacion, setInfo] = useState({ undefined });
  const [locations, setLocations] = useState([
    {
      lat: 17.052747,
      lng: -96.72233,
    },
  ]);

  var lat = "17.052466949615273";
  var lng = "-96.72238057281628";

  useEffect(() => {
    AOS.init();
    getContactanos();
    window.scrollTo(0, 0);
  }, []);

  const getContactanos = async () => {
    setLoading(true);
    try {
      const foot = await FooterService.getAll();
      setInfo(foot[0]);

      if (
        foot[0]?.Direccion &&
        foot[0]?.Direccion?.lat !== 0 &&
        !isNaN(foot[0]?.Direccion.lat)
      ) {
        setLocations([
          {
            lat: Number(foot[0]?.Direccion?.lat),
            lng: Number(foot[0]?.Direccion?.long),
          },
        ]);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  function onChange(value) {
    setValido(value);
  }

  const enviarMensaje = async () => {
    if (!mensaje.Nombre) {
      toast.info("Error, tienes que colocar tu nombre");
    } else if (!mensaje.Email) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!mensaje.Telefono) {
      toast.info("Error, tienes que colocar un número telefónico");
    } else if (!mensaje.MensajeCuerpo) {
      toast.info("Error, tienes que colocar una mensaje");
    } else if (!aceptar) {
      toast.info(
        "Para poder enviar tu mensaje debes de aceptar  los terminos y condiciones"
      );
    } else if (!valido) {
      toast.info("Error, no has comprobado tu identidad");
    } else {
      try {
        const datosMensaje = mensaje;
        datosMensaje.Status = "SinAbrir";
        await ContactanosMensajeService.create(datosMensaje);
      } catch (error) {
        toast.error(error);
      } finally {
        const limpiar = {
          Nombre: "",
          Email: "",
          MensajeCuerpo: "",
          Telefono: "",
          Subject: "",
        };
        setMensaje(limpiar);
        setValido(true);
        toast.info("Gracias, mensaje enviado");
      }
    }
  };

  return (
    <>
      <>
        {/* PAGE TITLE
  ================================================== */}
        <section
          className="page-title-section bg-img cover-background left-overlay-dark"
          data-overlay-dark={6}
          data-background="img/banner/page-title.jpg"
          style={{ backgroundImage: `url(${banner})`, height: "20rem" }}
        >
          <div className="container position-unset">
            <div className="page-title mx-1-6 mx-lg-2-0 mx-xl-2-6 mx-xxl-2-9">
              <div className="row">
                <div className="col-md-12">
                  <h1>Contáctanos</h1>
                </div>
                <div className="col-md-12">
                  <ul className="ps-0">
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate("/")}
                      >
                        Inicio
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Contáctanos</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <>
        {/* CONTACT FORM
  ================================================== */}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xl-4 mb-2-9 mb-lg-0">
                <div className="pe-lg-3 mt-n1-9">
                  <div className="card card-style8 mt-1-9">
                    <div className="card-body p-1-9">
                      <div className="media">
                        <div className="icon-box">
                          <i className="ti-location-pin text-primary z-index-9 position-relative" />
                          <div className="box-circle primary" />
                        </div>
                        <div className="media-body">
                          <h4 className="h5">Ubicación</h4>
                          <a
                            className="text-color"
                            target="_blank"
                            href={
                              informacion?.Direccion
                                ? `https://www.google.com/maps/search/?api=1&query=${informacion?.Direccion.lat},${informacion?.Direccion.long}5&zoom=20`
                                : "javascript:void(0);"
                            }
                          >
                            {informacion?.Direccion?.Direccion}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-style8 mt-1-9">
                    <div className="card-body p-1-9">
                      <div className="media">
                        <div className="icon-box">
                          <i className="ti-mobile text-primary z-index-9 position-relative" />
                          <div className="box-circle primary" />
                        </div>
                        <div className="media-body">
                          <h4 className="h5">Teléfono</h4>
                          <a href={"tel:" + informacion?.Telefono}>
                            <span className="d-block">
                              {informacion?.Telefono}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-style8 mt-1-9">
                    <div className="card-body p-1-9">
                      <div className="media">
                        <div className="icon-box">
                          <i className="ti-email text-primary z-index-9 position-relative" />
                          <div className="box-circle primary" />
                        </div>
                        <div className="media-body">
                          <h4 className="h5">Email</h4>
                          <a href={"mailTo:" + informacion?.Correo}>
                            <span className="d-block">
                              {informacion?.Correo}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-xl-8">
                <div className="contact-form p-4 p-md-5 ms-xl-3">
                  <h2 className="h3 mb-4">Envíanos un mensaje</h2>
                  <form
                    className="quform"
                    action="quform/contact.php"
                    method="post"
                    encType="multipart/form-data"
                    onclick=""
                  >
                    <div className="quform-elements">
                      <div className="row">
                        {/* Begin Text input element */}
                        <div className="col-md-6">
                          <div className="quform-element form-group">
                            <label htmlFor="name">
                              Nombre <span className="quform-required">*</span>
                            </label>
                            <div className="quform-input">
                              <input
                                className="form-control"
                                id="name"
                                type="text"
                                name="name"
                                placeholder="Tu nómbre aquí"
                                onChange={(e) =>
                                  setMensaje({
                                    ...mensaje,
                                    Nombre: e.target.value,
                                  })
                                }
                                value={mensaje.Nombre}
                              />
                            </div>
                          </div>
                        </div>
                        {/* End Text input element */}
                        {/* Begin Text input element */}
                        <div className="col-md-6">
                          <div className="quform-element form-group">
                            <label htmlFor="email">
                              Email <span className="quform-required">*</span>
                            </label>
                            <div className="quform-input">
                              <input
                                className="form-control"
                                id="email"
                                type="text"
                                name="email"
                                placeholder="Tu email"
                                onChange={(e) =>
                                  setMensaje({
                                    ...mensaje,
                                    Email: e.target.value,
                                  })
                                }
                                value={mensaje.Email}
                              />
                            </div>
                          </div>
                        </div>
                        {/* End Text input element */}
                        {/* Begin Text input element */}
                        <div className="col-md-6">
                          <div className="quform-element form-group">
                            <label htmlFor="subject">
                              Tema <span className="quform-required">*</span>
                            </label>
                            <div className="quform-input">
                              <input
                                className="form-control"
                                id="subject"
                                type="text"
                                name="subject"
                                placeholder="Tema"
                                onChange={(e) =>
                                  setMensaje({
                                    ...mensaje,
                                    Subject: e.target.value,
                                  })
                                }
                                value={mensaje.Subject}
                              />
                            </div>
                          </div>
                        </div>
                        {/* End Text input element */}
                        {/* Begin Text input element */}
                        <div className="col-md-6">
                          <div className="quform-element form-group">
                            <label htmlFor="phone">Teléfono</label>
                            <div className="quform-input">
                              <input
                                className="form-control"
                                id="phone"
                                type="text"
                                name="phone"
                                placeholder="Número telefónico"
                                onChange={(e) =>
                                  setMensaje({
                                    ...mensaje,
                                    Telefono: e.target.value,
                                  })
                                }
                                value={mensaje.Telefono}
                              />
                            </div>
                          </div>
                        </div>
                        {/* End Text input element */}
                        {/* Begin Textarea element */}
                        <div className="col-md-12">
                          <div className="quform-element form-group">
                            <label htmlFor="message">
                              Mensaje{" "}
                              <span className="quform-required">
                                * (Max. 300 palabras)
                              </span>
                            </label>
                            <div className="quform-input">
                              <textarea
                                className="form-control h-auto"
                                id="message"
                                name="message"
                                rows={3}
                                placeholder="Dejanos tu mensaje aquí"
                                defaultValue={""}
                                onChange={(e) => {
                                  e.target.value.split("").length < 301 &&
                                    setMensaje({
                                      ...mensaje,
                                      MensajeCuerpo: e.target.value,
                                    });
                                }}
                                value={mensaje.MensajeCuerpo}
                              />
                            </div>
                          </div>
                        </div>
                        {/* End Textarea element */}
                        {/* Begin Captcha element */}
                        <div className="col-12">
                          <div className="form-check">
                            <input
                              required=""
                              className="form-check-input"
                              id="contact_gdpr"
                              name="contact_gdpr"
                              type="checkbox"
                              defaultValue={1}
                              onClick={() => {
                                setAceptar(true);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="contact_gdpr"
                            >
                              Acepto términos y condiciones{" "}
                              <a
                                className="text-decoration-none"
                                href="javascript:void(0);"
                                onClick={() => navigate(`/aviso`)}
                              >
                                (Términos y condiciones)
                              </a>
                            </label>
                          </div>
                          <ReCAPTCHA
                            sitekey={ReCAPTCHAKey}
                            onChange={onChange}
                            style={{
                              transform: "scale(0.84)",
                              transformOrigin: "0 0",
                            }}
                          />
                        </div>
                        {/* End Captcha element */}
                        {/* Begin Submit button */}

                        {/* End Submit button */}
                      </div>
                    </div>
                  </form>
                  <div className="col-md-12">
                    <div className="quform-submit-inner">
                      <button
                        className="butn-style3"
                        onClick={() => enviarMensaje()}
                      >
                        <span>Enviar</span>
                      </button>
                    </div>
                    <div className="quform-loading-wrap text-start">
                      <span className="quform-loading" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* MAP
  ================================================== */}
        <div
          className="mb-10"
          id="map"
          style={{
            width: "100%",
            height: 420,
            backgroundColor: "red",
            marginTop: "-4%",
          }}
        >
          {loading ? null : (
            // <GoogleMaps
            //   apiKey={"AIzaSyAkITgblqZ2pdFC9-JBv6T3fZogUc93TY8"}
            //   style={{ width: "100%" }}
            //   zoom={12}
            //   center={{
            //     lat: Number(informacion?.Direccion?.lat),
            //     lng: Number(informacion?.Direccion?.long),
            //   }}
            //   markers={{
            //     lat: Number(informacion?.Direccion?.lat),
            //     lng: Number(informacion?.Direccion?.long),
            //   }} //optional
            // />
            <MapComponent locations={locations} />
          )}
        </div>
      </>
      <div className="main-wrapper contact-us"></div>{" "}
      {/* element wrapper ends */}
    </>
  );
};

export default Contactanos;
