/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import Button from "@mui/material/Button";
import DropZone from "../../DropZone";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import Preloader from "../../utils/Preloader";

/* Servicios */
import UserService from "../../../services/User.Service";
import S3Service from "../../../services/S3.service";

import styles from "../layout/assets/css/core.min.module.css";

const EliminarUsuario = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await UserService.getById(id);
      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        setImagen(imagen.result);
      }
      setUsuario(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUsuario = async () => {
    setLoading(true);
    try {
      const noticiaBorrada = await UserService.remove(id);
      toast.info("Usuario eliminado");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/usuario");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {" "}
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>Eliminar usuario</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li className={`${styles.breadcrumbItem}`}>
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate("/usuario")}
                    >
                      Usuarios
                    </a>
                  </li>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Eliminar
                  </li>
                </ol>
              </nav>
            </header>
            <form className="form-validate">
              <div className={`${styles.row} ${styles.g4}`}>
                <div className={`${styles.col}`}>
                  <div
                    className={`${styles.h5} ${styles.cardHeader} ${styles.borderBottom0} ${styles.fwBold} ${styles.textBgDark} ${styles.textCenter}`}
                  >
                    Usuario
                  </div>
                  {/* Shipping address */}
                  <div className={`${styles.section} ${styles.mb4}`}>
                    <div className={`${styles.cardBody}`}>
                      {file ? (
                        <div>
                          <div className={`${styles.colLg12}`}>
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={URL.createObjectURL(file)}
                                style={{
                                  height: 300,
                                  objectFit: "contain",
                                  width: "-webkit-fill-available",
                                }}
                              />
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  width: "-webkit-fill-available",
                                }}
                              >
                                {file.name}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {!file && usuario.imagen ? (
                        <div className={`${styles.row}`}>
                          <div className={`${styles.colLg12}`}>
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={String(imagen)}
                                style={{
                                  height: 300,
                                  objectFit: "contain",
                                  width: "-webkit-fill-available",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className={`${styles.cardBody}`}>
                        <div className={`${styles.row} ${styles.g3}`}>
                          <div className={`${styles.colSm6}`}>
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                placeholder="Nombre"
                                value={usuario?.nombre}
                                disabled={true}
                              />
                              <label>Nombre</label>
                            </div>
                          </div>
                          <div className={`${styles.colSm6}`}>
                            <div className={`${styles.formFloating}`}>
                              <select
                                className={`${styles.formSelect} ${styles.shadowNone}`}
                                id="shipping__country"
                                name="shipping[country]"
                                aria-label="Country"
                                disabled={true}
                                value={usuario.tipo}
                              >
                                <option disabled="disabled" selected="selected">
                                  Selecciona una
                                </option>
                                <option>Administrador</option>
                                <option>Usuario</option>
                                <option>Empresa</option>
                              </select>

                              <label htmlFor="shipping__last_name">Tipo</label>
                            </div>
                          </div>
                          <div className={`${styles.colSm6}`}>
                            <div className={`${styles.formFloating}`}>
                              <input
                                type="text"
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                disabled={true}
                                value={usuario.correo}
                                placeholder="Correo"
                              />
                              <label htmlFor="shipping__last_name">
                                Correo
                              </label>
                            </div>
                          </div>
                          <div className={`${styles.colSm6}`}>
                            <div className={`${styles.formFloating}`}>
                              <input
                                className={`${styles.formControl} ${styles.shadowNone}`}
                                disabled={true}
                                placeholder="Contraseña"
                                value={usuario.contraseña}
                                type="password"
                              />
                              <label htmlFor="shipping__last_name">
                                Contraseña
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className={`${styles.co12}`}>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => deleteUsuario()}
                >
                  <span className={`${styles.small}`}>Eliminar</span>
                </button>
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default EliminarUsuario;
