import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/*Servicios*/
import S3Service from "../../../services/S3.service";
import NoticiaService from "../../../services/Noticia.service";

/*Componentes*/
import Portada_ItemBlog from "./Portada_ItemBlog";
import FullPageLoading from "../../../utils/FullPageLoading";
import ItemNoticia from "./ItemNoticia";
import ItemBlog from "./ItemBlog";
import ItemBlogBig from "./ItemBlogBig";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Portada_Blog = ({ ultimasNoticias }) => {
  const [noticias, setNoticias] = useState([]);
  const [noticia, setNoticia] = useState([]);
  const [imagen, setImagen] = useState(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    setImagen("");
    window.scrollTo(0, 0);
    getNoticias();
  }, []);

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(3, 0);
      setNoticia(data.data[0]);
      setNoticias(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const indicatorStyles = {
    background: "#5F0F4654",
    width: 15,
    height: 15,
    borderRadius: "50%",
    display: "inline-block",
    margin: "0 8px",
  };

  const indicartorCarousel = (onClickHandler, isSelected, index, label) => {
    if (isSelected) {
      return (
        <li
          style={{ ...indicatorStyles, background: "#5F0F46d4" }}
          aria-label={`Selected: ${label} ${index + 1}`}
          title={`Selected: ${label} ${index + 1}`}
        />
      );
    }
    return (
      <li
        style={indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        title={`${label} ${index + 1}`}
        aria-label={`${label} ${index + 1}`}
      />
    );
  };

  return (
    <>
      <section data-scroll-index="5">
        <div class="container">
          <div
            class="title-style1 text-center wow fadeIn"
            data-wow-delay="100ms"
          >
            <h2>Últimas noticias</h2>
            <p class="mb-0 w-md-70 w-lg-60 w-xl-50 mx-auto">
              Explora nuestro blog y mantente actualizado con información
              relevante para proteger tu futuro y el de tus seres queridos.
            </p>
            <a
              href="javascript:void(0);"
              onClick={() => navigate(`/Blog`)}
              className="w-100"
            >
              Ver todo
            </a>
          </div>
          <div class="row g-xl-5 mt-n1-6">
            {noticias.map((record, index) => {
              return <Portada_ItemBlog key={index} data={record} />;
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Portada_Blog;
