import React, { useEffect, useState, useContext } from "react";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import WOW from "wowjs";

//servicios
import ServicioService from "../../../services/Servicio.service";

import { AuthContext } from "../../../context/AuthContext";

//componentes
import Portada_ServicioItem from "./Portada_ServicioItem";

const Portada_Servicios = ({ nuestrosPaquetes }) => {
  const [paquetes, setPaquetes] = useState([]);
  const [tipo, setTipo] = useState(0);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
    const wow = new WOW.WOW();
    wow.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPaquetes = async () => {
    try {
      const data = await ServicioService.mostrarInicio(4);
      setPaquetes(data.data);
      if (data.data.total < 6) {
        let limite = 6 - data.total;
        const dataDESC = await ServicioService.list(limite, 0);
        setPaquetes({ ...paquetes, dataDESC });
      }
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* SERVICIOS 2
  ================================================== */}
      <section
        className="pb-0 position-relative feature-style1"
        style={{
          marginTop: "-100px",
          marginBottom: "70px",
        }}
      >
        <div className="container">
          <div
            className="title-style2 text-center mb-2-9 mb-lg-6 wow fadeIn"
            data-wow-delay="100ms"
          >
            <span style={{ color: "#5F0F46" }}>Nuestros servicios</span>
            <h2 className="h1 mb-0">Conoce nuestros servicios</h2>
          </div>
          <div class="row mt-n1-6" style={{ justifyContent: "center" }}>
            {paquetes.map((record, index) => {
              return (
                <Portada_ServicioItem
                  key={index}
                  id={record.id}
                  titulo={record.titulo}
                  precioU={record.paquetesUsuario}
                  precioE={record.paquetesEmpresa}
                  descuento={record.descuento}
                  descripcion={record.descripcion}
                  incluye={record.incluye}
                  subtitulo={record.subtitulo}
                  imagenes={record.imagenes}
                  fecha={record.updateAt}
                  tipo={tipo}
                />
              );
            })}
          </div>
        </div>
        <div className="circle-md right-10 top-15 border-dark-color d-none d-md-block" />
        <div
          className="bg-img bg-stripes ani-left-right d-none d-md-block"
          data-background="img/content/bg-stripes.png"
        />
      </section>
    </>
  );
};

export default Portada_Servicios;
