import httpClient from "./HttpClient";
const prefix = "/servicio";

export default class ServicioService {
  static async create(servicio) {
    return (await httpClient.post(`${prefix}/`, servicio)).data;
  }

  static async update(servicio) {
    return (await httpClient.put(`${prefix}/${servicio.id}`, servicio)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async getAllTitulo() {
    return (await httpClient.get(`${prefix}-Titulo/`)).data;
  }

  static async getTotal() {
    return (await httpClient.get(`${prefix}-total/`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }

  static async mejoresOfertas(limit) {
    return (await httpClient.get(`${prefix}-oferta/${limit}`)).data;
  }

  static async mostrarInicio(limit) {
    return (await httpClient.get(`${prefix}-mostrarInicio/${limit}`)).data;
  }

  static async filtroCiudad(limit,filtros) {
    return (await httpClient.post(`${prefix}-filtroCiudad/${limit}`, filtros)).data;
  }

  static async filtroFecha(limit,filtro) {
    return (await httpClient.post(`${prefix}-filtroFecha/${limit}`, filtro)).data;
  }

  static async filtroCiudadFecha(limit,filtro) {
    return (await httpClient.post(`${prefix}-filtroCiudadFecha/${limit}`, filtro)).data;
  }

}
