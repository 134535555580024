import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//servicio
import S3Service from "../../../services/S3.service";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Carousel from "react-bootstrap/Carousel";
import escudologo from "../assets/img/escudologo.png";

const ServicioItem = ({
  id,
  titulo,
  precioU,
  precioE,
  descuento,
  imagenes,
  fecha,
  tipo,
  descripcion,
  incluye,
  subtitulo,
}) => {
  const navegar = useNavigate();
  let precioFinal = 0;
  const [imag, setImag] = useState([]);
  const [precio, setPrecio] = useState(0);
  let precios = 0;
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  if (descuento > 0) {
    const descuentoProps = descuento;
    const precioOriginal = precio;
    precioFinal = precioOriginal - 100 / (precioOriginal / descuentoProps);
  }

  if (tipo == "Empresa") {
    if (precioE == null) {
      precios = 0;
    } else {
      for (const pre of precioE) {
        if (pre.props.tipo == "Adulto") {
          precios = pre.props.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  } else {
    if (precioU == null) {
      precios = 0;
    } else {
      for (const pre of precioU) {
        if (pre.props.tipo == "Adulto") {
          precios = pre.props.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [imagenesItem, setImagenes] = useState([]);

  useEffect(() => {
    if (imagenes.length > 0) {
      getImagen();
    }
  }, []);

  const getImagen = async () => {
    try {
      for (const record of imagenes) {
        const imagen = await S3Service.get(record);
        setImagenes((imagenes) => [...imagenes, imagen.result]);
        console.log("------------" + imagen.result);
      }
      const result = await S3Service.get(imagenes[0]);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imagen);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const cortarTexto = (entry) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(entry, "text/html");
    return doc.body.innerText;
  };

  return (
    <>
      <div
        className="col-md-6 col-lg-4 mt-1-6 wow fadeIn"
        data-wow-delay="200ms"
      >
        <div className="card card-style1 border-color-light-black h-100">
          <div className="card-body text-center px-4 px-xl-5 py-5 py-md-6">
            <div className="hover-bg">
              <img
                onClick={() => navegar(`/Servicios/${id}`)}
                className="media-icon-large rounded shadow-sm me-4"
                style={{
                  height: "500px",
                  width: "500px",
                  borderColor: "white",
                  borderWidth: "5px",
                }}
                src={imag}
              />
            </div>
            <div className="service-icon text-center display-20">
              <img
                src={escudologo}
                alt="..."
                class="ti-user display-25 display-sm-22 display-lg-20 position-relative"
                style={{ width: "75px" }}
              />
            </div>
            <h3 className="h5 mb-3 mt-2 position-relative">
              <a
                href="javascript:void(0);"
                onClick={() => navegar(`/Servicios/${id}`)}
              >
                {titulo}
              </a>
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: String(cortarTexto(descripcion))
                  .slice(0, 130)
                  .concat("..."),
              }}
              className="mb-0 position-relative"
              style={{
                height: "100px",
              }}
            ></p>
          </div>
        </div>
      </div>
      {/* Media 
      <div className="col-md-6 col-lg-4">
        <div className="media mb-6">
            <img
              onClick={() => handleOpen()}
              className="media-icon-large bg-danger rounded shadow-sm me-4"
              style={{
                height: "80px",
                width: "80px",
                borderColor: "white",
                borderWidth: "5px",
              }}
              src={imag}
            />
          <div className="media-body">
            <h3 className="text-danger text-truncate">{titulo}</h3>
            <p>{String(descripcion).slice(0, 15)}</p>
          </div>
        </div>
      </div>*/}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: "center", overflowY: "auto" }}
      >
        <div>
          <div class="container pt-6" stylr>
            <div
              className="container"
              style={{ backgroundColor: "white", borderRadius: "10px" }}
            >
              <div>
                <div className="row">
                  <h1
                    className="display-5 fw-bold"
                    style={{ width: "90%", marginTop: "30px" }}
                  >
                    {titulo}
                  </h1>
                  <button
                    style={{ width: "10%", padding: "-30px" }}
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handleClose()}
                  >
                    <span aria-hidden="true" style={{ fontSize: "50px" }}>
                      &times;
                    </span>
                  </button>
                </div>
              </div>
              <div className="section">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-8 mb-5">
                      <div
                        className="swiper-container swiper-btn-group swiper-btn-group-end text-white p-0 overflow-hidden"
                        data-swiper='{
									"slidesPerView": 1,
									"spaceBetween": 0,
									"autoplay": { "delay" : 4500, "disableOnInteraction": false },
									"loop": true,
									"effect": "fade"
								}'
                        style={{ height: 500 }}
                      >
                        <div className="swiper-wrapper h-100">
                          <Carousel
                            slide={false}
                            nextLabel={""}
                            prevLabel={""}
                            style={{ height: "600px" }}
                          >
                            {imagenes.map((record, index) => {
                              return (
                                <Carousel.Item>
                                  <img
                                    className="d-block w-100"
                                    src={imagenesItem[index * 2]}
                                    alt="imagen"
                                    style={{
                                      filter: "brightness(70%)",
                                      height: "500px",
                                    }}
                                  />
                                  {console.log("slide:   " + record)}
                                </Carousel.Item>
                              );
                            })}
                          </Carousel>
                        </div>
                        {/* Add Arrows */}
                        <div className="swiper-button-next swiper-button-white" />
                        <div className="swiper-button-prev swiper-button-white" />
                      </div>
                      {/* /SWIPER */}
                    </div>
                    <div className="col-12 col-lg-4 mb-5">
                      <div className="p-4 p-0-xs shadow-md shadow-none-xs rounded">
                        <h2 className="h4 fw-bold m-0">{subtitulo}</h2>
                        <h6 className="fw-normal mb-4">Tiempo: 10 min</h6>
                        <p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: descripcion,
                            }}
                            style={{ textAlign: "justify" }}
                          ></p>
                        </p>
                        <div className="border-start border-primary border-3 py-1 px-3 my-5">
                          <h2 className="mb-0 h5">Precio</h2>
                          <p className="mb-0 text-gray-500">$0.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ServicioItem;
