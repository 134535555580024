import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

const ItemBlog = ({
  id,
  titulo,
  idAutor,
  fecha,
  categoria,
  imagenes,
  cuerpo,
}) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  const [fechaC, setFechaC] = useState("");
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const getTiempo = async () => {
    try {
      //Tiempo
      const date = new Date(fecha);

      // Obtener los componentes de fecha y hora
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const day = ("0" + date.getDate()).slice(-2);
      // Formatear el texto de salida
      setFechaC(`${day} ${monthNames[monthIndex]} ${year}`);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getImagen();
    getAutor();
    getTiempo();
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes[0]);
      setImag(result.result);
      imagen = result.result;
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const getAutor = async () => {
    try {
      const result = await UserService.getById(id);
      setAutor(result.nombre);
      autorNombre = result.nombre;
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div className="media mb-4">
        <a href="javascript:void(0);" onClick={() => navigate(`/blog/${id}`)}>
          <img
            className="rounded"
            src={imag}
            alt="blog-sm-img5.png"
            style={{ width: "100px", height: "60px", objectFit: "cover" }}
          />
        </a>
        <div className="media-body ms-3">
          <h4 className="h6">
            <a
              href="javascript:void(0);"
              onClick={() => navigate(`/blog/${id}`)}
              style={{ overflowWrap: "anywhere" }}
            >
              {titulo}
            </a>
          </h4>
          <p className="mb-0 small">{fechaC} </p>
        </div>
      </div>
    </>
  );
};

export default ItemBlog;
