import httpClient from "./HttpClient";
const prefix = "/testimonios";

export default class TestimoniosService {
  static async create(paquete) {
    return (await httpClient.post(`${prefix}/`, paquete)).data;
  }

  static async update(testimonios) {
    return (await httpClient.put(`${prefix}/${testimonios.id}`, testimonios)).data;
  }


  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async getTotal() {
    return (await httpClient.get(`${prefix}-total/`)).data;
  }
  
}
