import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserService from "../../../services/User.Service";
import FullPageLoading from "../../FullPageLoading";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DropZone from "../../utils/DropZoneDos";
import ReCAPTCHA from "react-google-recaptcha";
import S3Service from "../../../services/S3.service";
import AOS from "aos";
import "aos/dist/aos.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import background from "../assets/img/backLogin.jpg";
import topBg from "../assets/img/footer-map.png";

//imagenes

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px",
  boxShadow: 24,
  p: 4,
};

const IniciarSesion = () => {
  const { login } = useContext(AuthContext);
  const [datos, setDatos] = useState({ correo: "", contraseña: "" });
  const [registro, setRegistro] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [aceptar, setAceptar] = React.useState(false);
  const matches = useMediaQuery("(min-width:900px)");

  const handleClose = () => {
    AOS.init();
    setOpen(false);
    setFile("");
  };
  const [file, setFile] = useState(undefined);
  const [valido, setValido] = useState(true);

  const iniciarSesion = () => {
    if (datos) {
      const data = {
        contraseña: datos.contraseña,
        correo: datos.correo,
      };
      try {
        setLoading(true);
        const promise = UserService.login(data).then((response) => {
          if (response === "Correo o contraseña incorrectas") {
            toast.error("Correo o contraseña incorrectas");
          } else {
            login(response.user, response.token);
            toast.success("Inicio de sesión exitoso");
            navigate("/");
            window.location.reload();
          }
        });
        toast.promise(promise, {
          pending: "Espere por favor..",
          error: "Revise sus datos de acceso",
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const registrarse = async () => {
    if (!registro.nombre) {
      toast.info("Error, tienes que agregar un titulo");
    } else if (!registro.correo) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!file) {
      toast.info("Error, tienes que colocar una imagen");
    } else if (!registro.contraseña) {
      toast.info("Error, tienes que colocar una contraseña");
    } else if (!registro.contraseña2) {
      toast.info("Error, tienes que rellenar todos los campos");
    } else if (registro.contraseña2 != registro.contraseña) {
      toast.info("Error, las contraseñas no coinciden");
    } else {
      setLoading(true);
      try {
        const registroSubir = { ...registro, nombre: registro.nombre };

        if (file) {
          const uploadPhoto = await S3Service.upload(file);
          registroSubir.imagen = uploadPhoto.result.data;
          await UserService.create(registroSubir);
        } else {
          await UserService.create(registroSubir);
        }

        toast.success("Te has registrado con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        navigate("/blog");
      }
    }
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setValido(false);
  }

  return (
    <>
      <>
        {/* PAGE TITLE
  ================================================== */}
        {/* <section
          className="page-title-section bg-img cover-background left-overlay-dark"
          data-overlay-dark={6}
          data-background={topBg}
          style={{
            backgroundImage: `url(${topBg})`,
            backgroundColor: "#40383c",
          }}
        >
          <div className="container position-unset">
            <div className="page-title mx-1-6 mx-lg-2-0 mx-xl-2-6 mx-xxl-2-9">
              <div className="row">
                <div className="col-md-12">
                  <h1>Iniciar Sesión</h1>
                </div>
                <div className="col-md-12">
                  <ul className="ps-0">
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={() => navigate("/")}
                      >
                        Inicio
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Iniciar sesión</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </>

      <>
        {/* CONTACT FORM
  ================================================== */}
        <section
          data-overlay-dark={7}
          data-background={background}
          style={
            matches
              ? {
                  width: "100%",
                  backgroundImage: `url(${background})`,
                  display: "flex",
                  padding: "10rem",
                  paddingTop: "20rem",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }
              : { width: "100%", backgroundImage: `url(${background})` }
          }
        >
          <div
            className="container"
            style={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "0.25rem",
              padding: "1rem",
              maxWidth: "30rem",
            }}
          >
            <div
              style={
                matches
                  ? {
                      width: "100%%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }
                  : { width: "100%" }
              }
            >
              <div style={matches ? { width: "100%%" } : { width: "100%" }}>
                {/* <h2 className="h3 mb-4">Iniciar sesión</h2> */}
                <div className="container position-unset">
                  <div className="page-title mx-1-6 mx-lg-2-0 mx-xl-2-6 mx-xxl-2-9">
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{ marginBottom: "1rem" }}
                      >
                        <h1>Iniciar Sesión</h1>
                      </div>
                      <div className="col-md-12">
                        <ul className="ps-0"></ul>
                      </div>
                    </div>
                  </div>
                </div>
                <form className="quform">
                  <div className="quform-elements">
                    <div className="row">
                      {/* Begin Text input element */}
                      <div>
                        <div className="quform-element form-group">
                          <label htmlFor="name">
                            Correo electrónico{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              type="text"
                              className="form-control border"
                              placeholder="Correo electrónico"
                              required=""
                              onChange={(e) =>
                                setDatos({
                                  ...datos,
                                  correo: e.target.value,
                                })
                              }
                              onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                  iniciarSesion();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* End Text input element */}
                      {/* Begin Text input element */}
                      <div>
                        <div className="quform-element form-group">
                          <label htmlFor="email">
                            Contraseña{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              type="password"
                              className="form-control border"
                              placeholder="Contraseña"
                              required=""
                              onChange={(e) =>
                                setDatos({
                                  ...datos,
                                  contraseña: e.target.value,
                                })
                              }
                              onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                  iniciarSesion();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* End Text input element */}
                    </div>
                  </div>
                </form>
                <div className="col-md-12">
                  <div className="quform-submit-inner">
                    <button
                      className="butn-style3"
                      onClick={() => iniciarSesion()}
                    >
                      Iniciar sesión
                    </button>
                  </div>
                  <div className="quform-loading-wrap text-start">
                    <span className="quform-loading" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container"></div>
        </section>
      </>
    </>
  );
};

export default IniciarSesion;
