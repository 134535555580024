import Box from "@mui/material/Box";
import { useState } from "react";
import Tab from "@mui/material/Tab";
import { Tabs } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function LabTabs({ tabs, imagenesTab }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Tabs value={value} onChange={handleChange}>
          {tabs?.map((tab, index) => (
            <Tab
              label={tab.titulo}
              {...a11yProps(index)}
              style={{ color: "#5F0F46" }}
            />
          ))}
        </Tabs>
      </Box>

      {tabs?.map((tab, index) => (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{ minHeight: "30rem", backgroundColor: "#f5f6f9" }}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>
                <div className="row align-items-center">
                  <div className="col-lg-6 order-lg-1 order-2">
                    <div className="pe-0 pe-lg-3 pe-xl-4">
                      <h4 className="mb-3">{tab.titulo}</h4>
                      <div>{tab.descripcion}</div>
                      {/* <a
                        href="javascritp:void(0);"
                        className="butn-style3 medium"
                      >
                        Read More
                      </a> */}
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-2 order-1 mb-4 mb-lg-0">
                    <div
                      className="box-shadow-large"
                      style={{
                        height: "25rem",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {imagenesTab[index] ? (
                        <img
                          src={imagenesTab[index]}
                          alt="..."
                          style={{
                            maxHeight: "25rem",
                            backgroundColor: "white",
                            maxWidth: "100%",
                            padding: "2rem",
                          }}
                        />
                      ) : (
                        <img src="img/about/history1.jpg" alt="..." />
                      )}
                    </div>
                  </div>
                </div>
              </Typography>
            </Box>
          )}
        </div>
      ))}
    </>
  );
}
