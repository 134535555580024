import React, { useEffect, useState } from "react";
/*Servicios*/
import S3Service from "../../../services/S3.service";
import CarruselService from "../../../services/Carrusel.service";
/*Componentes*/
import FullPageLoading from "../../../utils/FullPageLoading";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import AOS from "aos";
import "aos/dist/aos.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const Slide = () => {
  const navigate = useNavigate();
  const [carrusels, setCarrusels] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [backImage, setBackImage] = useState("");
  const [activeBack, setActiveBack] = useState(0);
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    getData();
    AOS.init();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeBack >= imagenes.length - 1) {
        setBackImage(imagenes[0]);
        setActiveBack((prev) => 0);
      } else {
        setBackImage(imagenes[activeBack + 1]);
        setActiveBack((prev) => prev + 1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [backImage]);

  const getData = async () => {
    setLoading(true);
    setImagenes([]);
    try {
      const data = await CarruselService.getActive();
      setCarrusels(data.data);
      setActiveBack(data.data.length - 1);
      for (const record of data.data) {
        const imagen = await S3Service.get(record.imagen);
        setImagenes((imagenes) => [...imagenes, imagen.result]);
        setBackImage(imagen.result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const buton = (url, boton) => {
    return (
      <>
        <a
          target="_blank"
          href={`${url}`}
          className="butn-style3 small align-middle mb-3 mb-sm-0"
        >
          {boton}
        </a>
      </>
    );
  };

  const contenido = (carousel) => {
    return (
      <>
        <div class="container d-flex flex-column pt-5 pb-2 py-sm-8 py-md-0">
          <div class="row align-items-center min-vh-100">
            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-lg-9 col-xl-8 col-xxl-7">
                  <span class="text-primary fw-bold d-block mb-2">
                    {carousel?.titulo}
                  </span>
                  <h1 class="text-white display-16 display-md-9 display-lg-7 display-xl-4 mb-1-6 font-weight-700 text-shadow">
                    {carousel?.subtitulo}
                  </h1>
                  <div>
                    {carousel?.url ? buton(carousel.url, carousel.boton) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            content: "",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundImage: `url(${backImage})`,
            // filter: "grayscale(100%)",
            opacity: "1",
            backgroundSize: "cover",
            transition: "all .3s ease",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
          }}
        ></div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <header
            class="header full-screen bg-img cover-background"
            style={{
              position: "relative !important",
              backgroundSize: "cover !important",
              overflow: "hidden !important",
              backgroundPosition: "center !important",
              backgroundRepeat: " no-repeat !important",
              zIndex: "auto",
            }}
            data-overlay-dark="6"
            data-scroll-index="0"
          >
            {contenido(carrusels[activeBack])}
          </header>
        </>
      )}
    </>
  );
};

export default Slide;
