/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

/* Servicios */
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

import styles from "../layout/assets/css/core.min.module.css";

const TablaCategoriaBlog = () => {
  const [categorias, setCategorias] = useState({});
  const [categoria, setCategoria] = useState({ tipo: "" });
  const [categoriatipo, setCategoriaTipo] = useState({});
  const [estado, setEstado] = useState("Nuevo");

  const [activo, setActivo] = useState(true);

  const [selecionColor, setSelecionColor] = useState(false);

  const [categoriaEdit, setCategoriaEdit] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);
  const [openEliminar, setOpenEliminar] = useState(false);
  const [editandoCategoria, setEditandoCategoria] = useState(null);
  const [update, setUpdate] = useState(false);

  const onUpdate = () => {
    setUpdate(!update);
  };
  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleEditandoCategoria = (value) => {
    console.log(value);
    setEditandoCategoria(value);
  };
  const cancelEditandoCategoria = () => {
    setCategoria({ tipo: "" });
    setEditandoCategoria(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEliminar = () => {
    setOpenEliminar(true);
  };
  const handleCloseEliminar = () => {
    setOpenEliminar(false);
  };

  useEffect(() => {
    getCategorias();
  }, [page, limit, update]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getCategorias = async () => {
    try {
      const data = await CategoriaBlogService.getAll();
      console.log("get");
      setCategorias(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveCategoria = async () => {
    if (!categoria.tipo) {
      toast.info("Error, tienes que colocar una categoría");
    } else {
      setLoading(true);
      try {
        const datosCategoria = categoria;
        await CategoriaBlogService.create(datosCategoria);
        console.log("save");
        onUpdate();
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        categoria.tipo = "";
      }
    }
  };

  const activeBoton = (record) => {
    return (
      <a
        href="javascript:void(0)"
        className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
        style={{ width: "15%" }}
        onClick={() => {
          setCategoriaEdit(record);
          updateCategoria(record.id);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-check2"
          viewBox="0 0 16 16"
        >
          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
        </svg>
      </a>
    );
  };

  const updateCategoria = async (newCategoria) => {
    if (!newCategoria.tipo) {
      toast.info("Error, tienes que colocar una categoría");
    } else {
      setLoading(true);
      try {
        const datosCategoria = newCategoria;
        await CategoriaBlogService.update(datosCategoria);
        onUpdate();
        setEditandoCategoria(null);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteCategoria = async (oldCategoria) => {
    setLoading(true);
    try {
      const carruselBorrado = await CategoriaBlogService.remove(
        oldCategoria.id
      );
      toast.info("Categoria eliminada");
      onUpdate();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setBackgroundColor = (id) => {
    try {
      const linea = document.getElementById(`key_${id}`);
      linea.style.backgroundColor = "#abbcd5";
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>Categorias</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Hay {categoria.length} actualmente.
                  </li>
                </ol>
              </nav>
            </header>

            {/* user list */}
            <div className={`${styles.row} ${styles.g4}`}>
              <div className={`${styles.col}`}>
                <div className={`${styles.section} ${styles.mb4}`}>
                  <div className={`${styles.cardBody}`}>
                    <div className={`${styles.row} ${styles.g3}`}>
                      <div
                        className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                      >
                        <form
                          className={`${styles.positionRelative} ${styles.dFlex} ${styles.alignItemsCenter}`}
                          method="get"
                          action="#"
                        ></form>
                      </div>
                    </div>
                    <div className={`${styles.cardHeader} ${styles.p4}`}>
                      <div className={`${styles.row} ${styles.g3}`}>
                        <div
                          className={`${styles.order2} ${styles.orderMd1} ${styles.col}`}
                        >
                          <div
                            className={`${styles.positionRelative} ${styles.dFlex} ${styles.alignItemsCenter}`}
                          >
                            <div
                              className={`${styles.dropdown} ${styles.w100}`}
                            >
                              {!editandoCategoria?.id ? (
                                <input
                                  type="text"
                                  className={`${styles.formControl} ${styles.shadowNone} ${styles.formControlSm} ${styles.border0} ${styles.ps5} ${styles.bgGray100}`}
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="outside"
                                  placeholder="Agregar nuevo"
                                  value={categoria.tipo}
                                  onChange={(e) => {
                                    setCategoria({
                                      ...categoria,
                                      tipo: e.target.value,
                                    });
                                  }}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className={`${styles.formControl} ${styles.shadowNone} ${styles.formControlSm} ${styles.border0} ${styles.ps5} ${styles.bgGray100}`}
                                  styles={{
                                    backgroundColor: "#5F0F4687 !important",
                                  }}
                                  placeholder="Editando"
                                  value={editandoCategoria.tipo}
                                  onChange={(e) => {
                                    setEditandoCategoria({
                                      ...editandoCategoria,
                                      tipo: e.target.value,
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {/* options */}
                        <div
                          className={`${styles.order1} ${styles.orderMd2} ${styles.colMdAuto}`}
                        >
                          {!editandoCategoria?.id ? (
                            <a
                              href="javascript:void(0)"
                              onClick={() => saveCategoria()}
                              className={`${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                            >
                              <svg
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1={12} y1={5} x2={12} y2={19} />
                                <line x1={5} y1={12} x2={19} y2={12} />
                              </svg>
                              <span>{estado}</span>
                            </a>
                          ) : (
                            <>
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  updateCategoria(editandoCategoria)
                                }
                                className={`${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                              >
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </a>
                              <a
                                href="javascript:void(0)"
                                onClick={() => cancelEditandoCategoria()}
                                className={`${styles.btn} ${styles.btnSm} ${styles.btnPrimary}`}
                              >
                                <i class="fa fa-ban" aria-hidden="true"></i>
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.cardBody} ${styles.pt1}`}>
                      {/* item list */}
                      <div
                        className={`${styles.tableResponsiveMd}`}
                        style={{ width: "100%", overflow: "auto" }}
                      >
                        <table
                          className={`${styles.table} ${styles.tableAlignMiddle}`}
                          style={{
                            overflowX: "auto",
                            width: "300px !important",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                className={`${styles.small} ${styles.textMuted}`}
                                style={{ width: "600px" }}
                              >
                                TIPO
                              </th>
                              <th
                                className={`${styles.small} ${styles.textMuted}`}
                                style={{ width: 30 }}
                              ></th>
                              <th
                                className={`${styles.small} ${styles.textMuted}`}
                                style={{ width: 200, marginLeft: "20px" }}
                              >
                                <a
                                  href="javascript:void(0)"
                                  className={`${styles.dFlex} ${styles.linkMuted}`}
                                  title="order by inventory"
                                  aria-label="order by inventory"
                                >
                                  <span className={`${styles.ms2}`}>
                                    <i
                                      className="fa fa-calendar"
                                      aria-hidden="true"
                                    />{" "}
                                    CREACIÓN
                                  </span>
                                </a>
                              </th>
                              <th
                                className={`${styles.small} ${styles.textMuted}`}
                                style={{ width: 250 }}
                              >
                                <a
                                  href="javascript:void(0)"
                                  className={`${styles.dFlex} ${styles.linkMuted}`}
                                  title="order by inventory"
                                  aria-label="order by inventory"
                                >
                                  <span className={`${styles.ms2}`}>
                                    <i
                                      className="fa fa-calendar"
                                      aria-hidden="true"
                                    />{" "}
                                    EDICIÓN
                                  </span>
                                </a>
                              </th>
                              <th
                                className={`${styles.small} ${styles.textMuted}`}
                                style={{ width: 20 }}
                              >
                                <a
                                  href="javascript:void(0)"
                                  className={`${styles.dFlex} ${styles.linkMuted}`}
                                  title="order by price"
                                  aria-label="order by inventory"
                                >
                                  <span className={`${styles.ms2}`}></span>
                                </a>
                              </th>
                              <th
                                className={`${styles.small} ${styles.textMuted}`}
                                style={{ width: 20 }}
                              >
                                <a
                                  href="javascript:void(0)"
                                  className={`${styles.dFlex} ${styles.linkMuted}`}
                                  title="order by price"
                                  aria-label="order by inventory"
                                >
                                  <span className={`${styles.ms2}`}></span>
                                </a>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {categorias.map((record, index) => {
                              const regex = /<[^>]'>/gim;
                              return (
                                <tr
                                  key={`key_${record.id}`}
                                  id={`key_${record.id}`}
                                >
                                  <td
                                    className={`${styles.linkNormal} ${styles.fwMedium}`}
                                  >
                                    <div
                                      className={`${styles.row}`}
                                      style={{ width: "100%" }}
                                    >
                                      <input
                                        type="text"
                                        className={`${styles.formControl} ${styles.shadowNone} ${styles.formControlSm} ${styles.border0} ${styles.ps5} ${styles.bgGray100}`}
                                        placeholder="Agregar nuevo"
                                        value={record.tipo}
                                        disabled={activo}
                                        style={{
                                          width: "80%",
                                          minWidth: "18rem",
                                          marginLeft: "10px",
                                        }}
                                      />
                                      {/* <a
                                        href="javascript:void(0)"
                                        className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                        style={{ width: "15%" }}
                                        id={`keyboton_${record.id}`}
                                        onClick={() => {
                                          setCategoriaEdit(record);
                                          updateCategoria(record.id);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-check2"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                      </a> */}
                                    </div>
                                  </td>
                                  <td></td>
                                  <td>
                                    <i
                                      className="fa fa-calendar fa-1"
                                      aria-hidden="true"
                                    />{" "}
                                    {String(record.createdAt).slice(0, 10)}
                                    <br />{" "}
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    />{" "}
                                    {String(record.createdAt).slice(11, 16)}
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-calendar fa-1"
                                      aria-hidden="true"
                                    />{" "}
                                    {String(record.updateAt).slice(0, 10)}
                                    <br />{" "}
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    />{" "}
                                    {String(record.updateAt).slice(11, 16)}
                                  </td>
                                  <td>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        handleEditandoCategoria(record)
                                      }
                                      className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                      </svg>
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => deleteCategoria(record)}
                                      className={`${styles.btn} ${styles.btnSm} ${styles.linkNormal}`}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                      </svg>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {/* pagination, selected items */}
                      <div className={`${styles.row}`}>
                        <div
                          className={`${styles.col} ${styles.py3} ${styles.textCenter} ${styles.textMdStart}`}
                        ></div>
                        <div className={`${styles.colMdAuto} ${styles.py3}`}>
                          {/* pagination */}
                          <nav
                            className={`${styles.w100} ${styles.textCenter}`}
                            aria-label="Pagination"
                          >
                            {/* pagination : desktop */}
                            <nav
                              className={`${styles.textCenter} ${styles.floatMdEnd} ${styles.mt3} ${styles.mtMd0} ${styles.dNone} ${styles.dMdFlex}`}
                              aria-label="Pagination"
                            >
                              <TablePagination
                                style={{ color: "var(--color-dark-variant)" }}
                                component="div"
                                classes="recent-orders"
                                labelRowsPerPage="Items por pagina"
                                count={total}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={limit}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </nav>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          {/** ELIMINAR */}
          <Modal
            open={openEliminar}
            onClose={handleCloseEliminar}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Eliminar
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="row g-3 mb-3">
                  <div className="col-lg-6">
                    <p style={{ color: "#ff7782" }}>{categoriaEdit.tipo}</p>
                  </div>
                  <div className="col-lg-2">
                    <Button
                      variant="outlined"
                      color="error"
                      form="form1"
                      onClick={() => deleteCategoria()}
                      style={{ marginLeft: "150%" }}
                    >
                      Eliminar
                    </Button>
                  </div>
                </div>
              </Typography>
            </Box>
          </Modal>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaCategoriaBlog;
