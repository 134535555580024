import React, { useEffect, useState } from "react";
import Titulo from "./Titulo";
import Pagination from "./Pagination";
import ItemBlog from "./ItemBlog";
import BlogCard from "./BlogCard";

import CategoriaBlogService from "../../../services/CategoriaBlog.service";
import NoticiaService from "../../../services/Blog.service";

const EntradasRecientes = () => {
  const [noticias, setNoticias] = useState([]);
  const [tabUno, setTabUno] = useState("active");
  const [tabDos, setTabDos] = useState("");
  const [tabUnoCuerpo, setTabUnoCuerpo] = useState("show active");
  const [tabDosCuerpo, setTabDosCuerpo] = useState("");
  useEffect(() => {
    getNoticias();
  }, []);

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      setNoticias(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/*PESTAÑAS*/}
      <div className="widget mb-1-9 p-4 wow fadeIn" data-wow-delay="400ms">
        <h3 className="mb-1-6 h5">Entradas recientes</h3>
        {noticias.map((record, index) => {
          return (
            <ItemBlog
              id={record.id}
              titulo={record.titulo}
              idAutor={record.usuarioId}
              fecha={record.createdAt}
              categoria={record.categoria}
              imagenes={record.imagen}
              cuerpo={record.cuerpoNoticia}
            />
          );
        })}
      </div>
    </>
  );
};

export default EntradasRecientes;
