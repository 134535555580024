import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import FooterService from "../../../services/Footer.service";
import MenuIcon from "@mui/icons-material/Menu";
import SideMenu from "./SideMenu";

//imagenes
import logo from "../assets/img/SORIA_20_horizontal-removebg.png";

const HeaderPublicLayout = () => {
  //#region Variables
  const [footer, setFooter] = useState({ undefined });
  const matches = useMediaQuery("(min-width:992px)");

  const [scrollDirection, setScrollDirection] = useState(null);
  const [active, setActive] = useState("inicio");
  const navigate = useNavigate();
  const { currentUser, logout } = useContext(AuthContext);
  const [usuario, setUsuario] = useState(undefined);
  const [status, setStatus] = useState(false);
  //#endregion

  const getFooter = async () => {
    try {
      const foot = await FooterService.getAll();
      setFooter(foot[0]);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleStatus = () => {
    setStatus(!status);
  };

  const salir = () => {
    logout();
    navigate("/");
  };

  useEffect(() => {
    getFooter();
  }, []);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    let url = window.location.href.split("/");
    if (currentUser == undefined) {
    } else {
      setUsuario(currentUser);
    }
    console.log("current user " + usuario);
    if (url.includes("login")) {
      setActive("login");
    } else if (url.includes("inicio")) {
      setActive("inicio");
    } else if (url.includes("nosotros")) {
      setActive("nosotros");
    } else if (url.includes("Servicios")) {
      setActive("servicios");
    } else if (url.includes("Blog") || url.includes("blog")) {
      setActive("blog");
    } else if (url.includes("contactanos")) {
      setActive("contactanos");
    } else {
      setActive("inicio");
    }

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
      if (direction === "down") {
        let element;
        element = document.querySelector("#searchLupa");
        // element.style.display = "none";
      }
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener

    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  const displayUsuario = () => {
    let element;
    element = document.querySelector("#usuarioInformation");
    if (element.style.display === "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };

  const liLogin = () => {
    if (usuario == undefined) {
      return (
        <li className="nav-item dropdown">
          <a
            href="#"
            id="mainNavDocumentation"
            className="nav-link dropdown-toggle nav-link-caret-hide"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span>Iniciar sesión</span>
          </a>
          <div
            aria-labelledby="mainNavDocumentation"
            className="dropdown-menu dropdown-menu-clean dropdown-menu-hover end-0 w-300 dropdown-fadeinup"
          >
            <a href="/iniciarsesion" className="dropdown-item py-4 d-flex">
              <span className="flex-none">
                <i className="fa fa-sign-in mr-0 fa-4x" aria-hidden="true" />
              </span>
              <span className="ps-3">
                <span className="d-block mb-1">Incia sesión</span>
                <small className="d-block text-muted text-wrap">
                  Identificate con tu cuenta existente
                </small>
              </span>
            </a>
            <a
              href="/registrarse "
              target="_blank"
              rel="noopener"
              className="dropdown-item py-4 d-flex border-top"
            >
              <span className="flex-none">
                <i className="fa fa-user-plus mr-0 fa-4x" aria-hidden="true" />
              </span>
              <span className="ps-3">
                <span className="d-block mb-1">Registrarse</span>
                <small className="d-block text-muted text-wrap">
                  Crea una nueva cuenta
                </small>
              </span>
            </a>
          </div>
        </li>
      );
    }
  };

  const botonUsuario = () => {
    if (currentUser != undefined) {
      return (
        <>
          <li className="nav-item dropdown">
            <a
              className="btn btn-primary nav-link dropdown-toggle nav-link-caret-hide "
              data-bs-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                height: "50%",
                marginBottom: "5%",
                borderTopRightRadius: "0%",
                borderTopLeftRadius: "0%",
              }}
            >
              <i className="fa fa-user mr-0" aria-hidden="true" />
              {currentUser.nombre}
            </a>
            <div
              aria-labelledby="mainNavDocumentation"
              className="dropdown-menu dropdown-menu-clean dropdown-menu-hover end-0 w-300 dropdown-fadeinup"
            >
              <a className="dropdown-item py-4 d-flex">
                <span className="flex-none">
                  <i className="fa fa-user mr-0 fa-4x" aria-hidden="true" />
                </span>
                <span className="ps-3">
                  <span className="d-block mb-1">Bienvenido</span>
                  <small className="d-block text-muted text-wrap">
                    {currentUser.nombre}
                  </small>
                </span>
              </a>

              <a
                className="dropdown-item py-4 d-flex"
                onClick={() => salir()}
                href="/"
              >
                <span className="flex-none">
                  <button
                    className="input-group-text border-0 rounded-200 btn bg-primary"
                    type="submit"
                    onClick={() => salir()}
                  >
                    <span className="text-white text-uppercase font-weight-bold">
                      <i className="fa fa-power-off mr-0" aria-hidden="true" />
                    </span>
                  </button>
                </span>
                <span className="ps-3">
                  <span className="d-block mb-1">Cerrar Sesión</span>
                </span>
              </a>
            </div>
          </li>
        </>
      );
    } else {
      return <div />;
    }
  };
  //#endregion

  return (
    <>
      {/* ====================================
  ——— HEADER
  ===================================== */}
      {/* <header className="header-style3">
        <div id="top-bar">
          <div className="container-fluid px-lg-1-6 px-xl-2-5 px-xxl-2-9">
            <div className="row">
              <div className="col-md-9 col-xs-12">
                <div className="top-bar-info">
                  <ul className="ps-0">
                    <li>
                      <i className="ti-mobile" />
                      <a
                        href={"tel:" + footer?.Telefono}
                        className="me-lg-4 me-xl-6 text-white opacity-80"
                      >
                        {footer?.Telefono}{" "}
                      </a>
                    </li>
                    <li className="d-none d-sm-inline-block">
                      <i className="ti-email" />
                      <a
                        href={"mailTo:" + footer?.Correo}
                        className="text-white"
                      >
                        {footer?.Correo}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-md-3 d-none d-md-block">
                <ul className="top-social-icon ps-0">
                  <li>
                    <a
                      href={
                        footer?.fb
                          ? "https://" + footer?.fb
                          : "https://facebook.com"
                      }
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        footer?.tw
                          ? "https://" + footer?.tw
                          : "https://twtter.com"
                      }
                      target="_blank"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        footer?.isnta
                          ? "https://" + footer?.insta
                          : "https://instagram.com"
                      }
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={
                        footer?.in
                          ? "https://" + footer?.in
                          : "https://www.linkedin.com/"
                      }
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>*
*/}
      {/* <!-- start navbar --> */}
      <nav
        class="navbar navbar-expand-lg onepage-nav border-bottom border-color-light-white"
        style={{ backgroundColor: "white", color: "black", minHeight: "100px" }}
      >
        <div class="container">
          {/* <!-- start logo --> */}
          <a
            class="navbar-brand logowhite"
            href="javascript:void(0);"
            onClick={() => {
              setActive("inicio");
              navigate(`/`);
            }}
            data-scroll-nav="0"
          >
            <img
              src={logo}
              alt="Crizal - Onepage Business Template"
              title="Crizal - Onepage Business Template"
              style={{
                maxHeight: "85px",
              }}
            />
          </a>
          {/* <!-- end Logo --> */}

          {/* <!-- navbar links --> */}
          <ul
            class="navbar-nav ml-auto collapse navbar-collapse justify-content-end"
            id="navbarContent"
          >
            <li class="nav-item">
              <a
                style={{ color: "rgb(95, 15, 70)" }}
                className={active === "inicio" ? "nav-link active" : "nav-link"}
                href="javascript:void(0);"
                onClick={() => {
                  setActive("inicio");
                  navigate(`/`);
                }}
                data-scroll-nav="1"
              >
                Inicio
              </a>
            </li>
            <li class="nav-item">
              <a
                style={{ color: "rgb(95, 15, 70)" }}
                className={
                  active === "nosotros" ? "nav-link active" : "nav-link"
                }
                href="javascript:void(0);"
                onClick={() => {
                  setActive("nosotros");
                  navigate(`/nosotros`);
                }}
                data-scroll-nav="2"
              >
                Nosotros
              </a>
            </li>
            <li class="nav-item">
              <a
                style={{ color: "rgb(95, 15, 70)" }}
                className={
                  active === "servicios" ? "nav-link active" : "nav-link"
                }
                href="javascript:void(0);"
                onClick={() => {
                  setActive("servicios");
                  navigate(`/Servicios`);
                }}
                data-scroll-nav="3"
              >
                Servicios
              </a>
            </li>
            <li class="nav-item">
              <a
                style={{ color: "rgb(95, 15, 70)" }}
                className={active === "blog" ? "nav-link active" : "nav-link"}
                href="javascript:void(0);"
                onClick={() => {
                  setActive("blog");
                  navigate(`/Blog`);
                }}
                data-scroll-nav="4"
              >
                Blog
              </a>
            </li>
            <li class="nav-item">
              <a
                style={{ color: "rgb(95, 15, 70)" }}
                className={
                  active === "contactanos" ? "nav-link active" : "nav-link"
                }
                href="javascript:void(0);"
                onClick={() => {
                  setActive("contactanos");
                  navigate(`/contactanos`);
                }}
                data-scroll-nav="5"
              >
                Contáctanos
              </a>
            </li>
          </ul>
          {/* <!-- end navbar links --> */}
          {matches ? null : (
            <div>
              <button
                className="text-white font-weight-medium opacity-80"
                style={{
                  border: "none",
                  padding: "0.5rem",
                  backgroundColor: "#5F0F46",
                }}
                data-bs-toggle="modal"
                data-bs-target="#modal-createAccount"
                onClick={() => handleStatus()}
              >
                <MenuIcon />
              </button>
            </div>
          )}
        </div>
      </nav>
      {/* <!-- end navbar  --> */}
      <SideMenu
        active={active}
        handleActive={setActive}
        status={status}
        handleStatus={handleStatus}
        footer={footer}
      />
    </>
  );
};

export default HeaderPublicLayout;
