/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import TextField from "@mui/material/TextField";
import MapGoogle from "./MapGoogle/MapGoogle";

/* Servicios */
import FooterService from "../../../services/Footer.service";

import styles from "../layout/assets/css/core.min.module.css";
// import MapLibreUe from "./MapLibreUe";

const TablaFooter = () => {
  const google_apiKey = process.env.REACT_APP_GOOGLE_KEY;
  const geoapify_apiKey = process.env.REACT_APP_GEOAPIFY_KEY;
  const [footer, setFooter] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [updateCoordinates, setUpdateCoordinates] = useState(false);
  const [contenido, setContenido] = useState({});
  const myTheme = createTheme({});
  const [direction, setDirection] = useState({
    Direccion: "",
    lat: "",
    long: "",
  });

  useEffect(() => {
    getFooter();
  }, []);

  const getFooter = async () => {
    try {
      const cont = await FooterService.getAll();
      if (!cont[0]) {
        return;
      }
      setFooter(cont[0]);
      const contentHTML = convertFromHTML(cont[0].Texto);

      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(state));
      setContenido(content);
      setDirection(cont[0].Direccion);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateFooter = async () => {
    if (!footer.Texto) {
      toast.info("Error, tienes que agregar texto");
    } else if (!footer.Telefono) {
      toast.info("Error, tienes que colocar un número de telefono");
    } else if (!direction.Direccion) {
      toast.info("Error, tienes que colocar una dirección");
    } else if (!footer.Correo) {
      toast.info("Error, tienes que colocar un correo");
    } else {
      setLoading(true);
      try {
        const dataFooter = footer;
        dataFooter.Direccion = direction;
        await FooterService.update(dataFooter);
        toast.success("Información actualizada con éxito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getFooter();
      }
    }
  };

  const handleDirection = (newDirection) => {
    setDirection(newDirection);
  };

  const handleAddress = async () => {
    //geocode google
    let requestOptions = {
      method: "GET",
    };

    var direccionCodificada = encodeURIComponent(direction?.Direccion);

    const results = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${direccionCodificada}&key=${google_apiKey}`,
      requestOptions
    ).then((response) => response.json());

    setDirection({
      ...direction,
      lat: `${results?.results[0]?.geometry?.location?.lat}`,
      long: `${results?.results[0]?.geometry?.location?.lng}`,
    });
    setUpdateCoordinates(!updateCoordinates);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main
            id={styles["middle"]}
            className={`${styles.flexFill} ${styles.mxAuto}`}
          >
            {" "}
            {/* PAGE TITLE */}
            <header style={{ zIndex: "inherit" }}>
              <h1 className={`${styles.h4}`}>FOOTER</h1>
              <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb} ${styles.small}`}>
                  <li
                    className={`${styles.breadcrumbItem} ${styles.textMuted} ${styles.active}`}
                    aria-current="page"
                  >
                    Editar pie de página.
                  </li>
                </ol>
              </nav>
            </header>
            <>
              {/* user list */}
              <div className={`${styles.section} ${styles.p0}`}>
                <div className={`${styles.cardHeader} ${styles.p4}`}>
                  <div className={`${styles.row} ${styles.g3}`}>
                    <h4>Footer</h4>
                  </div>
                </div>
                <form className="form-validate">
                  <div className={`${styles.row} ${styles.g4}`}>
                    <div className={`${styles.col}`}>
                      {/* Shipping address */}

                      <div className={`${styles.section} ${styles.mb4}`}>
                        <div className={`${styles.cardBody}`}>
                          <div className={`${styles.row} ${styles.g3}`}>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <input
                                  type="text"
                                  className={`${styles.formControl} ${styles.shadowNone}`}
                                  placeholder="Texto"
                                  defaultValue={footer.Texto}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      Texto: e.target.value,
                                    })
                                  }
                                />
                                <label>Texto principal</label>
                              </div>
                            </div>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating} d-flex`}>
                                <input
                                  type="text"
                                  className={`${styles.formControl} ${styles.shadowNone}`}
                                  placeholder="Dirección"
                                  value={direction?.Direccion}
                                  onChange={(e) =>
                                    setDirection({
                                      ...direction,
                                      Direccion: e.target.value,
                                    })
                                  }
                                />
                                <label>
                                  Dirección (la dirección generada en
                                  aproximada)
                                </label>

                                <Button
                                  className="btn"
                                  style={{
                                    minWidth: "5rem",
                                    backgroundColor: "#5F0F46",
                                    color: "#fff",
                                  }}
                                  onClick={() => handleAddress()}
                                >
                                  Buscar
                                </Button>
                              </div>
                              <p
                                style={{
                                  marginInline: "1rem",
                                  fontSize: "0.8rem",
                                  color: "#ec1a22",
                                }}
                              >
                                Puedes modificar la dirección generada antes de
                                guardar...
                              </p>
                              <div
                                style={{
                                  width: "70%",
                                  height: "20rem",
                                  margin: "1rem",
                                  position: "relative",
                                }}
                              >
                                <MapGoogle
                                  update={updateCoordinates}
                                  handleDirection={handleDirection}
                                  positionApproximate={
                                    direction
                                      ? [
                                          Number(direction.lat),
                                          Number(direction.long),
                                        ]
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <input
                                  type="text"
                                  className={`${styles.formControl} ${styles.shadowNone}`}
                                  placeholder="Teléfono"
                                  value={footer.Telefono}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      Telefono: e.target.value,
                                    })
                                  }
                                />
                                <label>Teléfono</label>
                              </div>
                            </div>
                            <div className={`${styles.colSm12}`}>
                              <div className={`${styles.formFloating}`}>
                                <input
                                  type="text"
                                  className={`${styles.formControl} ${styles.shadowNone}`}
                                  placeholder="Correo electrónico"
                                  value={footer.Correo}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      Correo: e.target.value,
                                    })
                                  }
                                />
                                <label>Correo electrónico</label>
                              </div>
                            </div>
                            <h4>Redes sociales</h4>
                            <div className={`${styles.colSm3}`}>
                              <div className={`${styles.formFloating}`}>
                                <input
                                  type="url"
                                  className={`${styles.formControl} ${styles.shadowNone}`}
                                  placeholder="Facebook"
                                  value={footer.fb}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      fb: e.target.value,
                                    })
                                  }
                                />
                                <label>Facebook</label>
                              </div>
                            </div>
                            <div className={`${styles.colSm3}`}>
                              <div className={`${styles.formFloating}`}>
                                <input
                                  type="url"
                                  className={`${styles.formControl} ${styles.shadowNone}`}
                                  placeholder="Instagram"
                                  value={footer.insta}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      insta: e.target.value,
                                    })
                                  }
                                />
                                <label>Instagram</label>
                              </div>
                            </div>
                            <div className={`${styles.colSm3}`}>
                              <div className={`${styles.formFloating}`}>
                                <input
                                  type="url"
                                  className={`${styles.formControl} ${styles.shadowNone}`}
                                  placeholder="X"
                                  value={footer.tw}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      tw: e.target.value,
                                    })
                                  }
                                />
                                <label>X</label>
                              </div>
                            </div>

                            <div className={`${styles.colSm3}`}>
                              <div className={`${styles.formFloating}`}>
                                <input
                                  type="url"
                                  className={`${styles.formControl} ${styles.shadowNone}`}
                                  placeholder="LinkedIn"
                                  value={footer.in}
                                  onChange={(e) =>
                                    setFooter({
                                      ...footer,
                                      in: e.target.value,
                                    })
                                  }
                                />
                                <label>LinkedIn</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className={`${styles.co12}`}></div>
              </div>
              <div
                className={`${styles.cardFooter} ${styles.borderLight}`}
                style={{ textAlign: "right" }}
              >
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => updateFooter()}
                >
                  <svg
                    width="18px"
                    height="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  {"  "}
                  <span className={`${styles.small}`}>Guardar</span>
                </button>
              </div>
            </>
          </main>
        </>
      )}
    </>
  );
};

export default TablaFooter;
