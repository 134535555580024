import httpClient from "./HttpClient";
const prefix = "/nosotros";

export default class NosotrosService {
  static async update(nosotros) {
    return (await httpClient.put(`${prefix}/${nosotros.id}`, nosotros)).data;
  }
 
  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

}
