import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Typed from "react-typed";
import { useNavigate } from "react-router-dom";

//Services
import ServicioService from "../../../services/Servicio.service";

import banner from "./servicios.jpg";

//Components
import Titulo from "./Titulo";
import ItemPaquete from "./ItemPaquete";
import TextField from "@mui/material/TextField";
import { TablePagination } from "@mui/material";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";

import ServicioItem from "./ServicioItem";
const ServiciosGrid = () => {
  const { filtroI } = useParams();
  const [loading, setLoading] = useState(true);
  const [paquetes, setPaquetes] = useState([]);
  const [serviciosArray, setServiciosArray] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [tipo, setTipo] = useState(0);

  const { currentUser } = useContext(AuthContext);

  const [filtro, setFiltro] = useState(false);
  const [filtroCiudades, setFiltroCiudades] = useState([]);
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(undefined);
  const [filtroFechaFin, setFiltroFechaFin] = useState(undefined);
  const [value, setValue] = React.useState([0, 1000]);
  const navigate = useNavigate();
  //Imagenes

  useEffect(() => {
    AOS.init();
    getPaquetes();
    window.scrollTo(0, 0);
  }, [page, limit]);

  const getPaquetes = async () => {
    setLoading(true);
    try {
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }
      if (filtroI != undefined) {
        setFiltro(true);
        setFiltroCiudades([filtroI]);
        const data = await ServicioService.filtroCiudad(100, filtroI);
        {
          paquetes.map((record, index) => {
            serviciosArray.concat(record.titulo);
          });
        }
        setPaquetes(data);
      } else {
        const data = await ServicioService.list(limit, page * limit);
        setPaquetes(data.data);
        setTotal(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const buscar = async () => {
    setLoading(true);

    try {
      if (filtro) {
        console.log(
          "--Hay filtros" +
            filtroCiudades +
            "," +
            filtroFechaInicio +
            "," +
            filtroFechaFin +
            ","
        );
        if (
          filtroCiudades.length > 0 &&
          filtroFechaInicio == undefined &&
          filtroFechaFin == undefined
        ) {
          let cds = [];
          for (const cd of filtroCiudades) {
            const filtros = {
              ciudades: cd,
            };
            console.log("filtro de ciudades");
            console.log("la ciudad:" + filtros.ciudades);
            const data = await ServicioService.filtroCiudad(100, filtros);
            console.log("tipo:" + data.data);
            if (tipo == "Usuario") {
              data.data.forEach((element) => {
                for (const pre of element.paquetesUsuario) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            } else {
              data.data.forEach((element) => {
                for (const pre of element.paquetesEmpresa) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            }
          }
          setPaquetes(cds);
          console.log("paquetes del filtro de ciudades:" + cds.length);
        }
        if (
          (filtroCiudades.length == 0 &&
            filtroFechaInicio != undefined &&
            filtroFechaFin != undefined) ||
          (filtroCiudades.length == 0 &&
            filtroFechaInicio != "" &&
            filtroFechaFin != "")
        ) {
          const filtros = {
            fechaInicio: filtroFechaInicio.fecha,
            fechaFin: filtroFechaFin.fecha,
          };
          console.log(
            "paquetes del filtro de fechas:" +
              filtros.fechaInicio +
              "--" +
              filtros.fechaFin
          );
          let paq = [];
          const data = await ServicioService.filtroFecha(100, filtros);
          if (tipo == "Usuario") {
            data.data.forEach((element) => {
              for (const pre of element.paquetesUsuario) {
                if (pre.props.tipo == "Adulto") {
                  if (
                    pre.props.precio >= value[0] &&
                    pre.props.precio <= value[1]
                  ) {
                    paq.push(element);
                  }
                }
              }
            });
          } else {
            data.data.forEach((element) => {
              for (const pre of element.paquetesEmpresa) {
                if (pre.props.tipo == "Adulto") {
                  if (
                    pre.props.precio >= value[0] &&
                    pre.props.precio <= value[1]
                  ) {
                    paq.push(element);
                  }
                }
              }
            });
          }
          setPaquetes(paq);
          console.log("paquetes del filtro de fechas:" + paq.length);
        }
        if (
          filtroCiudades.length > 0 &&
          filtroFechaInicio != undefined &&
          filtroFechaFin != undefined
        ) {
          let cds = [];
          for (const cd of filtroCiudades) {
            const filtros = {
              ciudades: cd,
              fechaInicio: filtroFechaInicio.fecha,
              fechaFin: filtroFechaFin.fecha,
            };
            console.log("filtro de ciudades y fechas");
            console.log("la ciudad:" + cd);
            const data = await ServicioService.filtroCiudadFecha(100, filtros);
            console.log("tipo:" + data.data);
            if (tipo == "Usuario") {
              data.data.forEach((element) => {
                for (const pre of element.paquetesUsuario) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            } else {
              data.data.forEach((element) => {
                for (const pre of element.paquetesEmpresa) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            }
          }
          setPaquetes(cds);
          console.log("paquetes del filtro de ciudades y fecha:" + cds.length);
        }
        if (currentUser != undefined && currentUser.tipo == "Empresa") {
          setTipo("Empresa");
        } else {
          setTipo("Usuario");
        }
      } else {
        console.log("sin filtros");
        let paqs = [];
        const data = await ServicioService.list(limit, page * limit);
        data.data.forEach((element) => {
          for (const pre of element.paquetesEmpresa) {
            if (pre.props.tipo == "Adulto") {
              if (
                pre.props.precio >= value[0] &&
                pre.props.precio <= value[1]
              ) {
                paqs.push(element);
              }
            }
          }
        });
        setPaquetes(paqs);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = React.useState([]);

  const setFiltrosCiudades = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroCiudades(value);
    setFiltro(true);
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <>
        {/* PAGE TITLE
  ================================================== */}
        <section
          className="page-title-section bg-img cover-background left-overlay-dark"
          data-overlay-dark={6}
          data-background="img/banner/page-title.jpg"
          style={{ backgroundImage: `url(${banner})`, height: "20rem" }}
        >
          <div className="container position-unset">
            <div className="page-title mx-1-6 mx-lg-2-0 mx-xl-2-6 mx-xxl-2-9">
              <div className="row">
                <div className="col-md-12">
                  <h1>Servicios</h1>
                </div>
                <div className="col-md-12">
                  <ul className="ps-0">
                    <li>
                      <a
                        href="javascritp:void(0);"
                        onClick={() => navigate("/")}
                      >
                        Inicio
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascritp:void(0);"
                        onClick={() => navigate("/Servicios")}
                      >
                        Servicios
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <>
        {/* SERVICES
  ================================================== */}
        <section>
          <div className="container">
            <div
              className="text-center mb-2-9 mb-lg-6 wow fadeIn"
              data-wow-delay="100ms"
            >
              <span className="text-secondary mb-2 d-block fw-bold text-uppercase">
                Nuestros servicios
              </span>
              <h2 className="mb-0 h1">Nuestros servicios proporcionados</h2>
            </div>
            <div className="row mt-n1-6">
              {paquetes.map((record, index) => {
                return (
                  <ServicioItem
                    id={record.id}
                    titulo={record.titulo}
                    precioU={record.paquetesUsuario}
                    precioE={record.paquetesEmpresa}
                    descuento={record.descuento}
                    descripcion={record.descripcion}
                    incluye={record.incluye}
                    subtitulo={record.subtitulo}
                    imagenes={record.imagenes}
                    fecha={record.updateAt}
                    tipo={tipo}
                  />
                );
              })}
            </div>
          </div>
        </section>
        <TablePagination
          style={{ color: "var(--color-dark-variant)" }}
          component="div"
          labelRowsPerPage="Items por pagina"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
      <div className="main-wrapper services">
        {/* ====================================
  ———	BREADCRUMB
  ===================================== 
        <section
          className="breadcrumb-bg"
          style={{
            backgroundImage: "url(assets/img/background/page-title-bg-img.jpg)",
          }}
        >
          <div className="container">
            <div className="breadcrumb-holder">
              <div>
                <h1 className="breadcrumb-title">Servicios</h1>
                <ul className="breadcrumb breadcrumb-transparent">
                  <li className="breadcrumb-item">
                    <a
                      className="text-white"
                      href="#"
                      onClick={() => navigate(`/`)}
                    >
                      Inicio
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Services
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>*/}
        {/* ====================================
———	SERVICE STYLE 1
===================================== 
        <section className="pt-9 pb-6">
          <div className="container">
            <div className="section-title justify-content-center mb-4 mb-md-8">
              <span className="shape shape-left bg-info" />
              <h2 className="text-danger">Nuestros servicios</h2>
              <span className="shape shape-right bg-info" />
            </div>
            <div className="row">
              {paquetes.map((record, index) => {
                return (
                  <ServicioItem
                    id={record.id}
                    titulo={record.titulo}
                    precioU={record.paquetesUsuario}
                    precioE={record.paquetesEmpresa}
                    descuento={record.descuento}
                    descripcion={record.descripcion}
                    incluye={record.incluye}
                    subtitulo={record.subtitulo}
                    imagenes={record.imagenes}
                    fecha={record.updateAt}
                    tipo={tipo}
                  />
                );
              })}
              <div className="col-md-6 col-lg-4">
                <div className="media mb-6">
                  <div className="media-icon-large bg-primary rounded shadow-sm me-4">
                    <i className="fa fa-graduation-cap" aria-hidden="true" />
                  </div>
                  <div className="media-body">
                    <h3 className="text-primary text-truncate">Lorem ipsum</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              {/* Media 
              <div className="col-md-6 col-lg-4">
                <div className="media mb-6">
                  <div className="media-icon-large bg-success rounded shadow-sm me-4">
                    <i className="fa fa-leaf" aria-hidden="true" />
                  </div>
                  <div className="media-body">
                    <h3 className="text-success text-truncate">Lorem ipsum</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              {/* Media 
              <div className="col-md-6 col-lg-4">
                <div className="media mb-6">
                  <div className="media-icon-large bg-danger rounded shadow-sm me-4">
                    <i className="fa fa-car" aria-hidden="true" />
                  </div>
                  <div className="media-body">
                    <h3 className="text-danger text-truncate">Lorem ipsum</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              {/* Media 
              <div className="col-md-6 col-lg-4">
                <div className="media mb-6">
                  <div className="media-icon-large bg-info rounded shadow-sm me-4">
                    <i className="fas fa-utensils" aria-hidden="true" />
                  </div>
                  <div className="media-body">
                    <h3 className="text-info text-truncate">Delicious Food</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              {/* Media 
              <div className="col-md-6 col-lg-4">
                <div className="media mb-6">
                  <div className="media-icon-large bg-purple rounded shadow-sm me-4">
                    <i className="fa fa-heart" aria-hidden="true" />
                  </div>
                  <div className="media-body">
                    <h3 className="text-purple text-truncate">Lorem ipsum</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              {/* Media 
              <div className="col-md-6 col-lg-4">
                <div className="media mb-7">
                  <div className="media-icon-large bg-pink rounded shadow-sm me-4">
                    <i className="fas fa-shield-alt" aria-hidden="true" />
                  </div>
                  <div className="media-body">
                    <h3 className="text-pink text-truncate">Lorem ipsum</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>*/}
        {/* ====================================
———	SERVICE STYLE 2
===================================== 
        <section
          className="bg-info py-9"
          style={{
            backgroundImage: "url(assets/img/background/avator-bg.png)",
          }}
        >
          <div className="container">
            <div className="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
              <span className="shape shape-left bg-danger" />
              <h2 className="text-white">Servicios</h2>
              <span className="shape shape-right bg-danger" />
            </div>
            <div className="row wow fadeInUp">
              <div className="col-md-4">
                <div className="media flex-md-column flex-lg-row mb-6">
                  <div className="media-icon-large shadow-none me-4 p-0 mb-md-4 me-md-0 me-lg-4 mb-lg-0">
                    <i
                      className="fas fa-utensils extra-large"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="media-body">
                    <h3 className="text-white text-truncate">Lorem ipsum</h3>
                    <p className="text-white font-weight-light opacity-70">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
                <div className="media flex-md-column flex-lg-row mb-6">
                  <div className="media-icon-large shadow-none me-4 p-0 mb-md-4 me-md-0 me-lg-4 mb-lg-0">
                    <i className="fa fa-heart extra-large" aria-hidden="true" />
                  </div>
                  <div className="media-body">
                    <h3 className="text-white text-truncate">Lorem ipsum</h3>
                    <p className="text-white font-weight-light opacity-70">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
                <div className="media flex-md-column flex-lg-row mb-6">
                  <div className="media-icon-large shadow-none me-4 p-0 mb-md-4 me-md-0 me-lg-4 mb-lg-0">
                    <i
                      className="fas fa-shield-alt extra-large"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="media-body">
                    <h3 className="text-white text-truncate">Lorem ipsum</h3>
                    <p className="text-white font-weight-light opacity-70">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-none d-md-block">
                <div className="media-img">
                  <img
                    className="mx-auto d-block"
                    data-src="assets/img/features/features-img5.png"
                    src="assets/img/features/features-img5.png"
                    alt="services.png"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="media flex-md-column flex-lg-row mb-6">
                  <div className="media-icon-large shadow-none me-4 p-0 mb-md-4 me-md-0 me-lg-4 mb-lg-0">
                    <i className="fa fa-car extra-large" aria-hidden="true" />
                  </div>
                  <div className="media-body">
                    <h3 className="text-white text-truncate">Lorem ipsum</h3>
                    <p className="text-white font-weight-light opacity-70">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
                <div className="media flex-md-column flex-lg-row mb-6">
                  <div className="media-icon-large shadow-none me-4 p-0 mb-md-4 me-md-0 me-lg-4 mb-lg-0">
                    <i
                      className="fa fa-graduation-cap extra-large"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="media-body">
                    <h3 className="text-white text-truncate">Lorem ipsum</h3>
                    <p className="text-white font-weight-light opacity-70">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
                <div className="media flex-md-column flex-lg-row mb-6">
                  <div className="media-icon-large shadow-none me-4 p-0 mb-md-4 me-md-0 me-lg-4 mb-lg-0">
                    <i className="fa fa-leaf extra-large" aria-hidden="true" />
                  </div>
                  <div className="media-body">
                    <h3 className="text-white text-truncate">Lorem ipsum</h3>
                    <p className="text-white font-weight-light opacity-70">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>*/}
        {/* ====================================
———	SERVICE STYLE 3
=====================================
        <section className="pt-10 pb-7 pb-md-9">
          <div className="container">
            <div className="section-title justify-content-center mb-4 mb-md-8">
              <span className="shape shape-left bg-info" />
              <h2 className="text-danger">Service Style 3</h2>
              <span className="shape shape-right bg-info" />
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-5 offset-lg-1 col-xl-4 offset-xl-2">
                <div className="media text-end mb-6">
                  <div className="media-icon-large bg-primary rounded shadow-sm ms-auto me-0 order-1">
                    <i
                      className="fa fa-graduation-cap border-0"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="media-body me-5">
                    <h3 className="text-primary text-truncate">
                    Lorem ipsum
                    </h3>
                    <p className="ps-xl-5">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 col-xl-4">
                <div className="media mb-6">
                  <div className="media-icon-large bg-success rounded shadow-sm me-5">
                    <i className="fa fa-leaf border-0" aria-hidden="true" />
                  </div>
                  <div className="media-body">
                    <h3 className="text-success text-truncate">
                    Lorem ipsum
                    </h3>
                    <p className="pe-xl-5">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 offset-lg-1 col-xl-4 offset-xl-2">
                <div className="media text-end mb-6">
                  <div className="media-icon-large bg-danger rounded shadow-sm ms-auto me-0 order-1">
                    <i className="fa fa-car border-0" aria-hidden="true" />
                  </div>
                  <div className="media-body me-5">
                    <h3 className="text-danger text-truncate">
                    Lorem ipsum
                    </h3>
                    <p className="ps-xl-5">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 col-xl-4">
                <div className="media mb-6">
                  <div className="media-icon-large bg-info rounded shadow-sm me-5">
                    <i
                      className="fas fa-utensils border-0"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="media-body">
                    <h3 className="text-info text-truncate">Lorem ipsum</h3>
                    <p className="pe-xl-5">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 offset-lg-1 col-xl-4 offset-xl-2">
                <div className="media text-end mb-6">
                  <div className="media-icon-large bg-purple rounded shadow-sm ms-auto me-0 order-1">
                    <i className="fa fa-heart border-0" aria-hidden="true" />
                  </div>
                  <div className="media-body me-5">
                    <h3 className="text-purple text-truncate">
                    Lorem ipsum
                    </h3>
                    <p className="ps-xl-5">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 col-xl-4">
                <div className="media mb-6">
                  <div className="media-icon-large bg-pink rounded shadow-sm me-5">
                    <i
                      className="fas fa-shield-alt border-0"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="media-body">
                    <h3 className="text-pink text-truncate">Lorem ipsum</h3>
                    <p className="pe-xl-5">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>*/}
      </div>{" "}
      {/* element wrapper ends */}
    </>
  );
};

export default ServiciosGrid;
