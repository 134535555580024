/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

/* Components & dependencies */
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";
import Carousel from "react-bootstrap/Carousel";
import FooterService from "../../../services/Footer.service";

import PaquetePrecio from "./PaquetePrecio";
import ItemBlog from "../blog/ItemBlog";
import GoogleMaps from "simple-react-google-maps";
import { useCart } from "react-use-cart";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* services */
import S3Service from "../../../services/S3.service";
import ServicioService from "../../../services/Servicio.service";

import TextField from "@mui/material/TextField";
import Titulo from "./Titulo";

import banner from "./servicios.jpg";

const ServicioIndividual = () => {
  const { id } = useParams();
  const navegar = useNavigate();
  const [paquete, setPaquete] = useState({});
  const [servicioTitulo, setServicioTitulo] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [imagenesDias, setImagenesDias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [precio, setPrecio] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [Extra, setExtra] = useState(0);
  const [Nino, setNino] = useState(0);
  const [ExtraPrecio, setExtraPrecio] = useState(0);
  const [NinoPrecio, setNinoPrecio] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalMin, setTotalMin] = useState(0);
  const [fecha, setFecha] = useState();
  const [informacion, setInformacion] = useState();

  let dias = 0;
  let cargaPrecio = 0;
  var today = new Date();
  let check = 0;
  let adultoPrecio = 0;
  let adultoQuantity = 0;

  const {
    items,
    addItem,
    removeItem,
    removeLineItem,
    clearCart,
    lineItemsCount,
  } = useCart();

  useEffect(() => {
    getData();
    vaciarCart();
    getFooter();
    window.scrollTo(0, 0);
  }, [id]);

  const vaciarCart = async () => {
    items.map((record, index) => {
      removeItem(record.id);
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      setImagenes([]);
      const result = await ServicioService.getById(parseInt(id));
      setPaquete(result);
      for (const url of result.imagenes) {
        const imagen = await S3Service.get(url);
        setImagenes((imagenes) => [...imagenes, imagen]);
      }
      const result2 = await ServicioService.getAllTitulo();
      setServicioTitulo(result2);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const getFooter = async () => {
    setLoading(true);
    try {
      const resultInfo = await FooterService.getAll();
      setInformacion(resultInfo[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const menos = (tipo) => {
    if (tipo == "Extra") {
      setExtra(Extra >= 1 ? Extra - 1 : 0);
    } else {
      setNino(Nino >= 1 ? Nino - 1 : 0);
    }
  };

  const mas = (tipo) => {
    if (tipo == "Extra") {
      setExtra(Extra + 1);
    } else {
      setNino(Nino + 1);
    }
  };

  const sumar = (precio) => {
    setTotal(Number(total) + Number(precio));
  };

  const restar = (precio) => {
    if (Number(total) - Number(precio) >= Number(totalMin)) {
      setTotal(Number(total) - Number(precio));
    }
  };

  const irPagar = () => {
    if (fecha == undefined) {
      toast.error("Debes de seleccionar una fecha");
    } else if (currentUser == undefined) {
      toast.error("Por favor inicia sesión para comprar");
    } else {
      let fechaFinal = new Date(fecha);
      //let fechaFinal=f.getDate()+paquete.dias.length();
      fechaFinal.setDate(fechaFinal.getDate() + dias);

      const object = {
        id: idUnico(id + 1),
        idpaquete: id,
        name: "Adulto",
        price: adultoPrecio,
        quantity: adultoQuantity,
        cantidad: adultoQuantity,
        fechaInicial: fecha,
        fechaFinal: fechaFinal,
      };
      addItem(object);
      console.log("Item agregado Adulto:" + object.name);
      console.log("Nino:" + Nino);
      console.log("Extra:" + Extra);
      navegar(`/bookingStepUno/${id}`);
    }
  };

  const idUnico = (length) => {
    try {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    } catch (error) {
      CSSConditionRule.log(error);
    }
  };

  const reemplazarBullets = (html) => {
    const iconoBullet = '<i class="fas fa-circle"></i>'; // Utiliza el icono de bullet de FontAwesome (puedes cambiarlo según tus necesidades)
    const regexBullet = /<ul>|<\/ul>|<li>|<\/li>/g; // Expresión regular para buscar los elementos de lista (bullets)

    return html.replace(regexBullet, iconoBullet);
  };

  const locale = "en-gb";

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {/* PAGE TITLE
  ================================================== */}
          <section
            className="page-title-section bg-img cover-background left-overlay-dark"
            data-overlay-dark={6}
            data-background="img/banner/page-title.jpg"
            style={{ backgroundImage: `url(${banner})`, height: "20rem" }}
          >
            <div className="container position-unset">
              <div className="page-title mx-1-6 mx-lg-2-0 mx-xl-2-6 mx-xxl-2-9">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Detalles del servicio</h1>
                  </div>
                  <div className="col-md-12">
                    <ul className="ps-0">
                      <li>
                        <a
                          href="javascritp:void(0);"
                          onClick={() => navegar("/")}
                        >
                          Inicio
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascritp:void(0);"
                          onClick={() => navegar("/Servicios")}
                        >
                          Servicios
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">{paquete?.titulo}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* SERVICE DETAILS
  ================================================== */}
          <section>
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-4 order-2 order-lg-1 wow fadeIn"
                  data-wow-delay="200ms"
                >
                  <div className="sidebar pe-xl-4">
                    <div className="widget mb-1-9 p-4">
                      <div className="list-style3">
                        <ul className="list-unstyled mb-0">
                          {servicioTitulo?.map((record, index) => {
                            if (record.id == paquete.id) {
                              return (
                                <li className="active" key={index}>
                                  <a href="javascript:void(0);">
                                    {record.titulo}
                                  </a>
                                </li>
                              );
                            } else {
                              return (
                                <li key={index}>
                                  <a
                                    onClick={() => {
                                      navegar(`/Servicios/${record.id}`);
                                    }}
                                    href="javascript:void(0);"
                                  >
                                    {record.titulo}
                                  </a>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                    {/* <div className="widget mb-1-9 p-2-2">
                      <div className="media mb-4">
                        <i className="ti-mobile text-primary display-23" />
                        <div className="ms-3">
                          <h4 className="mb-1 h6">Teléfono</h4>
                          <span>{informacion?.Telefono}</span>
                        </div>
                      </div>
                      <div className="media mb-4">
                        <i className="ti-email text-primary display-23" />
                        <div className="ms-3">
                          <h4 className="mb-1 h6">Email</h4>
                          <span>{informacion?.Correo}</span>
                        </div>
                      </div>
                      <div className="media">
                        <i className="ti-location-pin text-primary display-23" />
                        <div className="ms-3">
                          <h4 className="mb-1 h6">Dirección</h4>
                          <span>{informacion?.Direccion?.Direccion}</span>
                        </div>
                      </div>
                    </div> */}
                    <div
                      className="bg-img theme-overlay cover-background rounded"
                      data-overlay-dark={10}
                      data-background="img/bg/bg-03.jpg"
                    >
                      <div className="position-relative z-index-9 text-center py-5">
                        <i className="fas fa-headset text-white mb-4 display-14" />
                        <h5 className="text-white mb-4">
                          ¿Tienes alguna duda?
                        </h5>
                        <div className="separator-line-horrizontal-full bg-white opacity4 mb-4" />
                        <ul className="text-center list-unstyled mb-0 ps-0">
                          <li className="text-white mb-2">
                            <i className="fa fa-phone small text-white me-2" />
                            <a
                              href="javascript:void(0);"
                              className="text-white"
                            >
                              {informacion?.Telefono}
                            </a>
                          </li>
                          <li className="text-white">
                            <i className="fa fa-envelope-open small text-white me-2" />
                            <a href="javascript:void(0)" className="text-white">
                              {informacion?.Correo}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-8 order-1 order-lg-2 mb-2-9 mb-lg-0 wow fadeIn"
                  data-wow-delay="400ms"
                >
                  <div className="row">
                    <div
                      className="col-12 mb-2-9"
                      style={{
                        height: "40rem",
                        overflow: "hidden",
                        backgroundColor: "#424242",
                      }}
                    >
                      <Carousel
                        fade={true}
                        indicators={false}
                        nextLabel=""
                        prevLabel=""
                      >
                        {imagenes.map((item, index) => {
                          return (
                            <Carousel.Item
                              key={index}
                              style={{
                                display: "grid",
                                justifyItems: "center",
                                height: "40rem",
                                alignItems: "center",
                              }}
                            >
                              <img
                                id={`imagen${index}`}
                                className="d-block"
                                src={item.result}
                                alt="image"
                                style={{ width: "auto", maxHeight: "40rem" }}
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                    <div className="col-12 mb-2-9">
                      <h2 className="mb-3">{paquete?.titulo}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: paquete.descripcion,
                        }}
                        style={{ textAlign: "justify" }}
                      ></p>
                      <ul
                        className="list-style1 mb-0"
                        style={{ listStyleType: "none", paddingLeft: 0 }}
                        dangerouslySetInnerHTML={{ __html: paquete.incluye }}
                      />
                      {/*<ul className="list-style1 mb-0">
                        <li>
                          Desktop publishing packages and web page editors.
                        </li>
                        <li>
                          Internet tend to repeat predefined chunks as
                          necessary.
                        </li>
                        <li>Various versions have evolved over the years.</li>
                        <li>
                          Letraset sheets containing Lorem Ipsum passages.
                        </li>
                        <li>
                          Passage of Lorem Ipsum, you need to be sure there
                          anything.
                        </li>
                      </ul>*/}
                    </div>
                    {/*<div className="col-lg-12 mb-2-9">
                      <div id="accordion" className="accordion-style">
                        <div className="card mb-3">
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <button
                                className="btn btn-link"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                What should I include in my personal statement?
                              </button>
                            </h5>
                          </div>
                          <div
                            id="collapseOne"
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordion"
                          >
                            <div className="card-body">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distribution of letters.
                            </div>
                          </div>
                        </div>
                        <div className="card mb-3">
                          <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                              <button
                                className="btn btn-link collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                Do you support banking loan?
                              </button>
                            </h5>
                          </div>
                          <div
                            id="collapseTwo"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordion"
                          >
                            <div className="card-body">
                              Neque porro quisquam est quis nostrud exercitation
                              ullamco laboris nisi ut aliquip ex ea commodo
                              consequat. Duis aute irure dolor in reprehenderit
                              in voluptate velit esse cillum dolore eu fugiat
                              nulla pariatur.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                              <button
                                className="btn btn-link collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                What do I get when my account is paid off?
                              </button>
                            </h5>
                          </div>
                          <div
                            id="collapseThree"
                            className="collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordion"
                          >
                            <div className="card-body">
                              It was popularised in the 1960s with the release
                              of Letraset sheets containing Lorem Ipsum
                              passages, and more recently with desktop
                              publishing software like Aldus PageMaker including
                              versions of Lorem Ipsum.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* PAGE TITLE
  ================================================== 
            <section
              className="page-title-section bg-img cover-background left-overlay-dark"
              data-overlay-dark={6}
              data-background="img/banner/page-title.jpg"
            >
              <div className="container position-unset">
                <div className="page-title mx-1-6 mx-lg-2-0 mx-xl-2-6 mx-xxl-2-9">
                  <div className="row">
                    <div className="col-md-12">
                      <h1>Service Details</h1>
                    </div>
                    <div className="col-md-12">
                      <ul className="ps-0">
                        <li>
                          <a href="index.html">Home</a>
                        </li>
                        <li>
                          <a href="#!">Service Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          <div className="main-wrapper single-package-right-sidebar">
            {/* Titulo 
            <section className="page-title" style={{ paddingTop: "7%" }}>
              <div
                className="page-title-img bg-img bg-overlay-darken"
                style={{
                  backgroundImage: "url(/assets/img/pages/page-title-bg13.jpg)",
                }}
              >
                <div className="containerPublic">
                  <div
                    className="row align-items-center justify-content-center"
                    style={{ height: "200px" }}
                  >
                    <div className="col-lg-6">
                      <div className="page-title-content">
                        <div className="">
                          <h2 className="text-uppercase text-white font-weight-bold">
                            {paquete?.titulo}
                          </h2>
                        </div>
                        <p className="text-white mb-0">{paquete?.subtitulo}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="py-10">
              <div className="containerPublic">
                <div className="row">
                  {/**Reserva 
                  <div className="col-md-5 col-lg-4 order-2">
                    {/*<form>
                    <div className="card border">
                      <h2 className="card-header text-uppercase text-center bg-smoke border-bottom">
                        Reservar
                      </h2>
                      <div className="card-body px-3 py-4">
                        <div className="border-bottom mb-5">
                          <div className="form-group mb-5">
                            <div className="row">
                              <div className="col-xl-7">
                                <div className="form-group form-group-icon form-group-icon-category-2 mb-0">
                                  <div>
                                    <TextField
                                      id="date"
                                      label="Fecha"
                                      type="date"
                                      sx={{ width: 220 }}
                                      InputLabelProps={{
                                        style: { color: "#ff891e" },
                                        shrink: true,
                                      }}
                                      style={{ width: "183%" }}
                                      inputProps={{
                                        min: paquete.fechaInicial.slice(0, 10),
                                        max: paquete.fechaFinal.slice(0, 10),
                                      }}
                                      onChange={(e) => setFecha(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-5"></div>

                          <div>
                            {precio.map((record, index) => {
                              if (record.props.tipo == "Adulto") {
                                if (check == 0) {
                                  adultoPrecio = record.props.precio;
                                  adultoQuantity = record.props.cantidad;
                                  check = 1;
                                }

                                return (
                                  <div className="form-group mb-5">
                                    <div
                                      className="row align-items-center"
                                      style={{ marginLeft: "-40%" }}
                                    >
                                      <label className="control-label col-xl-5 text-center text-xl-right">
                                        Adulto
                                      </label>
                                      <div className="col-xl-5">
                                        <div className="count-input mx-auto">
                                          <input
                                            type="number"
                                            value={adultoQuantity}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-xl-2">
                                        <p className="text-center mt-3 mt-xl-0 mb-0">
                                          ${record.props.precio}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <PaquetePrecio
                                    tipo={record.props.tipo}
                                    precio={record.props.precio}
                                    menos={(data) => menos(data)}
                                    mas={(data) => mas(data)}
                                    sumar={(data) => sumar(data)}
                                    restar={(data) => restar(data)}
                                    cant={
                                      record.props.tipo == "Extra"
                                        ? Extra
                                        : Nino
                                    }
                                  />
                                );
                              }
                            })}
                          </div>
                        </div>

                        <div className="d-flex justify-content-between border-bottom mb-5 pb-5">
                          <span className="text-uppercase h4 mb-0">
                            Precio total
                          </span>
                          <span className="font-weight-bold text-primary h3 mb-0">
                            ${Number(total).toFixed(2)}
                          </span>
                        </div>

                        <div className="text-center px-4">
                          <button
                            type="button"
                            onClick={() => {
                              irPagar();
                            }}
                            className="btn btn-hover btn-lg btn-block btn-outline-secondary text-uppercase"
                          >
                            Ir a pagar
                            <span className="ml-4">
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*</form>
                  </div>

                  {/**Descripción
                  <div className="col-md-7 col-lg-8">
                    {/**Carrusel /}
                    <div
                      id="package-slider"
                      className="owl-carousel owl-theme package-slider"
                      style={{ marginTop: "-13%" }}
                    >
                      <Carousel
                        fade={true}
                        indicators={false}
                        nextLabel=""
                        prevLabel=""
                      >
                        {imagenes.map((item, index) => {
                          return (
                            <Carousel.Item>
                              <img
                                id={`imagen${index}`}
                                className="lazyestload"
                                src={item.result}
                                alt="image"
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                    {/**Descripción /}
                    <div className="mt-8">
                      <h2 className="text-uppercase mb-5">Descripcion</h2>
                      <div className="row">
                        <div className="col-lg-6 mb-6">
                          <div className="blog__details__text">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: paquete.descripcion,
                              }}
                              style={{ textAlign: "justify" }}
                            ></p>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-6">
                          <div className="card border">
                            <h4
                              className="card-header text-uppercase text-center bg-transparent"
                              style={{ marginTop: "3%" }}
                            >
                              Incluye
                            </h4>
                            <div className="card-body">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: paquete.incluye,
                                }}
                                style={{
                                  textAlign: "justify",
                                  marginRight: "10%",
                                  marginTop: "-3%",
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/**Programa /}
                    <div className="mb-8">
                      <h3 className="text-uppercase mb-5">Programa</h3>
                      <div></div>
                      {paquete.diasJson.map((record, index) => {
                        dias += 1;
                        return (
                          <>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>
                                  <p className="mb-5 mb-lg-0">
                                    {" "}
                                    {record.titulo}{" "}
                                  </p>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  <div className="row">
                                    <div className="col-lg-10 mb-6">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: record.descripcion,
                                        }}
                                        style={{ textAlign: "justify" }}
                                      ></p>
                                      <img
                                        id={`imagen${index}`}
                                        className="lazyestload"
                                        src={imagenesDias[0]}
                                        alt="image"
                                      />
                                    </div>
                                    <div className="col-lg-2 mb-6">
                                      <div className="media-body d-flex">
                                        <div className="badge badge-rounded-circle border">
                                          <span className="d-block text-primary">
                                            dia {dias}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </>
                        );
                      })}
                    </div>
                    {/**Ubicación /}
                    <div className="mb-7">
                      <h3 className="text-uppercase mb-6">Ubicacion</h3>
                    </div>
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <GoogleMaps
                          apiKey={"AIzaSyAkITgblqZ2pdFC9-JBv6T3fZogUc93TY8"}
                          style={{ height: "400px", width: "100%" }}
                          zoom={6}
                          center={{
                            lat: Number(ubicacion.lat),
                            lng: Number(ubicacion.lng),
                          }}
                          markers={{
                            lat: Number(ubicacion.lat),
                            lng: Number(ubicacion.lng),
                          }} //optional
                        />
                        {/*
                        <iframe
                          className="mb-7"
                          id="map"
                          style={{ width: "100%", height: 400 }}
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14111.279725886337!2d-98.14111784090707!3d18.9424276689983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfbf41d9b5c177%3A0x98264d215aa459df!2sAfricam%20Safari!5e0!3m2!1ses-419!2smx!4v1669953191565!5m2!1ses-419!2smx"
                          frameBorder={0}
                          scrolling="no"
                          marginHeight={0}
                          marginWidth={0}
                        />
                  /}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>*/}
        </>
      )}
    </>
  );
};

export default ServicioIndividual;
